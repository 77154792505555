/* eslint-disable react-hooks/exhaustive-deps */

import { CircularProgress } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import UsersApi from '../../../API/UsersApi'
import { UserContext } from '../../../App'
import StringIntReq from '../../../Requests/StringIntReq'


import './index.scss'

import Layout from '../../../Layouts/Layout'

import MobileVersionBusinessCard from './components/MobileVersionBusinessCard'
import DesktopVersionBusinessCard from './components/DesktopVersionBusinessCard'
const Index = ({showLayout=true, isTab}) => {
    const currentUser = useContext(UserContext)
    const [salesRep, setSalesRep] = useState(null)
    const { id, fiberCompany } = useParams()
    const [salesRepIsUser, setSalesRepIsUser] = useState(false)
    const user = useContext(UserContext)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    console.log(fiberCompany)
    useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenWidth(window.innerWidth)
        }
        )


        return () => {
            window.removeEventListener('resize', () => {
                setScreenWidth(window.innerWidth)
            })
        }
    }, [])

    useEffect(() => {
        if (salesRep === null) {
            getSalesRep()
        }
    }, [id, currentUser])

    useEffect(() => {
        getSalesRep()
    }, [])


    const getSalesRep = async () => {
        if (id) {
            let req = new StringIntReq()
            if (id) req.int = id
            else req.int = user.salesRepId

            let res = await UsersApi.GetUserById(req)
            console.log(res)
            if (res?.status?.success) {

                setSalesRep(res.data)
                if (currentUser?.id === res.data?.id) {
                    setSalesRepIsUser(true)
                }
            }
        }
        else if (currentUser.userType === "Customer") {

            if (currentUser?.fidiumCustomer) {
                if (currentUser?.fidiumCustomer?.salesRep) {
                    setSalesRep(currentUser?.fidiumCustomer?.salesRep)
                }
            }
        }
        else {
            setSalesRep(currentUser)
            setSalesRepIsUser(true)
        }
    }

    if (currentUser.userType === "Customer" && !currentUser.fidiumCustomer?.salesRep) {
        return (
            <Layout>
                {/* <CircularProgress sx={{ color: "var(--color-icon)" }} size={100} color='dark' /> */}
                <div className="loader" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                    <h1>No Sales Rep Assigned</h1>
                </div>
            </Layout>
        )
    }


    // if (!salesRep) {
    //     return (
    //         <div className="loader" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
    //             <CircularProgress sx={{ color: "var(--color-icon)" }} size={100} color='dark' />
    //         </div>
    //     )
    // }
    // Function to share content using the Web Share API
    const share = () => {
        if (navigator.share) {
            navigator.share({
                title: salesRep.firstName + " " + salesRep.lastName + "'s Digital Business Card",
                text: salesRep.bio,
                url: window.location.origin + "/digital-business-center/" + salesRep.id

            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing:', error));
        } else {
            console.log('Web Share API not supported.');
        }
    };



    return (
        (showLayout ?

        <Layout>
            <>
                {/* {
                salesRepIsUser && 
                <h1 style={{
                    textAlign:'center',
                    margin:'1rem 0',
                    fontSize:'3rem',
                    fontWeight:'bold',

                }}>{
                    salesRep.userType === "SalesOrgAdmin"?
                    "Area Manager"
                    :
                    salesRep.userType === "SalesRep"?
                    "Fiber Specialist"
                    :
                    salesRep.userType === "SuperAdmin"?
                    "Super Admin"
                    :
                    ""
                }</h1>
            } */}
                {
                    salesRep ?
                        <div style={{
                            gridTemplateColumns: !salesRepIsUser && "1fr"
                        }} className='digitalBusinessCardPage '>
                            {/* OLD Card */}

                            {
                                // screenWidth < 1210 ?


                                    <MobileVersionBusinessCard share={share} salesRep={salesRep} salesRepIsUser={salesRepIsUser} fiberCompany={fiberCompany} />
                                    // :
                                    // <DesktopVersionBusinessCard share={share} salesRep={salesRep} salesRepIsUser={salesRepIsUser} fiberCompany={fiberCompany} />
                            }

                            {/* New Card */}


                        </div>
                        :
                        <div className="loader" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                            <CircularProgress sx={{ color: "var(--color-icon)" }} size={100} color='dark' />
                        </div>
                }
            </>
        </Layout>

        : 
                <div>
                    {
                    salesRep ?
                        <div style={{
                            gridTemplateColumns: !salesRepIsUser && "1fr"
                        }} className='digitalBusinessCardPage '>
                            {/* OLD Card */}

                            {
                                // screenWidth < 1210 ?


                                    <MobileVersionBusinessCard share={share} salesRep={salesRep} salesRepIsUser={salesRepIsUser} fiberCompany={fiberCompany} isTab={isTab}/>
                                    // :
                                    // <DesktopVersionBusinessCard share={share} salesRep={salesRep} salesRepIsUser={salesRepIsUser} fiberCompany={fiberCompany} />
                            }

                            {/* New Card */}


                        </div>
                        :
                        <div className="loader" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                            <CircularProgress sx={{ color: "var(--color-icon)" }} size={100} color='dark' />
                        </div>
                }
                </div>
        )
    )
}

export default Index