import React, { useEffect, useState } from 'react'
import { Modal } from '@mui/material'
import { IoCloseCircle } from 'react-icons/io5'
import Swal from 'sweetalert2'
import UpdateLeadAppointmentReq from '../../../../../Requests/Customer/UpdateLeadAppointmentReq'
import FiberHouseApi from '../../../../../API/FiberHouseApi'
import { gapi } from 'gapi-script';

const AppointmentForm = ({ open, setOpen, lead, setReload }) => {

    const [appointmentDateTime, setAppointmentDateTime] = useState(lead?.appointmentDateTime)

    const [isSignedIn, setIsSignedIn] = useState(false);
    const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
    const SCOPES = "https://www.googleapis.com/auth/calendar.events";
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
    const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
            }).then(() => {
                // Initialize the Google Auth2 library explicitly
                gapi.auth2.init({
                    client_id: CLIENT_ID,
                    scope: SCOPES,
                }).then(() => {
                    const authInstance = gapi.auth2.getAuthInstance();
                    if (authInstance) {
                        setIsSignedIn(authInstance.isSignedIn.get());
                        authInstance.isSignedIn.listen(setIsSignedIn);
                    } else {
                        console.error("Error: authInstance is null");
                    }
                });
            }).catch((error) => {
                console.error('Error initializing Google API', error);
            });
        };

        gapi.load('client:auth2', initClient); // Load client and auth2
    }, [API_KEY, CLIENT_ID]);

    const handleSignIn = () => {
        gapi.auth2.getAuthInstance().signIn();
    };

    const handleSignOut = () => {
        gapi.auth2.getAuthInstance().signOut();
    };

    const generateICalEvent = () => {
        const start = new Date(appointmentDateTime).toISOString().replace(/-|:|\.\d+/g, '');
        const end = new Date(new Date(appointmentDateTime).getTime() + 60 * 60 * 1000).toISOString().replace(/-|:|\.\d+/g, ''); // Adding 1 hour to end time
        const icsContent = `
        BEGIN:VCALENDAR
        VERSION:2.0
        BEGIN:VEVENT
        SUMMARY:Appointment
        DTSTART:${start}
        DTEND:${end}
        DESCRIPTION:Appointment Details
        LOCATION:Appointment Location
        END:VEVENT
        END:VCALENDAR
    `;
    
        const blob = new Blob([icsContent], { type: 'text/calendar' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'appointment.ics';
        link.click();
    
        Swal.fire('iCalendar Event Generated!');
    };

    const addGoogleCalendarEvent = (appointmentDateTime) => {

        const authInstance = gapi.auth2.getAuthInstance();
        if (!authInstance.isSignedIn.get()) {
            Swal.fire({
                title: 'Not Signed In',
                text: 'You need to sign in to Google to add this event to Google Calendar. Would you like to sign in now?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sign In',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSignIn();
                }
            });
        } else {
            const startTime = new Date(appointmentDateTime).toISOString();
            const endTime = new Date(new Date(appointmentDateTime).getTime() + 60 * 60 * 1000).toISOString();
            const event = {
                summary: `${lead?.name} Appointment`,
                start: {
                    dateTime: startTime,
                    timeZone: 'America/Los_Angeles',
                },
                end: {
                    dateTime: endTime,
                    timeZone: 'America/Los_Angeles',
                },
            };
    
            const request = gapi.client.calendar.events.insert({
                calendarId: 'primary',
                resource: event,
            });
    
            request.execute((event) => {
                console.log('Event created:', event);
                if(event.status !== 'confirmed' || (event.code && event.code === 400)) 
                {
                    Swal.fire('Error', 'Failed to create event in Google Calendar', event.data.message);
                    return;
                }
                Swal.fire('Success', 'Event created successfully in Google Calendar', 'success');
            });
        }
    };
    

    const promptAddToCalendar = async (appointmentDateTime) => {
        const { value } = await Swal.fire({
            title: 'Add Appointment to Calendar',
            text: 'Would you like to add this appointment to Google Calendar or iCalendar?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Google Calendar',
            denyButtonText: 'iCalendar',
            cancelButtonText: 'No, thanks',
        });
    
        if (value === true) {
            // User chose Google Calendar
            addGoogleCalendarEvent(appointmentDateTime);
        } else if (value === false) {
            // User chose iCalendar
            generateICalEvent();
        }
    };


    const updateAppointmentDate = async () => {
        setOpen(false)
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Updating Appointment Date",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            },
        })
        const req = new UpdateLeadAppointmentReq();
        req.leadId = lead.id;
        req.appointmentDateTime = appointmentDateTime;
        req.appointmentType = null;
        const res = await FiberHouseApi.UpdateLeadAppointment(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Appointment Date Updated Successfully",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            promptAddToCalendar();
            setReload(
                (prev) => {
                    return !prev
                }
            )
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message
            })
        }
    }


    return (
        <Modal setOpen={setOpen} open={open} onClose={() => {
            setOpen(false)
        }}>
            <div className='custom_modal'>
                <IoCloseCircle onClick={() => {
                    setOpen(false)
                }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                <img alt="Logo" src={'/images/nav/nav-logo.png'} className='logo' />
                <h1 >Appointment Date</h1>
                <input type="datetime-local" value={appointmentDateTime} onChange={(e) => {
                    setAppointmentDateTime(e.target.value)
                }} />
                <button
                    onClick={() => {
                        updateAppointmentDate()
                    }
                    }>Update</button>
            </div>
        </Modal>
    )
}

export default AppointmentForm