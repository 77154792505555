import React, { useEffect, useState } from 'react';
import './AnnouncementBanner.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import StringIntReq from '../../Requests/StringIntReq';
import UsersApi from '../../API/UsersApi';

const AnnouncementBanner = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(true);


    const getDaysAgo = (startDate) => {
        const today = new Date();
        const date = new Date(startDate);
        const diffInTime = today.getTime() - date.getTime();
        const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

        if (diffInDays === 0) return 'Today';
        if (diffInDays === 1) return 'Yesterday';
        return `${diffInDays}d ago`;
    };


    const isAnnouncementValid = (endDate) => {
        const today = new Date();
        const end = new Date(endDate);
        return end.getTime() >= today.getTime();
    };


    const getBorderColor = (type) => {
        switch (type) {
            case 'Bug Fix':
                return '#ff0000';
            case 'Feature':
                return '#4caf50';
            case 'Announcement':
                return '#2196f3';
            case 'Update':
                return '#ff0000';
            default:
                return '#000';
        }
    };


    const getAnnouncements = async () => {
        setLoading(true);
        let req = new StringIntReq();
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 50;
        const res = await UsersApi.GetAllAnnouncements(req);
        if (res?.status?.success) {
            const validAnnouncements = res.data.list
                .filter(announcement => isAnnouncementValid(announcement.endDate) && announcement.isHidden === false)
                .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
                .slice(0, 2);
            setAnnouncements(validAnnouncements);
            setLoading(false);
        } else {
            console.log(res?.status?.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        getAnnouncements();
    }, []);

    return (
        <div className="announcement-banner-container">
            {
                announcements.map((announcement) => (
                    <div
                        key={announcement.id}
                        className="announcement-banner"
                        style={{ borderLeftColor: getBorderColor(announcement.announcementType) }} 
                    >
                        <div className="announcement-header">
                            <Typography className="announcement-type">
                                New {announcement.announcementType}
                            </Typography>
                            <Typography className="announcement-date">
                                {getDaysAgo(announcement.startDate)}
                            </Typography>
                        </div>
                        <Typography className="announcement-message">
                            {announcement.message}
                        </Typography>
                    </div>
                ))
            }
        </div>
    );
};

export default AnnouncementBanner;
