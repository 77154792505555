/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowRight } from '@mui/icons-material'
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import FiberHouseApi from '../../../API/FiberHouseApi'
import MapIconApi from '../../../API/MapIconApi'
import { UserContext } from '../../../App'
import SearchFiberHousesReq from '../../../Requests/FiberHouse/SearchFiberHousesReq'
import CreateFiberHouse from './components/CreateFiberHouse'
import CreateMapIcon from './components/CreateMapIcon'
import CreateServicableAreaForm from './components/CreateServicableAreaForm'
import CreateUsingCSV from './components/CreateUsingCSV'
// import EditFiberHouse from './components/EditFiberHouse'
import EditMapIcons from './components/EditMapIcons'
import ManageLeadStatuses from './components/ManageLeadStatuses'
import { Map } from './components/Map'
import SearchFilters from './components/SearchFilters'
// import ServicableAreaDetails from './components/ServicableAreaDetails'
import Stats from './components/Stats'
import Popup from './components/Popup'
import Layout from '../../../Layouts/Layout'
import ContextMenu from '../../../components/ContextMenu'
import MainLeadCard from './components/NewLeadCard/MainLeadCard'
import HotLeadForm from './components/NewLeadCard/HotLeadForm'
import FreeMonthForm from './components/NewLeadCard/FreeMonthForm'
import ActivityHistoryNew from './components/NewLeadCard/ActivityHistoryNew'
import MainBizCard from './components/DigitalBizCard/MainBizCard'
import SearchLocation from './components/SearchLocation'
import ReviewContainer from '../../Reps/DigitalBusinessCenter/components/ReviewContainer'
import Legends from './components/Legends'
import BizCardTab from './components/DigitalBizCard/BizCardTab'
import { useLocation } from 'react-router-dom'
import Modify from 'ol/interaction/Modify'
import { fromLonLat } from 'ol/proj'
import { Feature, Geolocation } from 'ol'
import { Point } from 'ol/geom'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import Swal from 'sweetalert2'
import { CircularProgress } from '@mui/material'
import SearchServicableAreaReq from '../../../Requests/ServicableArea/SearchServicableAreaReq'
import ServicableAreaApi from '../../../API/ServicableAreaApi'
import SearchSalesOrgReq from '../../../Requests/Organizations/SearchSalesOrgsReq'
import OrgApi from '../../../API/OrgApi'
import SearchUserReq from '../../../Requests/Users/SearchUserReq'
import UsersApi from '../../../API/UsersApi'
// import * as ol from 'ol'
export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const MapContext = createContext()
const Index = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);



    const location = useLocation();
    console.log(location)

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            setScreenHeight(window.innerHeight);
            console.log(screenWidth, screenHeight)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const [fiberHouses, setFiberHouses] = useState([])
    const [totalFiberHouses, setTotalFiberHouses] = useState([])
    const shouldReRenderMap = useRef(true);
    // const [selectedServicableArea, setSelectedServicableArea] = useState(null)
    const [color, setColor] = useState("#000000")
    const user = useContext(UserContext)
    const [mapLayers, setMapLayers] = useState([]);
    // const [layerss, setLayers] = useState([])
    // const [membersLoaded, setMembersLoaded] = useState(false);
    // const [availableMembersLoaded, setAvailableMembersLoaded] = useState(false);
    const [shape, setShape] = useState(null)
    const polygonRefs = useRef([]);
    const [selectedFiberHouse, setSelectedFiberHouse] = useState(null);
    const [selectedFiberHouseFeature, setSelectedFiberHouseFeature] = useState(null);
    const [selectedLead, setSelectedLead] = useState(null)
    // const [coordinates, setCoordinates] = useState(null);
    const cluster = useRef(null)
    const [servicableAreas, setServicableAreas] = useState([])

    const [mapObject, setMapObject] = useState(null);
    const [index, setIndex] = useState(-1);
    const [currentPositiionLayer, setCurrentPositionLayer] = useState(null)

    //Search Filters
    const [salesRepId, setSalesRepId] = useState(null);

    const [doorKnockingStatus, setDoorKnockingStatus] = useState(null);
    const [notes, setNotes] = useState(null);

    const [mapString, setMapString] = useState(null);

    const [customerInterestFrom, setCustomerInterestFrom] = useState(null);
    const [customerInterestTo, setCustomerInterestTo] = useState(null);
    const [salesOrgId, setSalesOrgId] = useState(null);
    const [leadStatus, setLeadStatus] = useState(null);
    const [openMobileMenu, setOpenMobileMenu] = useState(null);
    const [mapIcons, setMapIcons] = useState([])
    const [mobileMenu, setMobileMenu] = useState([])
    // const [fiberHouseTotalPages, setFiberHouseTotalPages] = useState(1);
    const [fiberHouseCurrentPage, setFiberHouseCurrentPage] = useState(1);
    const [searchArea, setSearchArea] = useState(null);
    const [searchAreaFeature, setSearchAreaFeature] = useState([]);
   // const [searchAreaFiberHouses, setSearchAreaFiberHouses] = useState(null);
    // const lightHouses = useRef([])
    const [fiberHousesLoading, setFiberHousesLoading] = useState(true)
    const [filtersLoaded, setFiltersLoaded] = useState(false)
    const [salesReps, setSalesReps] = useState([])
    // fiberHouse Ids for stats
    const [fiberHouseIds, setFiberHouseIds] = useState([]);
    const [salesOrg, setSalesOrg] = useState([]);
    const [triggerSearch, setTriggerSearch] = useState(false)
    // Assigned Leads Filters
    const [assignedLeadsEnabled, setAssignedLeadsEnabled] = useState(true);
    const [assignedCreatedFrom, setAssignedCreatedFrom] = useState(null);
    const [assignedCreatedTo, setAssignedCreatedTo] = useState(null);
    const [assignedConvertedToCustomer, setAssignedConvertedToCustomer] = useState(null);
    const [assignedAcountStatus, setAssignedAcountStatus] = useState("");
    const [assignedMapIcons, setAssignedMapIcons] = useState([]);
    const [assignedSalesOrgId, setAssignedSalesOrgId] = useState(null);
    const [assignedUpdatedFrom, setAssignedUpdatedFrom] = useState(null);
    const [assignedUpdatedTo, setAssignedUpdatedTo] = useState(null);
    const [assignedReviewType, setAssignedReviewType] = useState(null);
    const [assignedSalesOrgs, setAssignedSalesOrgs] = useState([]);
    const [assignedQuickDate, setAssignedQuickDate] = useState(location.state?.quickDateValue?location.state.quickDateValue:null);
    const [assignedSalesRepIds, setAssignedSalesRepIds] = useState([])
    const [assignedDateType, setAssignedDateType] = useState("LastUpdated")
    const [assignedRepToggle, setAssignedRepToggle] = useState("UpdatedBy")
    const [mapIconToggle, setMapIconToggle] = useState("CurrentMapIcon")
    const [assignedProspectSold, setAssignedProspectSold] = useState(null)
    const [assignedFiberCompanyIds, setAssignedFiberCompanyIds] = useState([])

    // Unassigned Leads Filters
    const [unassignedLeadsEnabled, setUnassignedLeadsEnabled] = useState(true);
    const [unassignedConvertedToCustomer, setUnassignedConvertedToCustomer] = useState(null);
    const [unassignedCreatedFrom, setUnassignedCreatedFrom] = useState(null);
    const [unassignedCreatedTo, setUnassignedCreatedTo] = useState(null);
    const [unassignedAcountStatus, setUnassignedAcountStatus] = useState("");
    const [unassignedMapIcons, setUnassignedMapIcons] = useState([]);
    const [unassignedSalesOrgId, setUnassignedSalesOrgId] = useState(null);
    const [unassignedUpdatedFrom, setUnassignedUpdatedFrom] = useState(null);
    const [unassignedUpdatedTo, setUnassignedUpdatedTo] = useState(null);
    const [unassignedReviewType, setUnassignedReviewType] = useState(null);
    const [unassignedSalesOrgs, setUnassignedSalesOrgs] = useState([]);
    const [unassignedQuickDate, setUnassignedQuickDate] = useState(location.state?.quickDateValue?location.state.quickDateValue:null);
    const [unassignedDateType, setUnassignedDateType] = useState("LastUpdated")
    const [unassignedFiberCompanyIds, setUnassignedFiberCompanyIds] = useState([])
    const [assignedCompanyWithName, setAssignedCompanyWithName] = useState(null)
    const [unassignedCompanyWithName, setUnassignedCompanyWithName] = useState(null)
    // save 

    const initialRender = useRef(true);
    const [usingLight, setUsingLight] = useState(true)
    useEffect(
        () => {
            if (mapLayers.length > 0) {
                polygonRefs.current[polygonRefs.current.length - 1].setStyle({ color: color })
            }
        }, [color]
    )

    const getMapIcons = async () => {
        const res = await MapIconApi.GetAllMapIcons()
        if (res?.status?.success) {
            // sort all the icons by the order 
            res.data.sort((a, b) => a.order - b.order)
            // console.log(res.data)
            setMapIcons(res.data)
            if (!location?.state?.mapIcons || location?.state?.mapIcons.length === 0) {
                setAssignedMapIcons(res.data.map(m => m.id))
            }
            if (!location?.state?.mapIcons || location?.state?.mapIcons.length === 0) {
                setUnassignedMapIcons(res.data.map(m => m.id))
            }
        }
    }


    const getSalesOrg = async () => {
        const req = new SearchSalesOrgReq();
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 100;
        const res = await OrgApi.SearchOrg(req);
        if (res?.status?.success) {
            setSalesOrg(res.data.list);
            if (!location?.state?.orgIds || location?.state?.orgIds.length === 0) {
                setAssignedSalesOrgs(res.data.list.map(m => m.id))
            }

            if (!location?.state?.orgIds || location?.state?.orgIds.length === 0) {
                setUnassignedSalesOrgs(res.data.list.map(m => m.id))
            }
        }
    }

    async function getSalesReps() {
        const req = new SearchUserReq();
        req.pagingParams.pageNumber = 1
        req.pagingParams.pageSize = 100
        req.userTypes = ["SalesRep", "SalesOrgAdmin"]

        if (user?.userType !== "SuperAdmin") {
            req.salesOrgId = user.salesOrgId
        }

        const res = await UsersApi.SearchUser(req)
        if (res?.status?.success) {
            setSalesReps(res.data.list)
            if (!location?.state?.salesReps || location?.state?.salesReps.length === 0) {
                setAssignedSalesRepIds(res.data.list.map(m => m.id))
            }
        }
    }


    useEffect(() => {
        if (mapIcons == null || mapIcons.length === 0) {
            getMapIcons()
        }
    }, []
    )

    useEffect(() => {
        if (salesOrg == null || salesOrg.length === 0) {
            getSalesOrg()
        }
    }, []
    )


    useEffect(() => {
        if ((salesReps == null || salesReps.length === 0)) {
            getSalesReps()
        }
    }, [])

    const servicableAreaClicked = (id, points, area) => {

        if (index !== 3) {
            setIndex(4);
            // setSelectedServicableArea(area)
            console.log(area)
            // setMembersLoaded(false)
            // setAvailableMembersLoaded(false)
        }

    }

    useEffect(
        () => {
            if (location.state?.fiberHouse) {
                console.log(location.state.fiberHouse)
                setSelectedFiberHouse(location.state.fiberHouse)
                setIndex(10)
                console.log("fiber house", location.state.fiberHouse)
            }

        }, []
    )

    // useEffect(
    //     () => {
    //         if (location.state?.fiberHouse && mapObject) {
    //             mapObject.getView().setCenter(fromLonLat([location.state.fiberHouse.coordinates.longitude, location.state.fiberHouse.coordinates.latitude]))
    //             mapObject.getView().setZoom(18)
    //             console.log("fiber house", location.state.fiberHouse)
    //         }
    //         else if (!location.state?.fiberHouse.id && mapObject) {
    //             navigator.geolocation.getCurrentPosition(
    //                 (position) => {
    //                     const center = fromLonLat([
    //                         position.coords.longitude,
    //                         position.coords.latitude,
    //                     ])
    //                     mapObject.getView().animate({ zoom: 15, duration: 1000, center: center });


    //                     const geolocation = new Geolocation({
    //                         trackingOptions: {
    //                             enableHighAccuracy: true,

    //                         },
    //                         projection: mapObject?.getView().getProjection(),
    //                     })

    //                     if(currentPositiionLayer){
    //                         mapObject.removeLayer(currentPositiionLayer);
    //                         setCurrentPositionLayer(null)
    //                     }

    //                     console.log("geolocation", geolocation.getPosition())
    //                     const positionFeature = new Feature(
    //                         new Point(center),
    //                     );
    //                     const iconStyle = new Style({
    //                         image: new CircleStyle({
    //                             radius: 12,
    //                             stroke: new Stroke({
    //                                 color: "#fff",
    //                                 width: 2,
    //                             }),
    //                             fill: new Fill({
    //                                 color: "#3399CC",
    //                             }),
    //                         }),
    //                     });
    //                     positionFeature.setStyle(iconStyle);
    //                     // const geolocationControl = map.getControlsByType('Geolocation')[0];
    //                     // if (geolocationControl) {
    //                     //   // If it exists, we don't need to create a new one
    //                     //   console.log("Using existing Geolocation control");
    //                     //   return;
    //                     // }


    //                     const vectorSource = new VectorSource({
    //                         features: [positionFeature],
    //                     });

    //                     const vectorLayer = new VectorLayer({
    //                         source: vectorSource,
    //                     });
    //                     vectorLayer.setZIndex(1000);

    //                     mapObject.addLayer(vectorLayer);
    //                     setCurrentPositionLayer(vectorLayer)
    //                     geolocation.on("change", function () {
    //                         const coordinates = geolocation.getPosition();
    //                         console.log("coordinates", coordinates)
    //                         if(currentPositiionLayer && coordinates){
    //                             positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
    //                         }
    //                     });
    //                     geolocation.setTracking(true);
    //                 })
    //         }
    //     }, [mapObject]
    // )

    useEffect(
        () => {
            if (initialRender.current) {
                return
            }

            if (location.state?.fiberHouse && mapObject) {
                mapObject.getView().setCenter(fromLonLat([location.state.fiberHouse.coordinates.longitude, location.state.fiberHouse.coordinates.latitude]))
                mapObject.getView().setZoom(18)
                console.log("fiber house", location.state.fiberHouse)
            }
            else if (!location.state?.fiberHouse?.id && mapObject) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const center = fromLonLat([
                            position.coords.longitude,
                            position.coords.latitude,
                        ])
                        // mapObject.getView().setCenter(center)
                        // mapObject.getView().setZoom(18)
                        try {
                            mapObject.getView().animate({ center: center, zoom: 18, duration: 500 });
                        }
                        catch (e) {
                            console.error(e)
                        }
                        const geolocation = new Geolocation({
                            trackingOptions: {
                                enableHighAccuracy: true,
                            },
                            projection: mapObject?.getView().getProjection(),
                        })
                        console.log("geolocation", geolocation.getPosition())
                        if (currentPositiionLayer) {
                            mapObject.removeLayer(currentPositiionLayer);
                            setCurrentPositionLayer(null)
                        }

                        const positionFeature = new Feature(
                            new Point(center),
                        );
                        const iconStyle = new Style({
                            image: new CircleStyle({
                                radius: 12,
                                stroke: new Stroke({
                                    color: "#fff",
                                    width: 2,
                                }),
                                fill: new Fill({
                                    color: "#3399CC",
                                }),
                            }),
                        });
                        positionFeature.setStyle(iconStyle);
                        // const geolocationControl = map.getControlsByType('Geolocation')[0];
                        // if (geolocationControl) {
                        //   // If it exists, we don't need to create a new one
                        //   console.log("Using existing Geolocation control");
                        //   return;
                        // }


                        const vectorSource = new VectorSource({
                            features: [positionFeature],
                        });

                        const vectorLayer = new VectorLayer({
                            source: vectorSource,
                        });
                        vectorLayer.setZIndex(1000);

                        mapObject.addLayer(vectorLayer);
                        setCurrentPositionLayer(vectorLayer)

                        geolocation.on("change", function () {
                            const coordinates = geolocation.getPosition();
                            console.log("coordinates", coordinates)
                            positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
                            // mapObject.getView().setZoom(mapObject.getView().getZoom() + 0.0001)
                        });
                        geolocation.setTracking(true);

                    })
            }
            return () => {
                if (currentPositiionLayer) {
                    mapObject.removeLayer(currentPositiionLayer)
                    setCurrentPositionLayer(null)
                }
            }
        }, [mapObject]
    )

    const fiberHouseClicked = async (fh) => {
        // if(mapObject)
        console.log(mapObject)
        // mapObject.getInteractions().forEach(interaction => {
        //     if (interaction instanceof Modify) {
        //         console.log("clearing")
        //         mapObject.removeInteraction(interaction)
        //     }
        // })

        setSelectedFiberHouse(fh);
        console.log(fh)
        // find and set the feature to the selected fiber house

        // console.log(mapObject.getLayers().getArray())
        setIndex(10);
    }

    const searchFiberHouses = async (isFirstSearch, pageSize = 500, pageNumber = 1) => {
        // if(!usingLight){
        //     return
        // }
        setFiberHousesLoading(true)

        const req = new SearchFiberHousesReq()
        req.pagingParams.pageNumber = pageNumber
        req.pagingParams.pageSize = pageSize




        if (user?.userType === "SalesOrgAdmin") {
            req.salesOrgId = user.salesOrgId
            req.teamAdminId = user.id
        }

        if (user?.userType === "SalesRep") {

            req.salesRepId = user.id
            req.salesOrgId = user.salesOrgId
        }

        // assigned filters
        req.assignedEnabled = assignedLeadsEnabled;
        req.assignedConvertedToCustomer = assignedLeadsEnabled ? assignedConvertedToCustomer : null;
        if (assignedAcountStatus === "CancelsPending") {
            req.assignedIssueType = 'Cancel'
        }
        else {
            req.assignedAccountStatus = assignedAcountStatus !== "" ? assignedAcountStatus : null;
        }
        req.assignedSalesOrgId = assignedSalesOrgId
        req.assignedSalesRepIds = assignedSalesRepIds.length === salesReps.length || salesReps.length === 0 ? null : assignedSalesRepIds
        req.assignedSalesOrgIds = assignedLeadsEnabled ? (assignedSalesOrgs.length === salesOrg.length || salesOrg.length === 0 ? null : assignedSalesOrgs) : null;
        req.assignedMapIconIds = assignedLeadsEnabled ? (assignedMapIcons.length === mapIcons.length || mapIcons.length === 0 ? null : assignedMapIcons) : null;
        req.assignedUpdatedBy = assignedRepToggle !== "AssignedTo" ? true : false
        req.assignedProspectSold = assignedProspectSold
        req.assignedFiberCompanyIds = assignedCompanyWithName?assignedCompanyWithName.map(c => c.id) :assignedFiberCompanyIds
        req.assignedMapIconOnceWas = mapIconToggle === "OnceWas" ? true : false
        switch (assignedDateType) {
            case "LastUpdated":
                if (assignedUpdatedFrom) {
                    req.assignedLeadUpdatedFrom = assignedUpdatedFrom
                }
                if (assignedUpdatedTo) {
                    req.assignedLeadUpdatedTo = assignedUpdatedTo
                }
                break;
            case "DateSold":
                if (assignedUpdatedFrom) {
                    req.assignedLeadTimeStampFrom = assignedUpdatedFrom
                }
                if (assignedUpdatedTo) {

                    req.assignedLeadTimeStampTo = assignedUpdatedTo
                }
                break;
            case "InstallDate":
                if (assignedUpdatedFrom) {
                    req.assignedLeadInstallDateFrom = assignedUpdatedFrom
                }
                if (assignedUpdatedTo) {
                    req.assignedLeadInstallDateTo = assignedUpdatedTo
                }

                break;
            case "AppointmentDate":
                if (assignedUpdatedFrom) {
                    req.assignedLeadAppointmentDateFrom = assignedUpdatedFrom
                }
                if (assignedUpdatedTo) {
                    req.assignedLeadAppointmentDateTo = assignedUpdatedTo
                }
                break;
            case "CreatedAt":
                if (assignedUpdatedFrom) {
                    req.assignedCreatedFrom = assignedUpdatedFrom
                }
                if (assignedUpdatedTo) {
                    req.assignedCreatedTo = assignedUpdatedTo
                }
                break;
            default:
                break;
        }

        if (isFirstSearch && location.state?.mapIcons?.length > 0) {
            req.assignedMapIconIds = location.state.mapIcons
            setAssignedMapIcons(location.state.mapIcons)
        }

        if (isFirstSearch && location.state?.fromDate) {
            req.assignedLeadUpdatedFrom = location.state.fromDate

            setAssignedUpdatedFrom(location.state.fromDate)
        }

        if (isFirstSearch && location.state?.toDate) {
            req.assignedLeadUpdatedTo = location.state.toDate
            setAssignedUpdatedTo(location.state.toDate)
        }

        if (isFirstSearch && location.state?.salesReps?.length > 0) {
            req.assignedSalesRepIds = location.state.salesReps
            setAssignedSalesRepIds(location.state.salesReps)
        }

        if (isFirstSearch && location.state?.orgIds?.length > 0) {
            req.assignedSalesOrgIds = location.state.orgIds
            setAssignedSalesOrgs(location.state.orgIds)
        }

        if(isFirstSearch && location.state?.iconOnceWas){
            req.assignedMapIconOnceWas = location.state.iconOnceWas
            setMapIconToggle("OnceWas")
        }

        if(isFirstSearch && location.state?.fiberCompanies){
            
            req.assignedFiberCompanyIds = location.state.fiberCompanies.map( f => f.id)
            setAssignedFiberCompanyIds(location.state.fiberCompanies.map(f => f.id))
            setAssignedCompanyWithName(location.state.fiberCompanies)
            console.log("fiber companies", req.assignedFiberCompanyIds)
        }


        // unassigned filters
        req.unassignedEnabled = unassignedLeadsEnabled;

        req.unassignedConvertedToCustomer = unassignedLeadsEnabled ? unassignedConvertedToCustomer : null;
        req.unassignedSalesOrgId = unassignedSalesOrgId
        if (unassignedAcountStatus === "CancelsPending") {
            req.unassignedIssueType = 'Cancel'
        }
        else {
            req.unassignedAccountStatus = unassignedAcountStatus !== "" ? unassignedAcountStatus : null;
        }
        req.unassignedSalesOrgIds = unassignedLeadsEnabled ? (unassignedSalesOrgs.length === salesOrg.length || salesOrg.length === 0 ? null : unassignedSalesOrgs) : null;
        req.unassignedMapIconIds = unassignedLeadsEnabled ? (unassignedMapIcons.length === mapIcons.length || mapIcons.length === 0 ? null : unassignedMapIcons) : null;
        req.unassignedFiberCompanyIds = unassignedCompanyWithName?unassignedCompanyWithName.map(c => c.id) :unassignedFiberCompanyIds
      
        switch (unassignedDateType) {
            case "LastUpdated":
                if (unassignedUpdatedFrom) {
                    req.unassignedLeadUpdatedFrom = unassignedUpdatedFrom
                }
                if (unassignedUpdatedTo) {
                    req.unassignedLeadUpdatedTo = unassignedUpdatedTo
                }
                break;
            case "DateSold":
                if (unassignedUpdatedFrom) {
                    req.unassignedLeadTimeStampFrom = unassignedUpdatedFrom
                }
                if (unassignedUpdatedTo) {
                    req.unassignedLeadTimeStampTo = unassignedUpdatedTo
                }
                break;
            case "InstallDate":
                if (unassignedUpdatedFrom) {
                    req.unassignedLeadInstallDateFrom = unassignedUpdatedFrom
                }
                if (unassignedUpdatedTo) {
                    req.unassignedLeadInstallDateTo = unassignedUpdatedTo
                }

                break;
            case "AppointmentDate":
                if (unassignedUpdatedFrom) {
                    req.unassignedLeadAppointmentDateFrom = unassignedUpdatedFrom
                }
                if (unassignedUpdatedTo) {
                    req.unassignedLeadAppointmentDateTo = unassignedUpdatedTo
                }
                break;
            default:
                break;
        }


        if (isFirstSearch && location.state?.mapIcons?.length > 0) {
            req.unassignedMapIconIds = location.state.mapIcons
            setUnassignedMapIcons(location.state.mapIcons)
        }

        if (isFirstSearch && location.state?.fromDate) {
            req.unassignedLeadUpdatedFrom = location.state.fromDate

            setUnassignedUpdatedFrom(location.state.fromDate)
        }

        if (isFirstSearch && location.state?.toDate) {
            req.unassignedLeadUpdatedTo = location.state.toDate
            setUnassignedUpdatedTo(location.state.toDate)
            // req.unassignedEnabled = false
            // setUnassignedLeadsEnabled(false)
        }

        if(isFirstSearch && location.state?.fiberCompanies){
            req.unassignedFiberCompanyIds = location.state.fiberCompanies.map( f => f.id)
            setUnassignedFiberCompanyIds(location.state.fiberCompanies.map(f => f.id))
            setUnassignedCompanyWithName(location.state.fiberCompanies)
            console.log("fiber companies", req.unassignedFiberCompanyIds)
        }


        const res = await FiberHouseApi.SearchFiberHousesOptimizedLightAssignedUnassigned(req) // SEARCHING x4 TIMES???
        if (res?.status?.success) {
            setFiberHouses(res.data)
            setFiberHousesLoading(false)
        }

    }

    useEffect(() => {
        setFiltersLoaded(true)
    }, [])

    useEffect(() => {
        if (filtersLoaded) {
            searchFiberHouses(true)
        }
    }, [filtersLoaded])


    async function searchServicableAreas() {
        const req = new SearchServicableAreaReq();
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 100;
        const res = await ServicableAreaApi.SearchServicableArea(req);
        if (res?.status?.success) {

            const list = res?.data?.map(
                (area, index) => {
                    return {
                        latlngs: area.coordinates.map(
                            (point) => {
                                return [point.latitude, point.longitude]
                            }
                        ),
                        color: area.color,
                        id: area.id,
                        name: area.name,
                        fiberCompanyId: area.fiberCompanyId,
                        salesOrgId: area.salesOrgId,

                    }
                }
            )
            setServicableAreas(list);
        }
    }


    useEffect(
        () => {
            if (initialRender.current) {
                searchServicableAreas()
                initialRender.current = false;
            }

        }, [])




    useEffect(
        () => {
            setMapLayers([])
            polygonRefs.current[polygonRefs.current.length - 1]?.setStyle({ display: "none" })
        }, [index])


    const updateFiberHouseInContext = (id, newProperties) => {
        console.log("updated", id, newProperties)
        setFiberHouses(prevFiberHouses =>
            prevFiberHouses.map(fiberHouse =>
                fiberHouse.id === id 
                    ? { ...fiberHouse, ...newProperties } 
                    : fiberHouse
            )
        );
    };
        
        


    const getSubTab = () => {



        switch (index) {
            case 0:
                return <CreateServicableAreaForm searchServicableAreas={searchServicableAreas} layers={shape} setLayers={setShape} color={color} setColor={setColor} fiberHouses={fiberHouses} />
            case 1:
                return <CreateMapIcon />
            case 2:
                return <CreateFiberHouse />
            // case 3:
            //     return <EditFiberHouse setIndex={setIndex} mapMovement={false} coordinates={coordinates} fiberHouse={selectedFiberHouse} searchFiberHouses={searchFiberHouses} />
            // case 4:
            //     return <ServicableAreaDetails availableMembersLoaded={availableMembersLoaded}
            //         selectedServicableArea={selectedServicableArea} servicableAreaClicked={servicableAreaClicked} membersLoaded={membersLoaded} />
            case 5:
                return <EditMapIcons />
            case 6:
                return <CreateUsingCSV user={user} />
            case 7:
                return <SearchFilters
                    defaultFiberCompanies={location.state?.fiberCompanies}
                    assignedDateType={assignedDateType} setAssignedDateType={setAssignedDateType} unassignedDateType={unassignedDateType} setUnassignedDateType={setUnassignedDateType}
                    unassignedConvertedToCustomer={unassignedConvertedToCustomer} setUnassignedConvertedToCustomer={setUnassignedConvertedToCustomer} unassignedCreatedFrom={unassignedCreatedFrom} setUnassignedCreatedFrom={setUnassignedCreatedFrom} unassignedCreatedTo={unassignedCreatedTo} setUnassignedCreatedTo={setUnassignedCreatedTo} unassignedAcountStatus={unassignedAcountStatus} setUnassignedAcountStatus={setUnassignedAcountStatus} unassignedMapIcons={unassignedMapIcons} setUnassignedMapIcons={setUnassignedMapIcons}
                    assignedCreatedFrom={assignedCreatedFrom} setAssignedCreatedFrom={setAssignedCreatedFrom} assignedCreatedTo={assignedCreatedTo} setAssignedCreatedTo={setAssignedCreatedTo} assignedAcountStatus={assignedAcountStatus} setAssignedAcountStatus={setAssignedAcountStatus} fiberHouses={fiberHouses} searchFiberHouses={searchFiberHouses} salesRepId={salesRepId} setSalesRepId={setSalesRepId} doorKnockingStatus={doorKnockingStatus}
                    setDoorKnockingStatus={setDoorKnockingStatus} setSalesOrgId={setSalesOrgId} salesOrgId={salesOrgId} leadStatus={leadStatus} setLeadStatus={setLeadStatus}
                    notes={notes} setNotes={setNotes} assignedMapIcons={assignedMapIcons} setAssignedMapIcons={setAssignedMapIcons} mapString={mapString} setMapString={setMapString} assignedConvertedToCustomer={assignedConvertedToCustomer} setAssignedConvertedToCustomer={setAssignedConvertedToCustomer}
                    customerInterestFrom={customerInterestFrom} setCustomerInterestFrom={setCustomerInterestFrom} customerInterestTo={customerInterestTo} setCustomerInterestTo={setCustomerInterestTo} />
            case 8:
                // if (searchAreaFeature.length === 0) {
                //     Swal.fire({
                //         icon: 'error',
                //         title: "Please Mark an Area on the Map",
                //         showConfirmButton: true,
                //         confirmButtonText: "OK"
                //     })
                //     setIndex(-1)
                //     return
                // }
                return <Stats fiberHouses={fiberHouses} />
            case 9:
                return <ManageLeadStatuses />
            case 10:
                // return <EditLead fiberHouse={selectedFiberHouse} searchFiberHouses={searchFiberHouses} lead={selectedFiberHouse?.lead} user={user} />
                return <MainLeadCard searchFiberHouses={searchFiberHouses} lead={selectedFiberHouse?.lead} selectedFiberHouse={selectedFiberHouse} user={user} setIndex={setIndex} />
            case 11:
                return <HotLeadForm lead={selectedLead} searchFiberHouses={searchFiberHouses} fiberHouse={selectedFiberHouse} user={user} />
            case 12:
                return <FreeMonthForm lead={selectedLead} searchFiberHouses={searchFiberHouses} fiberHouse={selectedFiberHouse} user={user} setIndex={setIndex} />
            case 13:
                return <ActivityHistoryNew lead={selectedLead} searchFiberHouses={searchFiberHouses} fiberHouse={selectedFiberHouse} user={user} setIndex={setIndex} />
            case 14:
                return <MainBizCard lead={selectedLead} searchFiberHouses={searchFiberHouses} fiberHouse={selectedFiberHouse} user={user} setIndex={setIndex} />
            case 15:
                return <ReviewContainer searchArea={searchArea}  />
            case 16:
                return <Legends />
            case 17:
                return <BizCardTab lead={selectedLead} />
            default:
                return <></>
        }
    }

    const closeTab = () => {
        setIndex(-1)
    }

    const mM = [
        {
            title: "Create Servicable Area",
            key: 'createServicableArea',
            onClick: () => { setIndex(0) },
            user: ["SuperAdmin"]
        },
        {
            title: "Create Map Icon",
            key: 'createMapIcon',
            onClick: () => { setIndex(1) },
            user: ["SuperAdmin", "Admin"]
        },
        {
            title: "Edit Map Icons",
            key: 'editMapIcons',
            onClick: () => { setIndex(5) },
            user: ["SuperAdmin", "Admin"]
        },
        // {
        //     title: "Create Fiber House",
        //     key: 'createFiberHouse',
        //     onClick: () => { setIndex(2) },
        //     user: ["SuperAdmin", "Admin", "SalesOrgAdmin", "SalesRep"]
        // },
        {
            title: "Bulk Create FiberHouses",
            key: 'bulkCreateFiberHouses',
            onClick: () => { setIndex(6) },
            user: ["SuperAdmin"]
        },
        // {
        //     title: "Search Filters",
        //     key: 'searchFilters',
        //     onClick: () => { setIndex(7) },
        //     user: ["SuperAdmin", "Admin", "SalesOrgAdmin", "SalesRep"]
        // },
        {
            title: "Stats",
            key: 'stats',
            onClick: () => {
                // if (fiberHouses.length > 10000) {
                //     Swal.fire({
                //         icon: "error",
                //         title: "Too many fiber houses",
                //         text: "Please draw an area to narrow down the search",
                //         allowOutsideClick: false,
                //         allowEscapeKey: false,
                //         allowEnterKey: false,
                //         showConfirmButton: true,
                //         showCancelButton: false,
                //     });
                //     return
                // }
                // else if (fiberHouses.length === 0) {
                //     Swal.fire({
                //         icon: "error",
                //         title: "No fiber houses",
                //         text: "Please adjust the search filters",
                //         allowOutsideClick: false,
                //         allowEscapeKey: false,
                //         allowEnterKey: false,
                //         showConfirmButton: true,
                //         showCancelButton: false,
                //     });
                //     return
                // }
                if (searchAreaFeature.length === 0) {
                    Swal.fire({
                        icon: 'error',
                        title: "Please Mark an Area on the Map",
                        showConfirmButton: true,
                        confirmButtonText: "OK"
                    })
                    // setIndex(-1)
                    return
                }
                setIndex(8)
            },
            user: ["SuperAdmin", "Admin", "SalesOrgAdmin", "SalesRep"]
        },
        {
            title: "Manage Lead Statuses",
            key: 'manageLeadStatuses',
            onClick: () => { setIndex(9) },
            user: ["SuperAdmin", "Admin"]
        },
        // {
        //     title: "Reviews",
        //     key: "feedbacks",
        //     onClick: () => { setIndex(15) },
        //     user: ["SuperAdmin", "Admin", "SalesOrgAdmin", "SalesRep"]
        // },
        {
            title: "Legends",
            key: "legends",
            onClick: () => { setIndex(16) },
            user: ["SuperAdmin", "Admin", "SalesOrgAdmin", "SalesRep"]
        }
    ]
    useEffect(() => {
        const menu = mM.filter((item) => {
            return item.user.includes(user.userType)
        })
        setMobileMenu(menu)

    }, [index])

    useEffect(
        ()=>{
            return () => {
                setMapObject(null)
                setFiberHouses([])
            }
        },[]
    )

    return (
        <Layout>
            <MapContext.Provider value={{
                cluster,
                assignedCompanyWithName, setAssignedCompanyWithName, unassignedCompanyWithName, setUnassignedCompanyWithName,
                assignedFiberCompanyIds,setAssignedFiberCompanyIds,unassignedFiberCompanyIds,setUnassignedFiberCompanyIds,
                mapIconToggle,setMapIconToggle,triggerSearch, setTriggerSearch, assignedRepToggle, setAssignedRepToggle, assignedProspectSold, setAssignedProspectSold,
                shouldReRenderMap, salesReps, assignedQuickDate, setAssignedQuickDate, unassignedQuickDate, setUnassignedQuickDate, assignedAcountStatus, setAssignedAcountStatus, unassignedAcountStatus, setUnassignedAcountStatus,
                salesOrg, assignedSalesOrgs, setAssignedSalesOrgs, unassignedSalesOrgs, setUnassignedSalesOrgs, assignedMapIcons, unassignedMapIcons, assignedSalesRepIds, setAssignedSalesRepIds,
                setFiberHousesLoading, servicableAreas, searchServicableAreas, updateFiberHouseInContext,
                assignedReviewType, setAssignedReviewType, unassignedReviewType, setUnassignedReviewType,
                totalFiberHouses, setTotalFiberHouses, usingLight, setUsingLight, fiberHouseIds, setAssignedUpdatedFrom, assignedUpdatedFrom, setAssignedUpdatedTo, assignedUpdatedTo, setUnassignedUpdatedFrom, unassignedUpdatedFrom, setUnassignedUpdatedTo, unassignedUpdatedTo, setFiberHouseIds, assignedSalesOrgId, setAssignedSalesOrgId, unassignedSalesOrgId, setUnassignedSalesOrgId, setSelectedFiberHouseFeature, selectedFiberHouseFeature, assignedLeadsEnabled, setAssignedLeadsEnabled, unassignedLeadsEnabled, setUnassignedLeadsEnabled, setSelectedLead, selectedLead, setOpenMobileMenu, searchFiberHouses, searchAreaFeature, setSearchAreaFeature, searchArea, setSearchArea, selectedFiberHouse, setSelectedFiberHouse, fiberHouses, fiberHouseCurrentPage, setFiberHouseCurrentPage, index, setFiberHouses, mapObject, setMapObject, closeTab, Index, setIndex, mapIcons, getMapIcons, fiberHouseClicked
            }}>
                <div className='map_page_container'>
                    <div className='relative' style={{
                        height: "100%",
                        width: "100%",
                    }} >
                        {
                            fiberHousesLoading &&
                            <div className='transparent_loader'>
                                <div className='loader_text'>
                                    <p>Loading Fiber Houses</p>
                                    <CircularProgress sx={{ color: "#333" }} size={30} />
                                </div>
                            </div>
                        }

                        <SearchLocation setIndex={setIndex} setSelectedFiberHouse={setSelectedFiberHouse} fiberHouses={fiberHouses} />
                        <Map
                            currentPositionLayer={currentPositiionLayer} setCurrentPositionLayer={setCurrentPositionLayer}
                            // shared leads filters
                            unassignedConvertedToCustomer={unassignedConvertedToCustomer} unassignedCreatedFrom={unassignedCreatedFrom} unassignedCreatedTo={unassignedCreatedTo} unassignedAcountStatus={unassignedAcountStatus} unassignedMapIcons={unassignedMapIcons}
                            // leads filters
                            leadCustomer={assignedConvertedToCustomer} leadFrom={assignedCreatedFrom} leadTo={assignedCreatedTo} leadHasReviews={assignedAcountStatus} leadMapIcons={assignedMapIcons}
                            propFiberHouse={location.state?.fiberHouse} displayMarker={index === 2} shape={shape} setShape={setShape} servicableAreaClicked={servicableAreaClicked}
                            fiberHouseClicked={fiberHouseClicked} color={color} fiberHouses={fiberHouses} setFiberHouses={setFiberHouses} />

                        {screenWidth <= 1000 &&
                            <>
                                <ContextMenu anchorEl={openMobileMenu} handleClose={() => setOpenMobileMenu(null)} menuItems={mobileMenu} />
                                {index >= 0 && <Popup open={true} onClose={() => { setIndex(-1) }} >
                                    {
                                        getSubTab()
                                    }
                                </Popup>}
                            </>
                        }
                    </div>
                    {screenWidth > 1000 && <div className="MapPageBottom">

                        <div className='flex sm:w-3/12 w-full flex-col gap-4 bg-white  rounded-lg p-8 h-fit MapPageSideMenu'>
                            {(user?.userType === "SuperAdmin") &&
                                <div onClick={() => { setIndex(0) }} className={`flex items-center cursor-pointer hover:text-[#2761D0] ${index === 0 ? "text-[#2761D0]" : ""}`}>
                                    <ArrowRight fontSize="small" />
                                    <p>Create Servicable Area</p>
                                </div>
                            }
                            {(user?.userType === "SuperAdmin" || user?.userType === "Admin") &&
                                <>
                                    <div onClick={() => { setIndex(1) }} className={`flex items-center cursor-pointer hover:text-[#2761D0] ${index === 1 ? "text-[#2761D0]" : ""}`}>
                                        <ArrowRight fontSize="small" />
                                        <p>Create Map Icon</p>
                                    </div>
                                    <div onClick={() => { setIndex(5) }} className={`flex items-center cursor-pointer hover:text-[#2761D0] ${index === 1 ? "text-[#2761D0]" : ""}`}>
                                        <ArrowRight fontSize="small" />
                                        <p>Edit Map Icons</p>
                                    </div>
                                    <div onClick={() => { setIndex(9) }} className={`flex items-center cursor-pointer hover:text-[#2761D0] ${index === 9 ? "text-[#2761D0]" : ""}`}>
                                        <ArrowRight fontSize="small" />
                                        <p>Manage Lead Statuses</p>
                                    </div>
                                </>
                            }
                            {/* <div onClick={() => { setIndex(2) }} className={`flex items-center cursor-pointer hover:text-[#2761D0] ${index === 2 ? "text-[#2761D0]" : ""}`}>
                                <ArrowRight fontSize="small" />
                                <p>Create Fiber House</p>
                            </div> */}
                            {
                                user.userType === "SuperAdmin" &&
                                <div onClick={() => { setIndex(6) }} className={`flex items-center cursor-pointer hover:text-[#2761D0] ${index === 6 ? "text-[#2761D0]" : ""}`}>
                                    <ArrowRight fontSize="small" />
                                    <p>Bulk Create Fiber Houses</p>
                                </div>}
                            {/* <div onClick={() => { setIndex(7) }} className={`flex items-center cursor-pointer hover:text-[#2761D0] ${index === 7 ? "text-[#2761D0]" : ""}`}>
                                <ArrowRight fontSize="small" />
                                <p>Search Filters</p>
                            </div> */}
                            <div onClick={() => {
                                // if (fiberHouses.length > 10000) {
                                //     Swal.fire({
                                //         icon: "error",
                                //         title: "Too many fiber houses",
                                //         text: "Please draw an area to narrow down the search",
                                //         allowOutsideClick: false,
                                //         allowEscapeKey: false,
                                //         allowEnterKey: false,
                                //         showConfirmButton: true,
                                //         showCancelButton: false,
                                //     });
                                //     return
                                // }
                                // else if (fiberHouses.length === 0) {
                                //     Swal.fire({
                                //         icon: "error",
                                //         title: "No fiber houses",
                                //         text: "Please adjust the search filters",
                                //         allowOutsideClick: false,
                                //         allowEscapeKey: false,
                                //         allowEnterKey: false,
                                //         showConfirmButton: true,
                                //         showCancelButton: false,
                                //     });
                                //     return
                                // }
                                if (searchAreaFeature.length === 0) {
                                    Swal.fire({
                                        icon: 'error',
                                        title: "Please Mark an Area on the Map",
                                        showConfirmButton: true,
                                        confirmButtonText: "OK"
                                    })
                                    // setIndex(-1)
                                    return
                                }
                                setIndex(8)
                            }} className={`flex items-center cursor-pointer hover:text-[#2761D0] ${index === 8 ? "text-[#2761D0]" : ""}`}>
                                <ArrowRight fontSize="small" />
                                <p>Stats</p>
                            </div>

                            {/* {
                                user?.userType === "SuperAdmin" || user?.userType === "Admin" || user?.userType == "SalesRep" || user?.userType === "SalesOrgAdmin" ?
                                    <div onClick={() => { setIndex(15) }} className={`flex items-center cursor-pointer hover:text-[#2761D0] ${index === 15 ? "text-[#2761D0]" : ""}`}>
                                        <ArrowRight fontSize="small" />
                                        <p>Reviews</p>
                                    </div>
                                    : <></>
                            } */}
                            <div onClick={() => { setIndex(16) }} className={`flex items-center cursor-pointer hover:text-[#2761D0] ${index === 8 ? "text-[#2761D0]" : ""}`}>
                                <ArrowRight fontSize="small" />
                                <p>Legends</p>
                            </div>
                        </div>
                        <div className={`${index >= 0 && "sm:w-8/12 w-full flex-col gap-4 p-4 bg-white rounded-lg MapFormDiv"}`}>
                            {getSubTab()}
                        </div>
                    </div>}
                </div>
            </MapContext.Provider>
        </Layout>
    )
}
export { MapContext }
export default Index;