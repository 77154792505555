import React, { useState } from 'react';
import Layout from '../../../Layouts/Layout'
import Leads from './Leads';
import TeamMembers from './TeamMembers';
import './ContactCenter.scss';
import { useLocation } from 'react-router-dom';
const ContactCenter = () => {
    const [tab, setTab] = useState('Leads');
    const location = useLocation();
    const { fromDate, toDate, salesReps, quickDateValue, type, passedOrgIds,knocked,leadType,dateType,fiberCompanies, customerName, source } = location.state || {};
    console.log(fromDate, toDate, salesReps, quickDateValue, type, passedOrgIds,knocked,leadType,dateType,fiberCompanies, customerName, source)
    return (
        <Layout>
            <div className="ContactCenter">
                <div className="HeaderDiv">
                    <button className={`${tab==='Leads' && 'active'}`} onClick={() => setTab('Leads')}>Leads</button>
                    <button className={`${tab==='Team' && 'active'}`} onClick={() => setTab('Team')}>Team</button>
                </div>
                <div className="ContentDiv">
                    {tab === 'Leads' ? (
                        <Leads fiberCompanyIds={fiberCompanies} knocked={knocked} leadType={leadType} dateTypeProp={dateType} fromDate={fromDate} toDate={toDate} salesReps={salesReps} quickDateValue={quickDateValue} type={type} passedOrgIds={passedOrgIds} customerName={customerName} source={source}/>
                    ) : (
                        <TeamMembers />
                    )}
                </div>
            </div>
            
        </Layout>
    );
};

export default ContactCenter;
