/* eslint-disable react-hooks/exhaustive-deps */

import { fromLonLat, toLonLat } from 'ol/proj';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MapContext as MP } from '..';
import Swal from 'sweetalert2';
import AssignLeadsToRepsReq from '../../../../Requests/FiberHouse/AssignLeadsToRepsReq';
import FiberHouseApi from '../../../../API/FiberHouseApi';
import { CircularProgress, Pagination, Rating } from '@mui/material';
// import AssignLeadsToReps from './AssignLeadsToReps';
// import AssignLeadsToOrg from './AssignLeadsToOrg';
import { UserContext } from '../../../../App';
import MapIconSelector from "./../../../../components/MapIconSelector"
import MapIconApi from '../../../../API/MapIconApi';
// import BulkEditFiberHouseReq from '../../../../Requests/FiberHouse/BulkEditFiberHouseReq';
import SearchFiberHousesReq from '../../../../Requests/FiberHouse/SearchFiberHousesReq';
import MapHelper from '../MapHelper';
import { RiTeamFill } from 'react-icons/ri';
import anonUser from '../../../../assets/images/userGrey.png';
import { TfiWrite } from "react-icons/tfi";
import { RiFileVideoLine } from "react-icons/ri";
import { SiPagespeedinsights } from "react-icons/si";
import Modal from '@mui/material/Modal';
import AssignLeadsToOrgreq from '../../../../Requests/FiberHouse/AssignLeadsToOrgreq';
import ReviewContainer from '../../../Reps/DigitalBusinessCenter/components/ReviewContainer';
import { MdExpandLess, MdExpandMore, MdOutlineDirections, MdOutlineMail, MdOutlineMessage, MdOutlinePhone } from 'react-icons/md';
import { FaPhoneSlash, FaWhatsapp } from 'react-icons/fa';
import { IoCloseCircle, IoMapOutline } from 'react-icons/io5';
import ContactButtons from '../../../../Services/ContactButtons';
import { TbStackPop } from 'react-icons/tb';
import EditFiberHouseReq from '../../../../Requests/FiberHouse/EditFiberHouseReq';
import { FaCopy } from 'react-icons/fa6';
import FiberCompanySelector from './../../../../components/FiberCompanySelector';
import BulkChangeReq from '../../../../Requests/BulkChangeReq';


const Stats = ({ fiberHouses }) => {

    let map = React.useContext(MP);
    const user = useContext(UserContext)
    const [fiberHousesState, setFiberHousesState] = useState([])
    let mapObject = map.mapObject;
    const [filterMapIconIds, setFilterMapIconIds] = useState([]);
    const [selectedLeadIds, setSelectedLeadIds] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [initialSalesOrg, setInitialSalesOrg] = useState([])
    // const [initialSharedLeads, setInitialSharedLeads] = useState([])
    const [initialSalesRep, setInitialSalesRep] = useState([])
    const [addMode, setAddMode] = useState(false)
    const [selectedMapIcon, setSelectedMapIcon] = useState(null)
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selectedLeadIds
        ?.slice(indexOfFirstItem, indexOfLastItem);
    const filteredSalesOrg = useRef([])
    const filteredSalesRep = useRef([])
    // const [updatedFiberHouses, setUpdatedFiberHouses] = useState([])
    const [loading, setLoading] = useState(true)
    // const initialRender = useRef(true)
    const [openReviewModel, setOpenReviewModel] = useState(false)
    const [reviewType, setReviewType] = useState(null)
    const [openSalesRepView, setOpenSalesRepView] = useState(null)
    const helper =  useRef(new MapHelper(mapObject))
    const contactButtons = new ContactButtons(user)
    const [fiberCompany, setFiberCompany] = useState(null)



    useEffect(
        () => {
            searchFiberHousesPrev();
            map.setUsingLight(false);
        }, [map.fiberHouseIds]
    )

    useEffect(
        () => {
            return () => {
                setFiberHousesState([])
                setInitialSalesOrg([])
                setInitialSalesRep([])
            }
        }, []
    )

    const searchFiberHousesPrev = async (pageSize = 500, pageNumber = 1) => {
        setLoading(true)
        map.setFiberHousesLoading(true)
        const req = new SearchFiberHousesReq()
        req.pagingParams.pageNumber = pageNumber
        req.pagingParams.pageSize = pageSize

        let orgId = null;
        if (user?.userType === "SuperAdmin" || user?.userType === "Admin") {
            orgId = null
        }
        else {
            orgId = user.salesOrgId
        }
        req.salesOrgId = orgId

        let repId = null;
        if (user?.userType === "SalesRep") {
            repId = user.id
        }
        // else {
        //     repId = salesRepId
        // }
        req.salesRepId = repId

        // req.doorKnockingStatus = doorKnockingStatus
        // req.leadStatusId = leadStatus
        // req.notes = notes
        // req.mapIconId = assignedMapIcons?.id
        // req.mapString = mapString
        // req.assignedConvertedToCustomer = assignedConvertedToCustomer
        // req.customerInterestFrom = customerInterestFrom
        // req.customerInterestTo = customerInterestTo
        // req.assignedAcountStatus = assignedAcountStatus
        // if (map.searchArea) {
        // req.area = map.searchArea
        // }
        // if (map?.fiberHouseIds?.length > 0) {
        req.ids = map.fiberHouseIds
        // }
        // if(assignedCreatedFrom!=='')
        // req.assignedCreatedFrom = assignedCreatedFrom
        // if(assignedCreatedTo!=='')
        // req.assignedCreatedTo = assignedCreatedTo
        // req.mapIconIds = assignedMapIcons
        req.assignedEnabled = map.assignedLeadsEnabled;
        req.assignedConvertedToCustomer = map.assignedLeadsEnabled ? map.assignedConvertedToCustomer : null;
        // req.assignedHasReview = assignedLeadsEnabled?assignedAcountStatus:null;
        if (map.assignedAcountStatus === "CancelsPending") {
            req.assignedIssueType = 'Cancel'
        }
        else {
            req.assignedAccountStatus = map.assignedAcountStatus !== "" ? map.assignedAcountStatus : null;
        }
        req.assignedCreatedFrom = map.assignedCreatedFrom !== "" ? map.assignedCreatedFrom : null;
        req.assignedCreatedTo = map.assignedCreatedTo !== "" ? map.assignedCreatedTo : null;
        req.assignedSalesOrgId = map.assignedSalesOrgId
        req.assignedHasWrittenReivew = map.assignedReviewType !== null ? map.assignedReviewType === "Written" ? true : false : null;
        req.assignedHasVideoReivew = map.assignedReviewType !== null ? map.assignedReviewType === "Video" ? true : false : null;
        req.assignedHasSpeedTestReview = map.assignedReviewType !== null ? map.assignedReviewType === "SpeedTest" ? true : false : null;
        req.assignedLeadUpdatedFrom = map.assignedUpdatedFrom !== "" && map.assignedUpdatedFrom !== null ? new Date(map.assignedUpdatedFrom) : null;
        req.assignedLeadUpdatedTo = map.assignedUpdatedTo !== "" && map.assignedUpdatedTo !== null ? new Date(map.assignedUpdatedTo) : null;
        // if (fiberHouses.length > 0 || map.assignedSalesOrgs.length > 0)
        req.assignedSalesOrgIds = map.assignedSalesOrgs.length === map.salesOrg.length ? null : map.assignedSalesOrgs;
        // if (map.assignedMapIcons?.length > 0 || fiberHouses.length > 0)
        req.assignedMapIconIds = map.assignedMapIcons.length === map.mapIcons.length ? null : map.assignedMapIcons
        req.assignedSalesRepIds = map.assignedSalesRepIds === map.salesReps.length ? null : map.assignedSalesRepIds
        req.unassignedEnabled = map.unassignedLeadsEnabled;
        req.unassignedConvertedToCustomer = map.unassignedLeadsEnabled ? map.unassignedConvertedToCustomer : null;
        // req.unassignedHasReview = unassignedLeadsEnabled?unassignedAcountStatus:null;
        req.unassignedCreatedFrom = map.unassignedLeadsEnabled !== "" ? map.unassignedCreatedFrom : null;
        req.unassignedCreatedTo = map.unassignedLeadsEnabled !== "" ? map.unassignedCreatedTo : null;
        req.unassignedSalesOrgId = map.unassignedSalesOrgId
        req.unassignedHasWrittenReivew = map.unassignedReviewType !== null ? map.unassignedReviewType === "Written" ? true : false : null;
        req.unassignedHasVideoReivew = map.unassignedReviewType !== null ? map.unassignedReviewType === "Video" ? true : false : null;
        req.unassignedHasSpeedTestReview = map.unassignedReviewType !== null ? map.unassignedReviewType === "SpeedTest" ? true : false : null;
        req.unassignedLeadUpdatedFrom = map.unassignedUpdatedFrom !== "" && map.unassignedUpdatedFrom !== null ? new Date(map.unassignedUpdatedFrom) : null;
        req.unassignedLeadUpdatedTo = map.unassignedUpdatedTo !== "" && map.unassignedUpdatedTo !== null ? new Date(map.unassignedUpdatedTo) : null;

        if (map.unassignedAcountStatus === "CancelsPending") {
            req.unassignedIssueType = 'Cancel'
        }
        else {
            req.unassignedAccountStatus = map.unassignedAcountStatus !== "" ? map.unassignedAcountStatus : null;
        }
        // if (fiberHouses.length > 0 || map.unassignedSalesOrgs.length > 0)
        req.unassignedSalesOrgIds = map.unassignedSalesOrgs.length === map.salesOrg.length ? null : map.unassignedSalesOrgs;
        // if (fiberHouses.length > 0 || map.unassignedMapIcons.length > 0)
        req.unassignedMapIconIds = map.unassignedMapIcons.length === map.mapIcons.length ? null : map.unassignedMapIcons
        console.log(req)
        let res = await FiberHouseApi.SearchFiberHousesOptimized(req) // SEARCHING x4 TIMES???
        if (res?.status?.success) {
            // console.log(res.data)
            // console.table(res.data)
            // map.setFiberHouses(res.data)
            console.log(res.data)
            //    map.setSearchAreaFiberHouses(res.data)
            setFiberHousesState(res.data)
            setLoading(false)
            map.setFiberHousesLoading(false)

            delete res.data
        }

    }






    // async function updateCoordinates(fhs) {

    //     const result = await Swal.fire({
    //         title: 'Update Coordinates',
    //         text: 'Are you sure you want to update the coordinates of the fiber houses?',
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Yes',
    //         cancelButtonText: 'No',
    //         confirmButtonColor: 'green',
    //         cancelButtonColor: 'red',
    //     });

    //     if (result.isConfirmed) {
    //         let coordinatesFailed = 0
    //         const list = []
    //         Swal.fire({
    //             icon: "info",
    //             title: "Please Wait",
    //             text: "We are updating the coordinates",
    //             allowOutsideClick: false,
    //             allowEscapeKey: false,
    //             allowEnterKey: false,
    //             showConfirmButton: false,
    //             showCancelButton: false,
    //             willOpen: () => {
    //                 Swal.showLoading();
    //             }
    //         })

    //         for (let i = 0; i < fhs.length; i++) {
    //             const mapstring = fhs[i].mapstring
    //             const coordinates = await helper.searchLocation(mapstring);
    //             if (coordinates) {
    //                 list.push(
    //                     {
    //                         id: fhs[i].id,
    //                         coordinates: {
    //                             latitude: coordinates[1],
    //                             longitude: coordinates[0]
    //                         }
    //                     }
    //                 )
    //             }
    //             else {
    //                 coordinatesFailed++
    //             }
    //         }
    //         // console.log(list)
    //         // console.log(fiberHousesState)


    //         let uF = 0
    //         map.setFiberHouses(
    //             (prev) => {
    //                 prev.map(
    //                     (fh, index) => {
    //                         const updatedFH = list.find((f) => f.id === fh.id)
    //                         if (updatedFH && (fh.coordinates.latitude !== updatedFH.coordinates.latitude || fh.coordinates.longitude !== updatedFH.coordinates.longitude)) {
    //                             fh.coordinates = updatedFH.coordinates
    //                             uF++
    //                         }
    //                         return fh
    //                     }
    //                 )

    //                 return prev
    //             }
    //         )
    //         setUpdatedFiberHouses(list)
    //         Swal.fire({
    //             icon: "success",
    //             title: `Coordinates of ${uF}  Updated and ${fiberHousesState.length - uF} were not updated and Coordinates of ${coordinatesFailed} Fiber Houses could not be found`,
    //             allowOutsideClick: false,
    //             allowEscapeKey: false,
    //             allowEnterKey: false,
    //             showConfirmButton: true,
    //             showCancelButton: false,
    //             didOpen: () => {
    //                 Swal.hideLoading()
    //             }
    //         })





    //     }

    //     // const req;

    // }

    // const update = async () => {
    //     Swal.fire({
    //         icon: "info",
    //         title: "Please Wait",
    //         text: "We are updating the coordinates",
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //         allowEnterKey: false,
    //         showConfirmButton: false,
    //         showCancelButton: false,
    //         willOpen: () => {
    //             Swal.showLoading();
    //         }
    //     })
    //     let req = new BulkEditFiberHouseReq()
    //     req.houseCoordinates = updatedFiberHouses.map(
    //         (fh, index) => {
    //             return {
    //                 fiberHouseId: fh.id,
    //                 latitude: fh.coordinates.latitude,
    //                 longitude: fh.coordinates.longitude
    //             }
    //         }
    //     )
    //     const res = await FiberHouseApi.BulkUpdateFiberHouseCoordinates(req)
    //     if (res?.status?.success) {
    //         Swal.fire({
    //             icon: "success",
    //             title: "Coordinates Updated Successfully",
    //             allowOutsideClick: false,
    //             allowEscapeKey: false,
    //             allowEnterKey: false,
    //             showConfirmButton: true,
    //             showCancelButton: false,
    //             didOpen: () => {
    //                 Swal.hideLoading()
    //             }
    //         })
    //     }
    // }

    const getMapIconStats = () => {
        let mapIconStats = [];
        fiberHousesState?.forEach((fiberHouse, index) => {
            let assignedMapIcons = map.mapIcons.find((icon) => icon.id === fiberHouse.mapIconId);
            const mapIconIndex = mapIconStats.findIndex((stat) => stat.assignedMapIcons.id === assignedMapIcons.id);
            if (mapIconIndex === -1) {
                mapIconStats.push({ assignedMapIcons: assignedMapIcons, count: 0 });
            }
        });

        selectedLeadIds?.forEach((fh) => {
            let assignedMapIcons = map.mapIcons.find((icon) => icon.id === fh.mapIconId);
            const mapIconIndex = mapIconStats.findIndex((stat) => stat.assignedMapIcons.id === assignedMapIcons.id);
            if (mapIconIndex !== -1) {
                mapIconStats[mapIconIndex].count++;
            }
        });

        return mapIconStats;
    };



    // useEffect(() => {
    //     return () => {
    //         if (map.searchAreaFeature.length > 0) {
    //             map.searchAreaFeature.forEach(feature => {
    //                 mapObject.removeLayer(feature)
    //             })
    //         }
    //     }
    // }, [])




    useEffect(
        () => {

            if (loading) {
                return
            }

            setSelectedLeadIds(fiberHousesState);
            setInitialSalesOrg(helper.current.getUniqueSalesOrgsFromFiberHouses(fiberHousesState));
            setInitialSalesRep(helper.current.getUniqueSalesRepsFromFiberHouses(fiberHousesState));
            // setInitialSharedLeads(helper.current.getCountOfEachSalesRepFiberHouses(fiberHousesState));

            if (filterMapIconIds.length > 0)
                setFilterMapIconIds([])
        }, [fiberHousesState]
    )

    // useEffect(
    //     () => {
    //         console.log(initialSharedLeads)
    //     }, [initialSharedLeads]
    // )

    useEffect(
        () => {
            if (selectedLeadIds)
                setTotalPages(Math.ceil(selectedLeadIds.length / itemsPerPage))
            if (map.searchAreaFeature.length > 0) {
                // Get the layer by its name
                const idsToRemove = fiberHousesState.filter((fiberHouse) => {
                    return !selectedLeadIds.some((fh) => fh.id === fiberHouse.id)
                }).map((fiberHouse) => fiberHouse.id)
                map.setFiberHouses(
                    fiberHouses.map(
                        (fiberHouse) => {
                            if (idsToRemove.includes(fiberHouse.id))
                                return {
                                    ...fiberHouse,
                                    remove: true,
                                }
                            else {
                                const fh = selectedLeadIds.filter((fh) => fh.id === fiberHouse.id)[0]
                                return {
                                    ...fiberHouse,
                                    remove: false,
                                    // assigned: fh ? fh.owners.length > 0 ? true : false : null,
                                    selected: fh ? true : false
                                }
                            }
                        }
                    )
                )

                console.log(initialSalesRep)



                // if(idsToRemove.length>0){
                //     map.cluster.current.getSource().getFeatures().forEach((feature) => {
                //         const f = feature.get('features')
                //         f.map((fiberHouse) => {
                //             if (idsToRemove.includes(fiberHouse.get('id'))) {
                //            //     map.cluster.current.getSource().removeFeature(fiberHouse)
                //            fiberHouse.set('remove', true)  
                //            //mapObject.render()

                //             }
                //             else{
                //                 fiberHouse.set('remove', false)
                //             }
                //         })
                //     })
                //     map.cluster.current.getSource().refresh()
                // }


                // mapObject.getLayers().forEach((layer) => {
                //     if (layer.get('name') === 'fiberHouses') {
                //         layer.getSource().getFeatures().forEach((feature) => {
                //             if (idsToRemove.includes(feature.get('id'))) {
                //                 layer.getSource().removeFeature(feature)
                //             }
                //         })
                //         layer.getSource().refresh()
                //     }
                // })



                // mapObject.getView().setZoom(
                //     mapObject.getView().getZoom() +0.01
                // )
            }
        }, [selectedLeadIds]
    )


    // filtering map icons
    useEffect(
        () => {
            setSelectedLeadIds(
                fiberHousesState.filter((fiberHouse) => {
                    return !filterMapIconIds?.includes(fiberHouse.mapIconId) &&
                        !filteredSalesOrg?.current?.includes(fiberHouse.salesOrgId) &&
                        !fiberHouse?.owners?.some(owner => filteredSalesRep.current.includes(owner.userId));
                })
            )

        }, [filterMapIconIds]
    )




    const assignLeadsToReps = async (salesRep) => {
        const option = await Swal.fire({
            title: 'Assign Leads',
            text: 'Are you sure you want to assign the selected leads to ' + salesRep.firstName + ' ' + salesRep.lastName + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: 'green',
            cancelButtonColor: 'red',
            customClass: {
                container: 'custom-swal'
            }
        });

        if (!option.isConfirmed) {
            return
        }

        const filteredFiberHouses = selectedLeadIds.filter((fiberHouse) => {
            return fiberHouse.salesOrgId === salesRep.salesOrgId && !fiberHouse.owners.some(owner => owner.userId === salesRep.id)
        })

        const req = new AssignLeadsToRepsReq()
        req.fiberHouseIds = filteredFiberHouses.map((fiberHouse) => fiberHouse.id)
        req.repId = salesRep.id
        setSelectedLeadIds(
            (prev) => {
                return prev.map(
                    (fh) => {
                        if (fh.salesOrgId === salesRep.salesOrgId && !fh.owners.some(owner => owner.userId === salesRep.id)) {
                            return {
                                ...fh,
                                owners: [
                                    ...fh.owners,
                                    {
                                        userId: salesRep.id,
                                        salesRepFiberHouseId: null,
                                        name: salesRep.name,
                                        profileImage: salesRep.profileImage?.url
                                    }
                                ]
                            }
                        }
                        return fh
                    }
                )
            }
        )
        setInitialSalesRep(
            (prev) => {
                return prev.map(
                    (owner) => {
                        if (owner.userId === salesRep.id) {
                            return {
                                ...owner,
                                count: owner.count + filteredFiberHouses.length
                            }
                        }
                        return owner
                    }
                )
            }
        )
        const res = await FiberHouseApi.AssignRepsToLeads(req)
        if (res?.status?.success) {
            console.log(res)
        }
        else {

        }
    }




    const removeRepFromLeads = async (salesrep, idsToRemove) => {
        console.log(salesrep)
        const option = await Swal.fire({
            title: 'Remove Sales Rep',
            text: 'Are you sure you want to remove leads from ' + salesrep.firstName + " " + salesrep.lastName + "?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: 'red',
            cancelButtonColor: 'green',
            customClass: {
                container: 'custom-swal'
            }
        });

        if (!option.isConfirmed) {
            return
        }

        const req = new AssignLeadsToRepsReq()
        let ids = []

        if (idsToRemove) {
            ids = idsToRemove
        }
        else {

            selectedLeadIds.filter(f => f.owners.length === 1).forEach((fiberHouse) => {
                fiberHouse.owners.forEach((owner) => {
                    if (owner.userId === salesrep.id) {
                        ids.push(owner.salesRepFiberHouseId)
                    }
                })
            })
        }
        req.salesRepFiberHouseIds = ids
        req.repId = salesrep.id
        if(idsToRemove){
            setSelectedLeadIds(
                (prev) => {
                    return prev.map(
                        (fh) => {
                            return {
                                ...fh,
                                owners: fh.owners.filter((owner) => owner.userId !== salesrep.id && idsToRemove.includes(owner.salesRepFiberHouseId))
                            }
                        }
                    )
                }
            )     
        }
    else{

        setSelectedLeadIds(
            (prev) => {
                return prev.map(
                    (fh) => {
                        return {
                            ...fh,
                            owners: fh.owners.length === 1? fh.owners.filter((owner) => owner.userId !== salesrep.id): fh.owners
                        }
                    }
                )
            }
        )

    }


        const res = await FiberHouseApi.RemoveRepsFromLeads(req)
        if (res?.status?.success) {

            // setInitialSalesRep(
            //     (prev) => {
            //         return prev.filter((owner) => owner.userId !== salesrep.id)
            //     }
            // )

        }
        else {

        }

    }



    const getAddress = (fiberHouse) => {
        if (fiberHouse.mapstring) {
            return fiberHouse.mapstring
        }
        else {
            let address = "";
            if (fiberHouse.address) {
                address += fiberHouse.address + " "
            }
            if (fiberHouse.city) {
                address += fiberHouse.city + ", "
            }
            if (fiberHouse.state) {
                address += fiberHouse.state + " "
            }
            if (fiberHouse.zipCode) {
                address += fiberHouse.zipCode
            }
            return address
        }
    }

    const getCustomerName = (fiberHouse) => {
        console.log(fiberHouse)
        let customerName = "";
        if (fiberHouse?.customer?.firstName) {
            customerName += fiberHouse.customer.firstName + " ";
        }
        if (fiberHouse?.customer?.lastName) {
            customerName += fiberHouse.customer.lastName + " ";
        }
        return customerName;
    };

    const getCustomerPhoneNumber = (fiberHouse) => {
        let customerPhone = "";
        if (fiberHouse?.customer?.cellPhone) {
            customerPhone += fiberHouse.customer.cellPhone;
        }
        return customerPhone;
    };

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSelectedLeadIds(
            fiberHousesState.filter((fiberHouse) => {
                const address = getAddress(fiberHouse).toLowerCase();
                const customerName = getCustomerName(fiberHouse).toLowerCase();
                const customerPhone = getCustomerPhoneNumber(fiberHouse).toLowerCase();
                return address.includes(searchTerm) || customerName.includes(searchTerm) || customerPhone.includes(searchTerm);
            })
        );
    };

    const snapToLocation = (fiberHouse) => {
        map.fiberHouseClicked(fiberHouse);
        let lonlat = [fiberHouse.coordinates.longitude, fiberHouse.coordinates.latitude]
        map.mapObject.getView().setCenter(fromLonLat(lonlat));
        map.mapObject.getView().setZoom(21);

    }
    const goToGoogleMaps = (fiberHouse) => {
        let lonlat = [fiberHouse.coordinates.longitude, fiberHouse.coordinates.latitude]
        let url = "https://www.google.com/maps/search/?api=1&query=" + lonlat[1] + "," + lonlat[0]
        window.open(url, "_blank")
    }

    function filterSalesOrg(salesOrgId) {
        if (filteredSalesOrg.current.length > 0 && filteredSalesOrg.current.includes(salesOrgId)) {
            filteredSalesOrg.current = filteredSalesOrg.current.filter((id) => id !== salesOrgId)
        }
        else {
            filteredSalesOrg.current.push(salesOrgId)
        }
        setSelectedLeadIds(
            fiberHousesState.filter((fiberHouse) => {
                return !filterMapIconIds.includes(fiberHouse.mapIconId) &&
                    !filteredSalesOrg.current.includes(fiberHouse.salesOrgId) &&
                    !fiberHouse.owners.some(owner => filteredSalesRep.current.includes(owner.userId));
            })
        )
    }

    const filterSalesRep = (salesRepId) => {
        if (filteredSalesRep.current.length > 0 && filteredSalesRep.current.includes(salesRepId)) {
            filteredSalesRep.current = filteredSalesRep.current.filter((id) => id !== salesRepId)
        }
        else {
            filteredSalesRep.current.push(salesRepId)
        }
        setSelectedLeadIds(
            fiberHousesState.filter((fiberHouse) => {
                if (fiberHouse.owners.length > 1) {
                    return true
                }
                return !filterMapIconIds.includes(fiberHouse.mapIconId) &&
                    !filteredSalesOrg.current.includes(fiberHouse.salesOrgId) &&
                    !(fiberHouse.owners.some(owner => filteredSalesRep.current.includes(owner.userId)));
            })
        )
    }

    async function updateIcons() {
        if (selectedMapIcon) {

            const option = await Swal.fire({
                title: 'Update Map Icons',
                text: 'Are you sure you want to update the map icons of the selected fiber houses?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                confirmButtonColor: 'red',
                cancelButtonColor: 'green',
            });

            if (!option.isConfirmed) {
                return
            }


            Swal.fire({
                icon: "info",
                title: "Please Wait",
                text: "We are updating the map icons",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: false,
                willOpen: () => {
                    Swal.showLoading();
                }
            })
            const req = {
                ids: selectedLeadIds.map((fiberHouse) => fiberHouse.id),
                id: selectedMapIcon.id
            }
            MapIconApi.BulkChangeFiberHouseMapIcons(req).then((res) => {
                if (res?.status?.success) {
                    Swal.fire({
                        icon: "success",
                        title: "Map Icons Updated Successfully",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        showCancelButton: false,
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    })
                    searchFiberHousesPrev()
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: res?.status?.message,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        showCancelButton: false,
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    })
                }
            })
        }
    }

    const removeLeadsFromOrg = async (salesOrg) => {

        const option = await Swal.fire({
            title: 'Remove Leads',
            text: 'Are you sure you want to remove the selected leads from ' + salesOrg.name + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: 'red',
            cancelButtonColor: 'green',
            customClass: {
                container: 'custom-swal'
            }
        });

        if (!option.isConfirmed) {
            return
        }

        const req = new AssignLeadsToOrgreq()
        req.fiberHouseIds = selectedLeadIds.filter(fh => fh.salesOrgId === salesOrg.id).map((fiberHouse) => fiberHouse.id)
        req.salesOrgId = salesOrg.id

        setSelectedLeadIds(
            (prev) => {
                return prev.map(
                    (fh) => {
                        if (fh.salesOrgId === salesOrg.id) {
                            return {
                                ...fh,
                                salesOrgId: null
                            }
                        }
                        return fh
                    }
                )
            }
        )

        // setInitialSalesOrg(
        //     (prev) => {
        //         return prev.filter((s) => s.id !== salesOrg.id)
        //     }
        // )
        const res = await FiberHouseApi.RemoveFiberHousesFromSalesOrg(req)
        if (res?.status?.success) {
            console.log(res)
        }
        else {
        }
    }


    const assignLeadsToOrg = async (salesOrg) => {

        const option = await Swal.fire({
            title: 'Assign Leads',
            text: 'Are you sure you want to assign the selected leads to ' + salesOrg.name + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: 'green',
            cancelButtonColor: 'red',
            customClass: {
                container: 'custom-swal'
            }
        });

        if (!option.isConfirmed) {
            return
        }

        const req = new AssignLeadsToOrgreq()
        req.fiberHouseIds = selectedLeadIds.filter(fh => fh.salesOrgId !== salesOrg.id).map((fiberHouse) => fiberHouse.id)
        req.salesOrgId = salesOrg.id
        setSelectedLeadIds(
            (prev) => {
                return prev.map(
                    (fh) => {
                        return {
                            ...fh,
                            salesOrgName: salesOrg.name,
                            salesOrgId: salesOrg.id
                        }
                    }
                )
            }
        )
        setInitialSalesOrg(
            (prev) => {
                if (prev.some((s) => s.id === salesOrg.id)) {
                    return prev
                }
                return [...prev, salesOrg]
            }
        )
        // setInitialSalesOrg([salesOrg])
        const res = await FiberHouseApi.AddFiberHouseToSalesOrg(req)
        if (res?.status?.success) {

        }
        else {

        }
    }

    // const handleReset = () => {
    //     if (map.searchAreaFeature.length > 0) {
    //         deletePolygon()
    //     }
    //     if (map.fiberHouseIds.length > 0) {
    //         map.setFiberHouseIds([])
    //         map.setFiberHouses(map.lightHouses.current)
    //     }
    //     map.setIndex(-1)
    // }


    const getCustomerLine = (fh) => {
        let str = "";
        if (fh.customer != null) {
            if (fh.customer.orderNumber != null) {
                str += "✔ Verified Customer";
            }
            else {
                str += "✔ Customer";
            }
        }

        return str;
    }

    const copyAddress = (fiberHouse) => {
        if (fiberHouse.address) {
            navigator.clipboard.writeText(fiberHouse.address)
            Swal.fire({
                icon: 'success',
                title: 'Copied',
                text: 'Address Copied to Clipboard',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,
                toast: true
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Failed to Copy',
                text: 'Address Not Found',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,
                toast: true
            })
        }
    }


    const sendEmail = (fiberHouse) => {
        if (fiberHouse.email) {
            contactButtons.email(fiberHouse.email)
        }
        else if (fiberHouse.customer?.email) {
            contactButtons.email(fiberHouse.customer.email)
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'No Email Found',
                text: 'No email found for this fiber house',
            })
        }
    }

    const sendText = (fiberHouse) => {
        if (fiberHouse.phone) {
            contactButtons.sms(fiberHouse.phone)
        }
        else if (fiberHouse.customer?.cellPhone) {
            contactButtons.sms(fiberHouse.customer.cellPhone)
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'No Phone Number Found',
                text: 'No phone number found for this fiber house',
            })
        }
    }

    const call = (fiberHouse) => {
        if (fiberHouse.phone) {
            contactButtons.call(fiberHouse.phone)
        }
        else if (fiberHouse.customer?.cellPhone) {
            contactButtons.call(fiberHouse.customer.cellPhone)
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'No Phone Number Found',
                text: 'No phone number found for this fiber house',
            })
        }
    }

    const whatsapp = (fiberHouse) => {
        if (fiberHouse.phone) {
            contactButtons.whatsapp(fiberHouse.phone)
        }
        else if (fiberHouse.customer?.cellPhone) {
            contactButtons.whatsapp(fiberHouse.customer.cellPhone)
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'No Phone Number Found',
                text: 'No phone number found for this fiber house',
            })
        }
    }

    const direction = (fiberHouse) => {
        if (fiberHouse.coordinates) {
            contactButtons.directions(fiberHouse.coordinates)
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'No Coordinates Found',
                text: 'No coordinates found for this fiber house',
            })
        }
    }


    const handleLeadPop = (fiberHouse) => {
        mapObject.getLayers().forEach((layer) => {
            if (layer.get('name') === 'fiberHouses') {
                layer.getSource().getFeatures().forEach((feature) => {
                    if (feature.get('features')?.length > 0) {
                        feature.get('features').forEach((f) => {
                            if (f.get('id') === fiberHouse.id) {
                                f.getGeometry().translate(5, 5)
                                // map.setSelectedFiberHouseFeature(f)
                                mapObject.getView().setCenter(f.getGeometry().getCoordinates())
                                mapObject.getView().setZoom(mapObject.getView().getZoom() + 0.1)
                                setSelectedLeadIds(
                                    (prev) => {
                                        return prev.filter((fh) => fh.id !== fiberHouse.id)
                                    }
                                )

                                let req = new EditFiberHouseReq()
                                req.id = fiberHouse.id
                                let coordinate = toLonLat(f.getGeometry().getCoordinates())
                                req.coordinate = {
                                    latitude: coordinate[1],
                                    longitude: coordinate[0]
                                }
                                FiberHouseApi.EditFiberHouse(req).then((res) => {
                                    if (res?.status?.success) {
                                        console.log(res)
                                    }
                                    else {
                                        console.log(res)
                                    }
                                })
                                console.log(f)
                            }
                        })
                    }
                })
            }
        })

    }


    const assignLeadToFiberCompany = async () => {
        const option = await Swal.fire({
            title: 'Assign Leads',
            text: 'Are you sure you want to assign the selected leads to the selected fiberCompany ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: 'green',
            cancelButtonColor: 'red',
            customClass: {
                container: 'custom-swal'
            }
        });

        if (!option.isConfirmed) {
            return
        }
        const req = new BulkChangeReq()

        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "We are assigning the leads to the selected fiber company",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        })



        req.ids = selectedLeadIds.map((fiberHouse) => fiberHouse.leadId)
        req.id = fiberCompany
        setSelectedLeadIds(
            (prev) => {
                return prev.map(
                    (fh) => {
                        return {
                            ...fh,
                            lead: {
                                ...fh.lead,
                                fiberCompanyId: fiberCompany
                            }
                        }
                    }
                )
            }
        )
        const res = await FiberHouseApi.AddLeadsToCompany(req)
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Leads Assigned Successfully",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                showCancelButton: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
        else {
            console.log(res)
        }
    }



    function showSharedLeads() {
        let sharedLeads = {}

        selectedLeadIds.filter(fh => fh.owners.length > 1).forEach((fiberHouse) => {
            let ownerIds = fiberHouse.owners.map((owner) => owner.userId).sort()
            if (sharedLeads[ownerIds]) {
                sharedLeads[ownerIds].totalCount++
                fiberHouse.owners.forEach((owner) => {
                    if (sharedLeads[ownerIds][owner.userId]) {
                        sharedLeads[ownerIds][owner.userId].push(owner.salesRepFiberHouseId)
                    }
                    else {
                        sharedLeads[ownerIds][owner.userId] = [owner.salesRepFiberHouseId]
                    }
                })
            }
            else {
                sharedLeads[ownerIds] = {
                    totalCount: 1
                }
                fiberHouse.owners.forEach((o) => {
                    sharedLeads[ownerIds][o.userId] = [o.salesRepFiberHouseId]
                })
            }
        })

        return Object.keys(sharedLeads).map((owner) => {
            console.log(owner)
            return (
                <div className='sales_rep_wrapper'>
                    <div className={`lead_owner`}>
                        <div className='flex'>
                            {
                                owner.split(',').map((o) => {

                                    return initialSalesRep.find((s) => s.userId === parseInt(o))?.profileImage ?
                                        <img style={{ objectFit: "cover", marginLeft: "-10px" }} src={initialSalesRep.find((s) => s.userId === parseInt(o))?.profileImage} alt="Map Icon" />
                                        :
                                        <img src={anonUser} alt="Map Icon" />

                                })
                            }
                        </div>
                        {
                            owner.split(',').map((o) => {

                                return (
                                    <p>
                                        {
                                            initialSalesRep.find((s) => s.userId === parseInt(o))?.name
                                        }
                                    </p>
                                )
                            })
                        }
                        <p style={{
                            fontWeight: "bold"
                        }}>{
                                sharedLeads[owner].totalCount
                            }</p>
                    </div>
                    {
                        (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin") &&
                        owner.split(',').map((o) => {

                            return (
                                <button onClick={() => {
                                    removeRepFromLeads({
                                        id: parseInt(o),
                                        firstName: initialSalesRep.find((s) => s.userId === parseInt(o))?.name.split(' ')[0],
                                        lastName: initialSalesRep.find((s) => s.userId === parseInt(o))?.name.split(' ')[1]
                                    }, sharedLeads[owner][o])
                                }} className='remove_rep'>Remove {initialSalesRep.find(s => s.userId === parseInt(o)).name}</button>
                            )
                        })
                    }
                </div>
            )
        })

    }



    return (
        // !addMode ?
        loading ?
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%"
            }}>
                <CircularProgress sx={{
                    color: "blue"
                }} />
            </div>
            :
            <>
                <Modal open={addMode} onClose={() => {
                    // setReload(!reload)
                    setAddMode(false)
                }}>
                    <div className='assign_leads_container'>
                        <IoCloseCircle onClick={() => {
                            setAddMode(false)
                        }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                        <h1>Change Ownership</h1>
                        <h2 style={{
                            color: "black",
                            fontSize: "1.2rem",
                            textAlign: "center"
                        }}>Leads Selected: {selectedLeadIds.length}</h2>
                        <div className='assign_leads_content'>
                            <h3 style={{
                                color: "black",
                                fontSize: "1.2rem",
                                textAlign: "center"

                            }}>{user.userType === "SuperAdmin" ? "Sales Orgs" : "Sales Reps"}</h3>
                            <div className='sales_orgs'>
                                {
                                    user.userType === "SuperAdmin" ?
                                        map.salesOrg.map((salesOrg, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div >
                                                        <div className={`sales_org ${initialSalesOrg.find((s) => salesOrg.id === s.id && !filteredSalesOrg.current.find(sf => sf === s.id)) ? 'active' : ''}`}>
                                                            <div className='image'>
                                                                {
                                                                    salesOrg.icon ?
                                                                        <img src={"data:image/png;base64," + salesOrg.icon} alt="Map Icon" />
                                                                        :
                                                                        <RiTeamFill size={30} color='black' />
                                                                }
                                                            </div>
                                                            <p>{salesOrg.name}

                                                                {
                                                                    openSalesRepView === salesOrg.id ?
                                                                        <MdExpandLess onClick={() => {
                                                                            setOpenSalesRepView(null)
                                                                        }} size={30} style={{
                                                                            cursor: "pointer"
                                                                        }} />
                                                                        :
                                                                        <MdExpandMore onClick={() => {
                                                                            setOpenSalesRepView(salesOrg.id)
                                                                        }} size={30} style={{
                                                                            cursor: "pointer"
                                                                        }} />
                                                                }
                                                            </p>
                                                            {/* <div className='input'>
                                                            <div onClick={() => {
                                                                handleSalesOrgClicked(salesOrg, initialSalesOrg.find((s) => salesOrg.id === s.id && !filteredSalesOrg.current.find(sf => sf === s.id)) ? "remove" : "assign")
                                                            }} type="checkbox" className={`${initialSalesOrg.find((s) => salesOrg.id === s.id && !filteredSalesOrg.current.find(sf => sf === s.id)) ? 'active' : ''}`} />
                                                        </div> */}
                                                            <div className='input'>
                                                                <button onClick={() => { assignLeadsToOrg(salesOrg) }}
                                                                    disabled={selectedLeadIds.filter((fiberHouse) => fiberHouse.salesOrgId !== salesOrg.id).length === 0}
                                                                    style={{ color: selectedLeadIds.filter((fiberHouse) => fiberHouse.salesOrgId !== salesOrg.id).length === 0 ? "#ccc" : '' }} className='assign'>Assign {selectedLeadIds.filter((fiberHouse) => fiberHouse.salesOrgId !== salesOrg.id).length} Leads</button>
                                                                <button onClick={() => { removeLeadsFromOrg(salesOrg) }}
                                                                    style={{ color: selectedLeadIds.filter((fiberHouse) => fiberHouse.salesOrgId === salesOrg.id).length === 0 ? "#ccc" : '' }}
                                                                    disabled={selectedLeadIds.filter((fiberHouse) => fiberHouse.salesOrgId === salesOrg.id).length === 0}
                                                                    className='remove'>Remove {selectedLeadIds.filter((fiberHouse) => fiberHouse.salesOrgId === salesOrg.id).length} Leads</button>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            paddingLeft: "2rem",
                                                            paddingTop: "1rem",
                                                            display: openSalesRepView === salesOrg.id ? 'flex' : 'none',
                                                            flexDirection: "column",
                                                            gap: "0.5rem"
                                                        }}>
                                                            {
                                                                // eslint-disable-next-line array-callback-return
                                                                map.salesReps?.map((salesRep, index) => {
                                                                    if (salesRep.salesOrgId === salesOrg.id) {

                                                                        const noOfLeadsToAssign = selectedLeadIds.filter((fiberHouse) => fiberHouse.salesOrgId === salesOrg.id && fiberHouse.owners.filter(owner => owner.userId === salesRep.id).length === 0).length
                                                                        const noOfLeadsToRemove = selectedLeadIds.filter((fiberHouse) => fiberHouse.salesOrgId === salesOrg.id && fiberHouse.owners.some(owner => owner.userId === salesRep.id)).length

                                                                        return (
                                                                            <div key={index} className={`sales_org ${initialSalesRep.find((s) => salesRep.id === s.userId && !filteredSalesRep.current.find(sf => sf === s.userId)) ? 'active' : ''}`}>
                                                                                <div className='image'>
                                                                                    {
                                                                                        salesRep.profileImage ?
                                                                                            <img src={salesRep.profileImage.url} alt="Map Icon" />
                                                                                            :
                                                                                            <img src={anonUser} alt="Map Icon" />
                                                                                    }
                                                                                </div>
                                                                                <p>{salesRep.firstName + " " + salesRep.lastName}</p>
                                                                                {/* <div className='input'>
                                                                                <div onClick={() => {
                                                                                    handleSalesOrgClicked(salesRep, initialSalesRep.find((s) => salesRep.id === s.userId && !filteredSalesRep.current.find(sf => sf === s.userId)) ? "remove" : "assign")
                                                                                }} type="checkbox" className={`${initialSalesRep.find((s) => salesRep.id === s.userId && !filteredSalesRep.current.find(sf => sf === s.userId)) ? 'active' : ''}`} />
                                                                            </div> */}
                                                                                <div className='input'>
                                                                                    <button onClick={() => { assignLeadsToReps(salesRep) }}
                                                                                        disabled={noOfLeadsToAssign === 0}
                                                                                        style={{ color: noOfLeadsToAssign === 0 ? "#ccc" : '' }} className='assign'>Assign {noOfLeadsToAssign} Leads</button>
                                                                                    <button onClick={() => { removeRepFromLeads(salesRep) }}
                                                                                        style={{ color: noOfLeadsToRemove === 0 ? "#ccc" : '' }}
                                                                                        disabled={noOfLeadsToRemove === 0}
                                                                                        className='remove'>Remove {noOfLeadsToRemove} Leads</button>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            <p className='note'>{selectedLeadIds.filter(l => l.salesOrgId === salesOrg.id && l.owners.length === 0).length} Leads not assigned To any rep</p>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }) :
                                        user.userType === "SalesOrgAdmin" ?
                                            map.salesReps.map((salesRep, index) => {
                                                const noOfLeadsToAssign = selectedLeadIds.filter((fiberHouse) => fiberHouse.owners.filter(owner => owner.userId === salesRep.id).length === 0).length
                                                const noOfLeadsToRemove = selectedLeadIds.filter((fiberHouse) => fiberHouse.owners.some(owner => owner.userId === salesRep.id)).length
                                                return (
                                                    <div key={index} className={`sales_org ${initialSalesRep.find((s) => salesRep.id === s.userId && !filteredSalesRep.current.find(sf => sf === s.userId)) ? 'active' : ''}`}>
                                                        <div className='image'>
                                                            {
                                                                salesRep.profileImage ?
                                                                    <img src={salesRep.profileImage.url} alt="Map Icon" />
                                                                    :
                                                                    <img src={anonUser} alt="Map Icon" />
                                                            }
                                                        </div>
                                                        <p>{salesRep.firstName + " " + salesRep.lastName}</p>
                                                        {/* <div className='input'>
                                                            <div onClick={() => {
                                                                handleSalesRepClicked(salesRep, initialSalesRep.find((s) => salesRep.id === s.userId && !filteredSalesRep.current.find(sf => sf === s.userId)) ? "remove" : "assign")
                                                            }} type="checkbox" className={`${initialSalesRep.find((s) => salesRep.id === s.userId && !filteredSalesRep.current.find(sf => sf === s.userId)) ? 'active' : ''}`} />
                                                        </div> */}
                                                        <div className='input'>
                                                            <button onClick={() => { assignLeadsToReps(salesRep) }}
                                                                disabled={noOfLeadsToAssign === 0}
                                                                style={{ color: noOfLeadsToAssign === 0 ? "#ccc" : '' }} className='assign'>Assign {noOfLeadsToAssign} Leads</button>
                                                            <button onClick={() => { removeRepFromLeads(salesRep) }}
                                                                style={{ color: noOfLeadsToRemove === 0 ? "#ccc" : '' }}
                                                                disabled={noOfLeadsToRemove === 0}
                                                                className='remove'>Remove {noOfLeadsToRemove} Leads</button>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            null
                                }
                                {
                                    user.userType === "SuperAdmin" ?
                                        <p className='note'>{selectedLeadIds.filter(l => l.salesOrgId === null).length} Leads not assigned to any org</p>
                                        :
                                        <p className='note'>{selectedLeadIds.filter(s => s.owners.length === 0).length} Leads not assigned to any rep</p>
                                }
                            </div>

                        </div>
                    </div>
                </Modal>
                <Modal open={openReviewModel} onClose={() => {
                    setOpenReviewModel(false)
                }}>
                    <div style={{
                        padding: "1rem",
                        maxWidth: "90%",
                        minWidth: "50%"
                    }} className='assign_leads_container'>
                        <IoCloseCircle onClick={() => {
                            setOpenReviewModel(false)
                        }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                        <ReviewContainer type={reviewType} searchArea={map.searchArea} />
                    </div>
                </Modal>
                <div className='StatsDiv'>
                    <div className='TitleDiv'>
                        <h1 className='text-4xl font-bold'>Leads Management & Stats</h1>

                        {/*
                        <button style={{
                        marginLeft:"2rem",
                        // backgroundColor: "#181E4B ",
                        color: "#181E4B",
                        textDecoration:"underline",
                        padding: "0.5rem 1rem",
                        borderRadius:"10px",
                        fontWeight:"500",
                        border: '1px solid #181E4B',
                    }}  onClick={() => { handleReset() }}>Reset</button> 
                    */
                        }
                    </div>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        gap: "0.3rem"
                    }} className='Stats'>
                        {/* <div>
                            {
                                updatedFiberHouses.length > 0 &&
                                <button onClick={() => {
                                    update()
                                }}>
                                    Update
                                </button>
                            }
                            <button style={{
                                backgroundColor: "#181E4B ",
                                color: "white",
                                padding: "0.5rem",
                                borderRadius: "5px",
                                cursor: "pointer",
                                margin: "auto",
                                width: "100%"
                            }} onClick={() => { updateCoordinates(fiberHousesState) }}>Reset Position Of The Selected FiberHouses</button>
                        </div> */}
                        <div className='Stat'>
                            <p>Total Leads: {selectedLeadIds?.length}</p>
                        </div>
                        {
                            (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin") && <button onClick={() => {
                                setAddMode(true)
                            }} className='assign_leads'>Change Ownership</button>
                        }
                        <hr style={{ margin: "0.5rem 0rem" }} />
                        <h1 className='lead_owners_title'>Leads Status</h1>
                        <div className='select_deselect'>
                            <button className='select' onClick={() => {
                                setFilterMapIconIds([])
                            }}>Select All</button>
                            <button className='deselect' onClick={() => {
                                setFilterMapIconIds(
                                    map.mapIcons.map((icon) => icon.id)
                                )

                            }}>Deselect All</button>
                        </div>
                        <div className='MapIconStats'>
                            {
                                getMapIconStats().map((stat) => {
                                    return (
                                        <div onClick={() => {
                                            setFilterMapIconIds(
                                                (prev) => {
                                                    if (prev.includes(stat.assignedMapIcons.id)) {
                                                        return prev.filter((id) => id !== stat.assignedMapIcons.id)
                                                    }
                                                    else {
                                                        return [...prev, stat.assignedMapIcons.id]
                                                    }
                                                }
                                            )
                                        }} style={{
                                            borderRadius: "10px",
                                            borderWidth: "2px"
                                        }} className={`MapIconStat ${!filterMapIconIds.includes(stat.assignedMapIcons.id) ? 'active' : null}`}>
                                            <h3>
                                                <img src={"data:image/png;base64," + stat.assignedMapIcons.image} alt="Map Icon" />
                                            </h3>
                                            <p>{stat.assignedMapIcons.abbreviation}</p>
                                            <p style={{
                                                fontWeight: !filterMapIconIds.includes(stat.assignedMapIcons.id) ? "bold" : null
                                            }}>{!filterMapIconIds.includes(stat.assignedMapIcons.id) ? stat.count : 0}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            user.userType === "SuperAdmin" &&
                            <>
                                <hr style={{ margin: "0.5rem 0rem" }} />
                                <h1 className='lead_owners_title'>Sales Org</h1>
                                <div className='select_deselect'>
                                    <button className='select' onClick={
                                        () => {
                                            filteredSalesOrg.current = []
                                            setSelectedLeadIds(
                                                fiberHousesState.filter((fiberHouse) => {
                                                    return !filterMapIconIds.includes(fiberHouse.mapIconId) &&
                                                        !filteredSalesOrg.current.includes(fiberHouse.salesOrgId) &&
                                                        !fiberHouse.owners.some(owner => filteredSalesRep.current.includes(owner.userId));
                                                })
                                            )
                                        }
                                    }>Select All</button>
                                    <button className='deselect' onClick={() => {
                                        filteredSalesOrg.current = initialSalesOrg.map((owner) => owner.id)
                                        setSelectedLeadIds(
                                            fiberHousesState.filter((fiberHouse) => {
                                                return !filterMapIconIds.includes(fiberHouse.mapIconId) &&
                                                    !filteredSalesOrg.current.includes(fiberHouse.salesOrgId) &&
                                                    !fiberHouse.owners.some(owner => filteredSalesRep.current.includes(owner.userId));
                                            })
                                        )
                                    }}>Deselect All</button>
                                </div>
                                <div className='lead_owners'>
                                    {
                                        initialSalesOrg.map((salesOrg) => {

                                            return (
                                                <div onClick={() => {
                                                    filterSalesOrg(salesOrg.id)
                                                }} className={`lead_owner ${filteredSalesOrg.current.find((s) => salesOrg.id === s) ? null : 'active'}`}>
                                                    {
                                                        salesOrg.icon ?
                                                            <img src={"data:image/png;base64," + salesOrg.icon} alt="Map Icon" />
                                                            :
                                                            <RiTeamFill size={30} color='black' />
                                                    }
                                                    <p>{salesOrg.name}</p>
                                                    <p style={{
                                                        fontWeight: "bold"
                                                    }}> {selectedLeadIds.filter(fh => fh.salesOrgName === salesOrg.name).length}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        }
                        <hr style={{ margin: "0.5rem 0rem" }} />
                        {
                            (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin") &&
                            <>
                                <h1 className='lead_owners_title'>Sales Rep</h1>
                                <div className='select_deselect'>
                                    <button className='select' onClick={
                                        () => {
                                            filteredSalesRep.current = []
                                            setSelectedLeadIds(
                                                fiberHousesState.filter((fiberHouse) => {
                                                    return !filterMapIconIds.includes(fiberHouse.mapIconId) &&
                                                        !filteredSalesOrg.current.includes(fiberHouse.salesOrgId) &&
                                                        !fiberHouse.owners.some(owner => filteredSalesRep.current.includes(owner.userId));
                                                })
                                            )
                                        }
                                    }>Select All</button>
                                    <button className='deselect' onClick={() => {
                                        filteredSalesRep.current = initialSalesRep.map((owner) => owner.userId)
                                        setSelectedLeadIds(
                                            fiberHousesState.filter((fiberHouse) => {
                                                return !filterMapIconIds.includes(fiberHouse.mapIconId) &&
                                                    !filteredSalesOrg.current.includes(fiberHouse.salesOrgId) &&
                                                    !fiberHouse.owners.some(owner => filteredSalesRep.current.includes(owner.userId));
                                            })
                                        )
                                    }}>Deselect All</button>
                                </div>
                                <div className='lead_owners'>
                                    {
                                        initialSalesRep?.filter((owner, index, self) =>
                                            index === self.findIndex((o) => o.userId === owner.userId)
                                        ).map((owner) => {

                                            return (
                                                <div className='sales_rep_wrapper'>
                                                    <div onClick={() => {
                                                        filterSalesRep(owner.userId)
                                                    }} className={`lead_owner ${filteredSalesRep.current.find((user) => user === owner.userId) ? null : 'active'}`}>
                                                        {
                                                            owner.profileImage ?
                                                                <img style={{ objectFit: "cover" }} src={owner.profileImage} alt="Map Icon" />
                                                                :
                                                                <img src={anonUser} alt="Map Icon" />
                                                        }
                                                        <p >{owner.userId === user.id ? "You" : owner.name}</p>
                                                        <p style={{
                                                            fontWeight: "bold"
                                                        }}>{
                                                                selectedLeadIds.filter(fh => fh.owners.length === 1).filter((fh) => {
                                                                    return fh.owners.some((o) => o.userId === owner.userId)
                                                                }).length
                                                            }</p>
                                                    </div>
                                                    {
                                                        (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin") &&
                                                        <button onClick={() => {
                                                            removeRepFromLeads({
                                                                id: owner.userId,
                                                                firstName: owner.name.split(" ")[0],
                                                                lastName: owner.name.split(" ")[1]
                                                            })
                                                        }} className='remove_rep'>Remove</button>
                                                    }
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                {
                                    selectedLeadIds.filter(fh => fh.owners.length > 1).length>0
                                     &&
                                    <>
                                        <h1 className='lead_owners_title'>Shared Leads</h1>
                                        <div className='select_deselect'>
                                            <button className='select'>Select All</button>
                                            <button className='deselect'>Deselect All</button>
                                        </div>
                                        <div className='lead_owners'>
                                            {
                                                showSharedLeads()
                                            }
                                        </div>
                                    </>
                                }
                                <hr style={{ margin: "0.5rem 0rem" }} />
                            </>}
                        <div className='ratio_container'>
                            <p className='label'>Ratio ( Customers / Total Leads ):</p>
                            <p className='ratio'>{((fiberHousesState.filter(fh => fh.convertedToCustomer).length / fiberHousesState.length) * 100).toFixed(1)}%</p>
                        </div>
                        <hr style={{ margin: "0.5rem 0rem" }} />
                        <div className='stats_review_container'>
                            <h1 className='lead_owners_title'>Customer Reviews ({fiberHousesState.filter((fh) => fh.hasReviews).length})</h1>
                            <Rating value={5} precision={0.5} readOnly />
                        </div>
                        <div className='stats_icon_reviews'>
                            <div onClick={() => {
                                setReviewType("Written")
                                setOpenReviewModel(true)
                            }} className='icon'>
                                <TfiWrite size={40} color='black' />
                            </div>
                            <div onClick={() => {
                                setReviewType("Video")
                                setOpenReviewModel(true)
                            }} className='icon'>
                                <RiFileVideoLine size={40} color='black' />
                            </div>
                            <div onClick={() => {
                                setReviewType("SpeedTest")
                                setOpenReviewModel(true)
                            }} className='icon'>
                                <SiPagespeedinsights size={40} color='black' />
                            </div>
                        </div>
                    </div>

                    {
                        (user.userType === "SuperAdmin" || user.userType === "Admin") &&
                        <>
                            <hr style={{ margin: "0.5rem 0rem" }} />
                            <h1 style={{
                                marginTop: "1rem"
                            }} className='lead_owners_title'>Update Lead Status</h1>
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr auto",
                                gap: "0.5rem",

                            }}>
                                <MapIconSelector setAssignedMapIcons={setSelectedMapIcon} placeholder='Select Map Icon' />
                                <button style={{
                                    backgroundColor: "#181E4B ",
                                    color: "white",
                                    padding: "0.5rem",
                                    borderRadius: "5px",
                                    cursor: "pointer"
                                }} onClick={() => { updateIcons() }} >Update</button>
                            </div>
                        </>
                    }
                    <hr style={{ margin: "0.5rem 0rem" }} />
                    {
                        user.userType === "SuperAdmin" &&
                        <>
                            <h1 style={{
                                marginTop: "1rem"
                            }} className='lead_owners_title'>Assign Fiber Company</h1>
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr auto",
                                gap: "0.5rem",
                            }}>
                                <FiberCompanySelector setUser={setFiberCompany} placeholder='Select Company' />
                                <button style={{
                                    backgroundColor: "#181E4B ",
                                    color: "white",
                                    padding: "0.5rem",
                                    borderRadius: "5px",
                                    cursor: "pointer"
                                }} onClick={() => { assignLeadToFiberCompany() }} >Update</button>
                            </div>
                        </>
                    }
                    <hr style={{ margin: "0.5rem 0rem" }} />
                    <div className='stats_input_container'>
                        <input className='stats_input' type='text' placeholder='Search FiberHouses' onChange={handleSearchChange} />
                    </div>
                    <div className='fiberHouse_list'>

                        {
                            currentItems != null &&
                            currentItems?.map(
                                (fh, index) => {
                                    return (
                                        <React.Fragment key={index} >
                                            <div key={index} className="LeadCard">

                                                <div className='ImagesDiv'>
                                                    <div className='Image'>
                                                        <img src={"data:image/png;base64," + map.mapIcons.find(assignedMapIcons => assignedMapIcons.id === fh?.mapIconId)?.image} alt="Map Icon" />
                                                    </div>

                                                </div>
                                                <div className='DataDiv'>
                                                    <div className='Name'>
                                                        <h3>{fh.customer != null ? fh.customer.firstName + " " + fh.customer.lastName : fh.name ? fh.name : "No Name"}</h3>
                                                    </div>
                                                    <div className='Address'>
                                                        <p  >{
                                                            fh.address ?
                                                                fh.address : fh.mapstring
                                                        }</p>
                                                    </div>
                                                    <div className='Phone'>
                                                        <p>{
                                                            fh.phone ?
                                                                fh.phone : <FaPhoneSlash color='red' size={20} />
                                                        }</p>
                                                    </div>
                                                    <div className='additional_info'>
                                                        {
                                                            (fh.customer !== null) &&
                                                            <p>{getCustomerLine(fh)}</p>
                                                        }
                                                        {
                                                            fh.customer !== null && fh.customer.installDateTime &&
                                                            <p>{new Date(fh.customer.installDateTime).toLocaleDateString()}</p>
                                                        }
                                                        {
                                                            fh.appointmentDateTime &&
                                                            <p>Appointment: {new Date(fh.appointmentDateTime).toLocaleDateString()}</p>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    mapObject.getView().getZoom() >= 15.6 && map.searchAreaFeature.length === 0 &&
                                                    <div className='dbt_1' style={{
                                                        // display:"flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        gap: "0.5rem",
                                                        flexDirection: "column"
                                                    }}>

                                                        <div className='dbt_1' style={{
                                                            // display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: "2rem",
                                                            height: "2rem",

                                                        }} onClick={() => {
                                                            handleLeadPop(fh)
                                                        }}>
                                                            <TbStackPop size={30} color='#2761D0' />
                                                        </div>
                                                    </div>
                                                }

                                                <div className="MainLeadCard_Buttons">

                                                    {
                                                        mapObject.getView().getZoom() >= 15.6 && map.searchAreaFeature.length === 0 &&
                                                        <div className="dbt_2" style={{
                                                            width: "2rem",
                                                            height: "2rem",
                                                            cursor: 'pointer'
                                                        }}  >
                                                            <div className='dbt_2' style={{
                                                                // display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: "2rem",
                                                                height: "2rem",

                                                            }} onClick={() => {
                                                                handleLeadPop(fh)
                                                            }}>
                                                                <TbStackPop size={30} color='#2761D0' />
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className="MainLeadCard_Button"
                                                        onClick={() => sendEmail(fh)}
                                                    >
                                                        <MdOutlineMail color='#81848A' size={30} />
                                                    </div>
                                                    <div className="MainLeadCard_Button"
                                                        onClick={() => whatsapp(fh)}
                                                    >
                                                        <FaWhatsapp color='#81848A' size={30} />
                                                    </div>
                                                    <div className="MainLeadCard_Button"
                                                        onClick={() => sendText(fh)}
                                                    >
                                                        <MdOutlineMessage color='#81848A' size={30} />

                                                    </div>
                                                    <div className="MainLeadCard_Button"
                                                        onClick={() => call(fh)}
                                                    >
                                                        <MdOutlinePhone color='#81848A' size={30} />

                                                    </div>
                                                    <div className="MainLeadCard_Button"
                                                        onClick={() => direction(fh)}
                                                    >
                                                        <MdOutlineDirections color='#81848A' size={30} />
                                                    </div>
                                                    <div
                                                        onClick={() => { snapToLocation(fh) }}
                                                        className='MainLeadCard_Button'>
                                                        <IoMapOutline color='#81848A' size={30} />
                                                    </div>
                                                    <div onClick={() => copyAddress(fh)} className='MainLeadCard_Button'>
                                                        <FaCopy color='#81848A' size={30} />
                                                    </div>
                                                    {/* {
                                                user.userType === 'SuperAdmin' && lead.fidiumCustomer != null &&
                                                <div onClick={()=>{
                                                    markCustomerAsInvalid(lead)
                                                }} className='MainLeadCard_Button'>
                                                    <IoIosRemoveCircle color='#81848A' size={30} />
                                                </div>
                                            } */}
                                                </div>

                                            </div>
                                        </React.Fragment>
                                    )
                                }
                            )
                        }
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "1rem"
                        }}>
                            <Pagination count={totalPages} page={currentPage} onChange={(e, page) => setCurrentPage(page)} />
                        </div>
                    </div>
                </div>
            </>
        // :
        // addMode === 'reps' ?
        //     <AssignLeadsToReps selectedLeadIds={selectedLeadIds} setAddMode={setAddMode} initialSalesRep={initialSalesRep} initialSalesOrg={initialSalesOrg

        //     } />
        //     :
        //     <AssignLeadsToOrg selectedLeadIds={selectedLeadIds} setAddMode={setAddMode} initialSalesOrg={initialSalesOrg} />

    )
}

export default Stats