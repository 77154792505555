/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import { MapContext } from '..'
import FiberHouseApi from '../../../../API/FiberHouseApi'
// import MapIconApi from '../../../../API/MapIconApi'
import EditFiberHouseReq from '../../../../Requests/FiberHouse/EditFiberHouseReq'
import { useSwipeable } from 'react-swipeable'
import { UserContext } from '../../../../App'
import { Fill, Icon, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
const MapIconDisplay = ({ selectedFiberHouse, setMapIcon,removeLightningBolts }) => {

    // const [mapIcons,setMapIcons] = React.useState([])
    const user = React.useContext(UserContext)
    const { fiberHouses,shouldReRenderMap, setFiberHouses, mapIcons, selectedFiberHouseFeature, mapObject } = React.useContext(MapContext)
    const [selectedMapIcon, setSelectedMapIcon] = React.useState(null)
    const mapIconsRef = React.useRef(null)

    const lightningBolts = [
        "Buy1",
        "Inst1",
        "Inst2",
        "Inst3",
        "CNL",
        "PC",
        "Inst4",
        "IC",
        "OSL"
    ]

    const refPassthrough = (el) => {
        handlers.ref(el);
        mapIconsRef.current = el;
    }
    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            eventData.event.stopPropagation();
        },
        onSwiping: (eventData) => {
            eventData.event.stopPropagation();
        },
        onSwipedDown: (eventData) => {
            eventData.event.stopPropagation();
        },
    })


    const onClick = (assignedMapIcons) => {

        setSelectedMapIcon(assignedMapIcons);
        if (selectedFiberHouseFeature && selectedFiberHouseFeature.get('id') === selectedFiberHouse.id) {
            console.log("Fiber House Feature Found")
            const newStyle = new Style({
                image: new Icon({
                    scale: 1.5, // Adjust this value to change the scale of the icon
                    src: "data:image/png;;base64," + assignedMapIcons?.image,
                }),
            });

            const circleStyle = new Style({
                image: new CircleStyle({
                    radius: 15, // This is now in pixels
                    stroke: new Stroke({
                        color: "black", // Border color
                        width: 4, // Border width
                    }),
                    fill: new Fill({
                        color: "rgba(0, 0, 0, 0)", // Transparent fill
                    }),
                }),
            });
            selectedFiberHouseFeature.setStyle([
                newStyle, // This is the icon style
                circleStyle, // This is the circle style
            ]);
              selectedFiberHouseFeature.values_.mapIconId = assignedMapIcons.id
            console.log(assignedMapIcons.id)
            console.log(selectedFiberHouseFeature)
            setMapIcon(assignedMapIcons.id)
            mapObject.getView().setZoom(
                mapObject.getView().getZoom() + 0.000001
            )

     




        }
        shouldReRenderMap.current = false;
        const index = binarySearchFiberHouses(fiberHouses, selectedFiberHouse.id);
        console.log(index)
        const fh = fiberHouses
        if (index !== -1) {
            fh[index].mapIconId = assignedMapIcons.id;
            setFiberHouses([...fh]);
        }
        else {
            const fh = fiberHouses.map((fiberHouse) => {
                if (fiberHouse.id === selectedFiberHouse.id) {
                    return {
                        ...fiberHouse,
                        mapIconId: assignedMapIcons.id
                    }
                }
                return fiberHouse;
            });
            setFiberHouses([...fh]);
        }


    };

    function binarySearchFiberHouses(fiberHouses, targetId) {
        let left = 0;
        let right = fiberHouses.length - 1;

        while (left <= right) {
            const mid = Math.floor((left + right) / 2);
            const midId = fiberHouses[mid].id;

            if (midId < targetId) {
                left = mid + 1;
            } else if (midId > targetId) {
                right = mid - 1;
            } else {
                return mid; // Found the target, return its index
            }
        }

        return -1; // Target not found
    }

    useEffect(() => {
        if (selectedMapIcon) {
            editMapIcon()
        }
    }, [selectedMapIcon])




    const editMapIcon = () => {

        console.log("Selected Fiber House", selectedFiberHouse)
        const req = new EditFiberHouseReq()
     
        req.id = selectedFiberHouse.id
    
        req.coordinate = null
        req.mapIconId = selectedMapIcon.id
        req.salesRepId = user?.id

        FiberHouseApi.EditFiberHouse(req).then(
            (res) => {
                if (res?.status?.success) {
           
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: res?.status?.message,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        toast: true,
                        timer: 1500,
                        position: "bottom-end",
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    });
                }
            }
        )
    }


    return (
        <>
            {/* <h3 className="text-2xl text-center mt-4 mb-0">Select a Map Icon</h3> */}
            {/* <div className='flex gap-4 flex-wrap justify-center items-center' > */}
            <div ref={refPassthrough}
                {...handlers} className='MapIconDisplay' >
                {
                    mapIcons.filter(m => {
                        if(removeLightningBolts){
                            return !lightningBolts.includes(m.abbreviation)
                        }
                        return true
                    }).map(
                        (assignedMapIcons) => {
                            // return <div onClick={()=>{onClick(assignedMapIcons)}} className={`flex flex-col items-center justify-center w-fit h-28 hover:bg-blue-300 p-2 rounded-lg focus:bg-blue-300 ${selectedMapIcon?.id===assignedMapIcons.id?"bg-blue-300":""} `}>
                            return <div onClick={() => { onClick(assignedMapIcons) }} className={`MapIconDiv`}
                                style={{
                                    border: selectedMapIcon != null ?
                                        (selectedMapIcon?.id === assignedMapIcons.id ? "2px solid #2563EB" : "2px solid #E5E7EB") :
                                        (selectedFiberHouse?.mapIconId === assignedMapIcons?.id ? "2px solid #2563EB" : "2px solid #E5E7EB")
                                }}>
                                <img alt='Map Icon' className="w-16" src={"data:image/png;base64," + assignedMapIcons.image} />
                                <div>{assignedMapIcons.abbreviation}</div>
                            </div>
                        }

                    )
                }
            </div>
            {/* <div className='MapIconSaveButtonDiv' >
                {selectedMapIcon && <button onClick={() => { editMapIcon() }} className='bg-blue-500 text-white rounded-lg p-2 w-fit mx-auto mt-3 mb-3'>Save Map Icon</button>}
            </div> */}
        </>
    )
}

export default MapIconDisplay