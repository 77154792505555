import React,{useContext, useState} from 'react'
import { Modal } from '@mui/material'
import FiberCompanySelector from '../../../../../components/FiberCompanySelector'
import PhoneInput from 'react-phone-input-2'
import authorized from './Images/authorized.png'
import authorizedFastwyre from './Images/authorizedFastwyre.png'
import logo from './Images/logo.png'
import CreateLightCustomerReq  from '../../../../../Requests/Customer/CreateLightCustomerReq'
import CustomerApi from '../../../../../API/CustomerApi'
import Swal from 'sweetalert2'
import { getAuth } from 'firebase/auth'
import { sendPasswordResetEmail } from 'firebase/auth'
import { MapContext } from '../..'
import { UserContext } from '../../../../../App'

const FreeInstallForm = ({open,setOpen,lead,fiberHouse,setReload}) => {
    
    const [lightFirstName, setLightFirstName] = useState(lead?.name?.split(' ')[0])
    const [lightLastName, setLightLastName] = useState(lead?.name?.split(' ').length > 1 ? lead?.name?.split(' ')[1] : '')
    const [lightEmail, setLightEmail] = useState(lead?.email)
    const [lightPhone, setLightPhone] = useState(lead?.phone)
    const [lightConsent, setLightConsent] = useState(false)
    const [lightCompany, setLightCompany] = useState(0)

    const {setFiberHouses,setSelectedFiberHouse} = useContext(MapContext)
    const user = useContext(UserContext)


    const auth = getAuth();
    const createLightCustomer = async () => {
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Creating Light Customer",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            customClass: {
                container: 'custom-swal'
            },
            willOpen: () => {
                Swal.showLoading();
            },
        })

        if (!lightFirstName) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "First Name is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!lightEmail) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Email is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        var pattern = new RegExp(/^\S+@\S+\.\S+$/);
        if (!pattern.test(lightEmail)) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Email',
                text: 'Please enter a valid email address',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        if (!lightPhone) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Phone is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (lightPhone.length < 11) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Phone is invalid",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return
        }

        if (!lightCompany) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Company is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!lightConsent) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Consent is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }


        const req = new CreateLightCustomerReq()
        req.leadId = lead.id
        req.firstName = lightFirstName
        req.lastName = lightLastName
        req.email = lightEmail
        req.phoneNumber = lightPhone
        req.companyId = lightCompany
        req.salesRepId = user.id
        req.signedUpBy = "SalesRep"
        req.salesOrgId = user?.salesOrgId
        const res = await CustomerApi.CreateLightCustomer(req)
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Light Customer Created Successfully",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            sendPasswordResetEmail(auth, lightEmail)
                .then((value) => {
                    console.log(value)
                })
                .catch((error) => {
                    const errorMessage = error.message;

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: errorMessage,
                    });
                });
            setSelectedFiberHouse(
                (prev) => {
                    return {
                        ...prev,
                        mapIconId: 9
                    }
                }
            )
            setFiberHouses(
                (prev) => {
                    return prev.map((fh) => {
                        if (fh.id === fiberHouse.id) {
                            return {
                                ...fh,
                                mapIconId: 9
                            }
                        }
                        return fh
                    })
                }
            )
            setReload(
                (prev) => {
                    return !prev
                }
            )

            setOpen(false)
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                customClass: {
                    container: 'custom-swal'
                },
                text: res?.status?.message
            })
        }
    }

    return (
        <Modal setOpen={setOpen} minHeight={'80vh'} open={open} onClose={() => {
            setOpen(false)
        }} >
            <div className='custom_modal'>

                {
                    lightCompany === 2 ?
                        <img alt="Logo" style={{ width: "100%" }} src={authorized} className='logo' />
                        : lightCompany === 9 ?
                            <img alt="Logo" style={{ width: "100%" }} src={authorizedFastwyre} className='logo' />
                            : <img alt='Logo' src={logo} style={{ width: "100%" }} className='logo' />

                }
                <div className='inputs'>
                    <input placeholder='*First Name' value={lightFirstName} onChange={(e) => {
                        setLightFirstName(e.target.value)
                    }} />
                    <input placeholder='Last Name' value={lightLastName} onChange={(e) => {
                        setLightLastName(e.target.value)
                    }} />
                    <input placeholder='Email' value={lightEmail} onChange={(e) => {
                        setLightEmail(e.target.value)
                    }} />
                    <FiberCompanySelector companiesWithOfferTemplateOnly={true} setUser={setLightCompany} disabled={false} placeholder="Select a Company" className="fiber_company_selector" />
                    <PhoneInput placeholder='* Cellphone' country={'us'} containerStyle={{
                        width: "100%",
                        borderRadius: "10px"
                    }} inputClass='custom_input' value={lightPhone} onChange={(e) => {
                        setLightPhone(e)
                    }} />
                    <div className='consent'>
                        <input type="checkbox"
                            checked={lightConsent}
                            onChange={(e) => {
                                setLightConsent(e.target.checked)
                            }}
                        />
                        <label>I agree to receive email and/or sms messages from Lightning OS LLC.</label>
                    </div>
                    <button onClick={() => {
                        createLightCustomer()
                    }
                    }>Reserve Free Install</button>

                </div>
            </div>
        </Modal>
    )
}

export default FreeInstallForm