import React, { useContext, useEffect } from 'react'
import { UserContext } from './App';
import FiberHouseApi from './API/FiberHouseApi';
import StringIntReq from './Requests/StringIntReq';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const RequestedCustomerReminder = () => {


    const user = useContext(UserContext);
    const navigate = useNavigate();

    const searchRequestedCustomers = async () => {
        const req = new StringIntReq();
        req.int = user.id;
        const res = await FiberHouseApi.GetNumberOfRequestedCustomers(req)

        if (res?.status?.success) {
            if (res.data.int > 0) {
                Swal.fire({
                    title: 'New Requested Customers!',
                    text: 'You have ' + res.data.int + ' new requested customers',
                    icon: 'info',
                    confirmButtonText: 'Set Them Up Now!',
                    showCancelButton: true,
                    cancelButtonText: 'Later',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/contact-center',{
                            state:{
                                leadType:"RequestedCustomer"
                            }
                        })
                    }
                });
            }
        }

    }


    useEffect(() => {
        searchRequestedCustomers();
    }, [])

    return (
        <></>
    )
}

export default RequestedCustomerReminder