class AnnouncementReq {
    constructor() {
        this.id = null;
        this.message= null;
        this.announcementType= null;
        this.startDate= null;
        this.endDate= null;
        this.isHidden = null;
    }
};

export default AnnouncementReq;