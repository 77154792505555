/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import MapIconApi from '../../../API/MapIconApi';
import SearchLeadsForContactCenterReq from '../../../Requests/Customer/SearchLeadsForContactCenterReq';
import { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { UserContext } from '../../../App';
import greyUser from '../../../assets/images/userGrey.png'
import ContactButtons from '../../../Services/ContactButtons';
import { FaPhoneSlash, FaSearch, FaWhatsapp } from "react-icons/fa";
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MdExpandMore, MdOutlineDirections, MdOutlineMail, MdOutlineMessage, MdOutlinePhone } from 'react-icons/md';
// import { TbMessage2Bolt } from 'react-icons/tb';
import { IoMapOutline } from 'react-icons/io5';
import QuickDate from '../../../components/QuickDate';
import ContextMenu from '../../../components/ContextMenu';
import ExportData from '../../../Services/ExportData';
import Swal from 'sweetalert2';
import UserSelector from '../../../components/UserSelector';
import OrganizationSelector from '../../../components/OrganizationSelector';
import FiberCompanySelector from '../../../components/FiberCompanySelector';
import DigitalToolBelt from '../../../components/DigitalToolBelt';
import { IoIosAddCircle, IoIosRemoveCircle } from 'react-icons/io';
import StringIntReq from '../../../Requests/StringIntReq';
import CustomerApi from '../../../API/CustomerApi';
import CustomerInfo from '../../../components/CustomerInfo';
const Leads = ({ fromDate, toDate, salesReps, quickDateValue, type, soldOnly, passedOrgIds, knocked, leadType, dateTypeProp, fiberCompanyIds, customerName, source }) => {
    const [leads, setLeads] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [mapIcons, setMapIcons] = useState([]);
    const user = useContext(UserContext);
    const contactButtons = new ContactButtons(user);
    const [search, setSearch] = useState('');
    const [filterBy, setFilterBy] = useState(leadType ? leadType : 'Customer');
    const [customerStatus, setCustomerStatus] = useState('Sold');
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    // const [isVerified, setIsVerified] = useState(true);
    // const [mapIconIds, setMapIconIds] = useState([]);
    const [lastUpdatedFrom, setLastUpdatedFrom] = useState(fromDate ? fromDate : null);
    const [lastUpdatedTo, setLastUpdatedTo] = useState(toDate ? toDate : null);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState("20"); // (*)
    const observer = useRef(); // (*)
    const [firstRender, setFirstRender] = useState(true);
    const [selectedLeads, setSelectedLeads] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [salesRepIds, setSalesRepIds] = useState([]);
    const [orgIds, setOrgIds] = useState([]);
    const initialRender = useRef(true);
    // const [activeButton, setActiveButton] = useState('');
    const exportData = new ExportData();
    const navigate = useNavigate();
    const [selectedLead, setSelectedLead] = useState(null);
    const [openToolBelt, setOpenToolBelt] = useState(false);
    const [openCustomerInfo, setOpenCustomerInfo] = useState(false);
    const [dateType, setDateType] = useState(dateTypeProp ? dateTypeProp : 'DateSold');
    const [mapIconIds, setMapIconIds] = useState([]);
    const [filtersLoaded, setFiltersLoaded] = useState(false);
    const [includeInvalidCustomers, setIncludeInvalidCustomers] = useState(false);
    const [fiberCompanies, setFiberCompanies] = useState([]);
    const [orderType, setOrderType] = useState('All');


    const [userWithName, setUserWithName] = useState(null);
    const [orgWithName, setOrgWithName] = useState(null);
    const [fiberCompaniesWithName, setFiberCompaniesWithName] = useState(null);

    const [quickDateValue2, setQuickDateValue2] = useState(quickDateValue ? quickDateValue : null);


    useEffect(() => {
        if (customerName) {
            setSearch(customerName);
        }
    }, [customerName]);
    // Set customerStatus based on the type prop
    useEffect(() => {
        setUserWithName(salesReps)
        setOrgWithName(passedOrgIds)
        setFiberCompaniesWithName(fiberCompanyIds)
        if (type) {
            setCustomerStatus(type);
        }
    }, [type, salesReps]);
    const lastCard = useCallback(
        (node) => {
            if (!isLoaded) {
                console.log("Skip due to loading");
                return;
            }
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {

                if (entries[0].isIntersecting && hasMore) {
                    setPageNumber((prev) => prev + 1);
                    console.log("visible");
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLoaded, hasMore]
    );

    const openMap = (lead) => {
        if (lead.fiberHouse && lead.fiberHouse.coordinates) {

            navigate('/map', {
                state: {
                    fiberHouse: {
                        ...lead.fiberHouse,
                        leadId: lead.id,
                        lead: {
                            ...lead,
                            fiberHouse: null
                        }
                    }
                }
            })
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'No Coordinates',
                text: 'This lead does not have any coordinates'
            })

        }

    }

    const getMapIcons = async () => {
        const res = await MapIconApi.GetAllMapIcons()
        if (res?.status?.success) {
            res.data.sort((a, b) => a.order - b.order)
            setMapIcons(res.data)
            localStorage.setItem('mapIcons', JSON.stringify(res.data))
            // setMapIconIds(res.data.map((assignedMapIcons) => assignedMapIcons.id))
        }
    }
    useEffect(() => {
        if (mapIcons == null || mapIcons.length === 0) {
            getMapIcons()
        }
        setLastUpdatedFrom(fromDate)
        setLastUpdatedTo(toDate)
        setQuickDateValue2(quickDateValue)
        console.log(fromDate, toDate, quickDateValue)
    }, [fromDate, toDate, quickDateValue]
    )

    const searchLeads = async (page, firstSearch) => {
        setIsLoaded(false);
        const req = new SearchLeadsForContactCenterReq();
        req.SearchString = search;
        req.CustomersOnly = filterBy === 'Customer' ? true : filterBy === 'All' ? null : false;
        req.CustomerStatus = customerStatus;
        req.CustomerType = filterBy === 'KnockedDoors' ? 'All' : filterBy;
        req.Knocked = filterBy === 'KnockedDoors' ? true : null;
        //req.IsVerfied = isVerified;
        if (page) {
            req.PagingParams.PageNumber = 1;
            setPageNumber(1);
        }
        else {
            req.PagingParams.PageNumber = pageNumber;
        }


        req.orderType = orderType;

        req.PagingParams.PageSize = parseInt(pageSize);
        if (user.userType === 'SalesRep' || user.userType === 'SalesOrgAdmin') {

            req.SalesOrgId = user?.salesOrgId;
        }
        if (user.userType === 'SalesOrgAdmin') {
            req.TeamAdminId = user.id;
        }
        else if (user.userType === 'SalesRep') {
            req.SalesRepId = user.id;
        }
        req.mapIconIds = null;
        req.SalesRepIds = userWithName ? userWithName.map(user => user.id) : salesRepIds;

        if (orgWithName) {
            req.SalesOrgIds = orgWithName.filter(org => org.id === 0).length > 0 ? null : orgWithName.map(org => org.id)
        }

        req.fiberCompanyIds = fiberCompaniesWithName ? fiberCompaniesWithName.map(company => company.id) : fiberCompanyIds;
        req.mapIconIds = mapIconIds.length === 0 ? null : mapIcons.filter(mapIcon => !mapIconIds?.includes(mapIcon.id)).map(mapIcon => mapIcon.id)
        req.includeInvalidCustomers = includeInvalidCustomers;

        console.log(lastUpdatedFrom)
        console.log(lastUpdatedTo)
        if (filterBy !== 'KnockedDoors') {
            switch (dateType) {
                case "LastUpdated":
                    if (lastUpdatedFrom) {
                        req.lastUpdatedFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.lastUpdatedTo = lastUpdatedTo;
                    }
                    break;
                case "InstallDate":
                    if (lastUpdatedFrom) {
                        req.installDateFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.installDateTo = lastUpdatedTo;
                    }
                    break;
                case "DateSold":
                    if (lastUpdatedFrom) {
                        req.timeStampFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.timeStampTo = lastUpdatedTo;
                    }
                    break;
                case "AppointmentDate":
                    if (lastUpdatedFrom) {
                        req.appointmentTimeFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.appointmentTimeTo = lastUpdatedTo;
                    }
                    break;
                case "CancelRequestTime":
                    if (lastUpdatedFrom) {
                        req.cancelRequestTimeFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.cancelRequestTimeTo = lastUpdatedTo;
                    }
                    break;
                case "RescheduleRequestTime":
                    if (lastUpdatedFrom) {
                        req.rescheduleRequestTimeFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.rescheduleRequestTimeTo = lastUpdatedTo;
                    }
                    break;

                case "KnockedDate":
                    if (lastUpdatedFrom) {
                        req.knockedDateFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.knockedDateTo = lastUpdatedTo;
                    }
                    break;
                case "OfferSentTime":
                    if (lastUpdatedFrom) {
                        req.offerTimeFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.offerTimeTo = lastUpdatedTo;
                    }
                    break;
                default:
                    break;
            }
        }
        else {
            if (lastUpdatedFrom) {
                req.knockedDateFrom = new Date(lastUpdatedFrom);
            }
            if (lastUpdatedTo) {
                req.knockedDateTo = new Date(lastUpdatedTo);
            }
        }
        const res = await MapIconApi.SearchLeadsForContactCenter(req);
        if (res?.status?.success) {
            setLeads((prev) => {
                if (prev !== null && pageNumber > 1)
                    return [...new Set([...prev, ...res?.data?.list])];
                else
                    return res?.data?.list;
            }
            )
            setTotalItems(res.data.totalItems);
            console.log(res)
            // if (pageNumber < res.data.totalPages) {
            //     setHasMore(true);
            // }
            // else
            //     setHasMore(false);
            setHasMore(res.data.hasNextPage);

            setIsLoaded(true);
        }
    }


    const filterSearch = () => {
        // setPageNumber(1);
        setLeads(null);
        searchLeads(1);

    }

    useEffect(() => {
        if (pageNumber > 1 && !firstRender)
            searchLeads();

        setFirstRender(false);
    }, [pageNumber])

    // useEffect(() => {
    //     if(!initialRender.current){
    //         setPageNumber(1)
    //         filterSearch();
    //     }
    // }, [search, filterBy, lastUpdatedFrom, lastUpdatedTo, customerStatus, salesRepIds,userWithName, orgIds, dateType,mapIconIds])

    const getImage = (lead) => {
        if (lead.fidiumCustomer != null) {
            if (lead.fidiumCustomer.user?.profileImage != null) {
                return <img src={lead.fidiumCustomer.user.profileImage.url} alt="User" />
            }
            else {
                return <img src={greyUser} alt="User" />
            }
        }
        else {
            return <img src={greyUser} alt="User" />
        }
    }
    const getMapIcon = (lead) => {
        if (lead.fiberHouse != null) {
            if (lead.fiberHouse.mapIconId != null) {
                return <img src={"data:image/png;base64," + mapIcons.find(assignedMapIcons => assignedMapIcons.id === lead.fiberHouse.mapIconId)?.image} alt="Map Icon" />
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }
    const getName = (lead) => {
        if (lead.fidiumCustomer != null) {
            return lead.fidiumCustomer.firstName + " " + lead.fidiumCustomer.lastName;
        }
        else if (lead.name != null) {
            return lead.name;
        }
        else {
            return "No Name";
        }
    }


    const getSalesRepName = (lead) => {
        if (lead.fidiumCustomer != null && lead.fidiumCustomer.salesRep != null && (user.userType === 'SuperAdmin' || user.userType === "SalesOrgAdmin")) {
            return " | " + lead.fidiumCustomer.salesRep.firstName + " " + lead.fidiumCustomer.salesRep.lastName[0];
        }
        else if(lead?.offer?.salesRep){
            return " | " + lead.offer.salesRep.firstName + " " + lead.offer.salesRep.lastName[0];
        }

    }

    const getAddress = (lead) => {
        if (lead.fiberHouse?.mapString) {
            return lead.fiberHouse?.mapString;
        }
        else if (lead.fiberHouse?.address) {
            return lead.fiberHouse?.address?.street + " " + lead.fiberHouse?.address?.city + ", " + lead.fiberHouse?.address?.state + " " + lead.fiberHouse?.address?.zip + ", " + lead.fiberHouse?.address?.country;
        }
        else {
            return "No Address";
        }
    }
    const getPhone = (lead) => {
        if (lead.fidiumCustomer?.cellPhone != null) {
            return lead.fidiumCustomer.cellPhone;
        }
        else if (lead.phone != null) {
            return lead.phone;
        }
        else {
            return <FaPhoneSlash color='red' size={20} />
        }
    }

    // const lightningMessage = async (lead) => {
    //     const res = await contactButtons.lightningMessageLead(lead);
    //     if (res != null) {
    //         console.log("Conversation", res)
    //         navigate('/chat', {
    //             state: { conversation: res }
    //         })
    //     }

    // }

    useEffect(
        () => {
            setPageNumber(1)
            const timer = setTimeout(() => {
                filterSearch();
            }, 1000)

            return () => {
                clearTimeout(timer)
            }

        }, [filterBy, pageSize, orderType, fiberCompanies, includeInvalidCustomers, search, customerStatus, orgIds, salesRepIds, lastUpdatedFrom, userWithName, orgWithName, lastUpdatedTo, dateType, mapIconIds]
    )


    const getInstallDateTime = (lead) => {
        let data = "Install : ";
        if (lead.fidiumCustomer != null && lead.fidiumCustomer.installDateTime != null) {
            const installDateTime = new Date(lead.fidiumCustomer.installDateTime);
            const installDate = installDateTime.toLocaleDateString();
            const installTime = installDateTime.toLocaleTimeString(
                "en-US",
                {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                }
            );
            data += installDate;
            if (installTime !== "12:00 AM" && installTime !== "00:00") {
                data += " | " + installTime;
            }
        }
        if (lead.fidiumCustomer != null && lead.fidiumCustomer.originalIntstallDateTimeTo) {
            let timeTo = lead.fidiumCustomer.updatedInstallTimeTo ? lead.fidiumCustomer.updatedInstallTimeTo : lead.fidiumCustomer.originalIntstallDateTimeTo;
            const installDateTimeTo = new Date(timeTo);
            const installTimeTo = installDateTimeTo.toLocaleTimeString(
                "en-US",
                {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                }
            );
            if (installTimeTo !== "12:00:00 AM" && installTimeTo !== "00:00:00") {
                data += "-" + installTimeTo;
            }
        }
        return data;
    }

    const getSoldDateTime = (lead) => {
        let data = "Sold : ";
        if (lead.fidiumCustomer != null && lead.fidiumCustomer.timeStamp != null) {
            const soldDateTime = new Date(lead.fidiumCustomer.timeStamp);
            const soldDate = soldDateTime.toLocaleDateString();
            const soldTime = soldDateTime.toLocaleTimeString(
                "en-US",
                {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                }
            );
            data += soldDate;
            if (soldTime !== "12:00 AM" && soldTime !== "00:00") {
                data += " | " + soldTime;
            }
        }
        return data;
    }


    const getAppointmentDateTime = (lead) => {
        let data = "Appt : ";
        if (lead.fidiumCustomer != null && lead.fidiumCustomer.appointmentTime != null) {
            const appointmentDateTime = new Date(lead.fidiumCustomer.appointmentTime);
            const appointmentDate = appointmentDateTime.toLocaleDateString();
            const appointmentTime = appointmentDateTime.toLocaleTimeString(
                "en-US",
                {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                }
            );
            data += appointmentDate;
            if (appointmentTime !== "12:00 AM" && appointmentTime !== "00:00") {
                data += " | " + appointmentTime;
            }
        }
        return data;
    }

    function getPlanSpeed(plan) {
        if (plan.speed < 1) {
            return plan.speed * 1000 + " Mbps"
        } else {
            return plan.speed + " Gig"
        }
    }

    const getCustomerLine = (lead) => {
        let str = "";
        if (lead.fidiumCustomer != null) {
            if (lead.fidiumCustomer.orderNumber != null) {
                str += "✔ Verified Customer";
            }
            else {
                str += "✔ Customer";
            }
        }
        if (lead.isProspect) {
            if (lead?.offer?.accepted) {
                if (str !== "") {
                    str += " | Offer Accepted"
                }
                else if (lead.fidiumCustomer == null) {
                    str = "⚠ Requested Customer"
                    if (lead?.offer?.fiberPlan) {
                        str += " | " + getPlanSpeed(lead?.offer?.fiberPlan)
                    }
                }
            }
            else if (str !== "") {
                str += " | Offer Sent"
            }
            else {
                str += "✔ Offer Sent"
            }
        }
        return str;
    }

    const resetAllFilters = () => {
        setSearch('');
        setFilterBy('Customer');
        setCustomerStatus('Sold');
        setLastUpdatedFrom('');
        setLastUpdatedTo('');
        setSalesRepIds([]);
        setOrgIds([]);
        setOrgWithName([]);
    }

    const markCustomerAsInvalid = async (lead) => {

        const opt = await Swal.fire({
            icon: 'warning',
            title: 'Mark Customer As Invalid',
            text: 'Are you sure you want to mark this customer as invalid?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            showLoaderOnConfirm: true,

        })

        if (!opt.isConfirmed) {
            return;
        }
        Swal.fire({
            title: 'Please Wait',
            text: 'Marking Customer As Invalid',
            icon: "info",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading()
            }
        })

        const req = new StringIntReq();
        req.int = lead.fidiumCustomer.id;
        const res = await CustomerApi.MarkCustomerAsInvalid(req);
        if (res?.status?.success) {

            setLeads((prev) => {
                if (!includeInvalidCustomers)
                    return prev.filter(l => l.id !== lead.id)
                else {
                    return prev.map(l => {
                        if (l.id === lead.id) {
                            l.fidiumCustomer.isInvalid = true;
                        }
                        return l;
                    })
                }
            })

            Swal.fire({
                icon: 'success',
                title: 'Customer Marked As Invalid',
                text: 'The customer has been marked as invalid',
                onOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res.status.message
            })
        }
    }

    const markCustomerAsValid = async (lead) => {

        const opt = await Swal.fire({
            icon: 'warning',
            title: 'Mark Customer As Valid',
            text: 'Are you sure you want to mark this customer as valid?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            showLoaderOnConfirm: true,

        })

        if (!opt.isConfirmed) {
            return;
        }
        Swal.fire({
            title: 'Please Wait',
            icon: "info",
            text: 'Marking Customer As Valid',
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading()
            }
        })

        const req = new StringIntReq();
        req.int = lead.fidiumCustomer.id;
        const res = await CustomerApi.MarkCustomerAsValid(req);
        if (res?.status?.success) {

            setLeads((prev) => {
                return prev.map(l => {
                    if (l.id === lead.id) {
                        l.fidiumCustomer.isInvalid = false;
                    }
                    return l;
                })
            })

            Swal.fire({
                icon: 'success',
                title: 'Customer Marked As Valid',
                text: 'The customer has been marked as valid',
                onOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res.status.message
            })
        }
    }

    return (
        <div className="Leads">
            <div className="SearchFilters">

                {source && source === "Calendar" &&
                    <button className='reset_all_fitlers' style={{ backgroundColor: '#2761CF', marginBottom: '0.5rem' }} onClick={() => {
                        navigate(-1)
                    }}>Back</button>
                }
                <form onSubmit={(e) => { e.preventDefault(); }} className='search-bar'>
                    <input value={search} onChange={(e) => { setSearch(e.target.value) }} type="text" placeholder="Search" />
                    <FaSearch color='#2761CF' size={30} />
                </form>
                <button className='reset_all_fitlers' onClick={() => {
                    resetAllFilters();
                }}>Reset</button>
                <div className='selectors'>
                    <div className='selector_con'>
                        <label>Lead Type</label>
                        <select onChange={(e) => {
                            setFilterBy(e.target.value)
                            if (e.target.value === "KnockedDoors") {
                                setDateType("KnockedDate")
                            }
                        }} className='selector' value={filterBy}>
                            <option value="Customer">Customers</option>
                            <option value="Prospect">Prospects</option>
                            <option value="HotLead">Hot Leads</option>
                            <option value="KnockedDoors">Knocked Doors</option>
                            <option value="RequestedCustomer" >Requested Customers</option>
                            <option value="All" >All</option>
                        </select>
                    </div>
                    {
                        filterBy === 'Customer' &&
                        <div className='selector_con'>
                            <label>Status</label>
                            <select value={customerStatus} onChange={(e) => {
                                setCustomerStatus(e.target.value)
                                if (e.target.value === "PendingInstalls" || e.target.value === "Completed") {
                                    setDateType("InstallDate")
                                }
                                // setLastUpdatedFrom('')
                                // setLastUpdatedTo('')
                            }} className='selector'>
                                <option value="Sold">Sold</option>
                                <option value="SoldOnly">Sold (Not Scheduled)</option>
                                <option value="VerifiedSold">Verified Sold</option>
                                <option value="PendingInstalls" >Pending Installs</option>
                                <option value="Completed">Completed</option>
                                <option value="PendingCancels">Pending Cancels</option>
                                <option value="Canceled">Canceled</option>
                                <option value="PendingReschedules">Pending Reschedules</option>
                                <option value="Referral">Referrals</option>
                                <option value="Reviewed">Reviewed</option>
                            </select>
                        </div>
                    }
                    {
                        filterBy === "Prospect" &&
                        <div className='selector_con'>
                            <label>Status</label>
                            <select value={customerStatus} onChange={(e) => {
                                setCustomerStatus(e.target.value)
                            }} className='selector'>
                                <option value="Prospect">All</option>
                                <option value="OffersSent">Offers Sent</option>
                                <option value="OffersAccepted">Offers Accepted</option>
                            </select>
                        </div>
                    }
                    {/* <div className='selector_con'>
                        <label>Verified</label>
                        <select onChange={(e) => {
                            setIsVerified(e.target.value)
                        }} className='selector' value={isVerified}>
                            <option value={true}>True</option>
                            <option value={false}>false</option>
                        </select>
                    </div> */}
                    <FiberCompanySelector value={fiberCompanyIds} companiesWithOfferTemplateOnly={true} className={'single'} placeholder='Select Fiber Company' multiple={true} setCompanyObject={setFiberCompaniesWithName} setUser={setFiberCompanies} />
                    {
                        user.userType === 'SuperAdmin' &&
                        <div className='single'>
                            <div className='select_deselect mb-2'>
                                <button className='select' onClick={() => {
                                    setOrgWithName([
                                        {
                                            value: 0
                                            , label: "All", id: 0
                                        }
                                    ])
                                }}>Select All</button>
                                <button className='deselect' onClick={() => {
                                    setOrgWithName([])
                                }}>Deselect All</button>
                            </div>
                            <OrganizationSelector allOption={true} orgWithName={orgWithName} setOrgWithName={setOrgWithName} multiple={true} setUser={setOrgIds} placeholder='Select Organizations' />
                        </div>
                    }
                    {
                        (user.userType === 'SuperAdmin' || user.userType === "SalesOrgAdmin") &&
                        <div className='single'>
                            <UserSelector salesOrgIds={orgIds} includeYourself={true} SalesOrgId={user.userType === "SalesOrgAdmin" ? user.salesOrgId : null} setUser={setSalesRepIds} userWithName={userWithName} setUserWithName={setUserWithName} conversationType={"Group"} placeholder='Select SalesReps' userTypes={["SalesRep", "SalesOrgAdmin"]} />
                        </div>
                    }

                    {
                        filterBy === 'All' &&
                        <>
                            <div style={{
                                marginTop: "1rem",
                                // gap: "0.5rem",
                                gridColumn: "span 2",
                            }} className='select_deselect'>
                                <button className='select' onClick={() => {
                                    setMapIconIds([])
                                }}>Select All</button>
                                <button className='deselect' onClick={() => {
                                    setMapIconIds(
                                        mapIcons.map((mapIcon) => mapIcon.id)
                                    )
                                }}>Deselect All</button>
                            </div>
                            <div className='mapicon_con'>
                                {
                                    mapIcons.length > 0 &&
                                    mapIcons.map((mapIcon, index) => {
                                        return (
                                            <div key={index} className={`map_icon ${!mapIconIds?.includes(mapIcon.id) ? 'active' : ''}`} onClick={() => {
                                                if (mapIconIds?.includes(mapIcon.id)) {
                                                    setMapIconIds((prev) => prev.filter(id => id !== mapIcon.id))
                                                }
                                                else {
                                                    setMapIconIds((prev) => [...prev, mapIcon.id])
                                                }
                                            }}>
                                                <img src={"data:image/png;base64," + mapIcon.image} alt='Map Icon' />
                                            </div>
                                        )
                                    }
                                    )
                                }
                            </div>
                        </>}
                    <div className='selector_con'>
                        <label>Date Type</label>
                        <select value={dateType} onChange={(e) => {
                            setDateType(e.target.value)
                        }} className='selector'>
                            <option value="LastUpdated">Last Updated</option>
                            <option value="InstallDate">Install Date</option>
                            <option value={"DateSold"} >Date Sold</option>
                            <option value="AppointmentDate">Appointment Date</option>
                            <option value="CancelRequestTime" >Cancel Request Time</option>
                            <option value="RescheduleRequestTime" >Reschedule Request Time</option>
                            <option value="KnockedDate" >Knocked Date</option>
                            <option value="OfferSentTime">Offer Sent Date</option>
                        </select>
                    </div>
                    <QuickDate quickDateValue={quickDateValue2} selectQuickDate={quickDateValue} setQuickDateValue={setQuickDateValue2} from={lastUpdatedFrom} to={lastUpdatedTo} setFrom={setLastUpdatedFrom} setTo={setLastUpdatedTo} />
                    {
                        user.userType === 'SuperAdmin' &&
                        <>
                            <div className='selector_con col-span-2'>
                                <label>Include Invalid Customers</label>
                                <div className="toggle-switch">
                                    {/* <label>{"Viewing " + filterBy}</label> */}
                                    <input
                                        type="checkbox"
                                        checked={includeInvalidCustomers}
                                        onChange={() => { setIncludeInvalidCustomers(!includeInvalidCustomers) }}
                                    />
                                </div>
                            </div>
                            <div className='selector_con col-span-2'>
                                <label>Order Type</label>
                                <select value={orderType} onChange={(e) => {
                                    setOrderType(e.target.value)
                                }} className='selector'>
                                    <option value="All">All</option>
                                    <option value="Verified">Verified</option>
                                    <option value="Unverified">Unverified</option>
                                </select>
                            </div>
                        </>
                    }



                </div>

            </div>
            <div className="LeadsList">

                {isLoaded && <div className='leads_top_button'>
                    <h3 >RESULTS ({totalItems})</h3>

                    <div className='page_size_container'>
                        <p>Page Size</p>
                        <select value={pageSize} onChange={(e) => { setPageSize(e.target.value) }} >
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="2000">2000</option>
                            <option value="5000">5000</option>
                        </select>
                    </div>

                    <button className='filter_btn export_button' onClick={(e) => {
                        setAnchorEl(e.currentTarget)
                    }}>Export | <MdExpandMore color='white' size={20} /></button>
                </div>}
                {
                    isLoaded &&
                    <div style={{
                        marginTop: "1rem",
                        // gap: "0.5rem"
                    }} className='select_deselect'>
                        <button className='select' onClick={() => {
                            setSelectedLeads(leads.map(l => l.id))
                            document.querySelectorAll('.select').forEach((checkbox) => {
                                checkbox.checked = true
                            })
                        }}>Select All</button>
                        <button className='deselect' onClick={() => {
                            setSelectedLeads([])
                            document.querySelectorAll('.select').forEach((checkbox) => {
                                checkbox.checked = false
                            })
                        }}>Deselect All</button>
                    </div>
                }
                <ContextMenu anchorEl={anchorEl} handleClose={() => {
                    setAnchorEl(null)
                }} setAnchorEl={setAnchorEl} menuItems={[
                    {
                        title: "Export to XLSX",
                        onClick: () => {
                            exportData.ExportToXLSX('Leads.xlsx', leads, selectedLeads)
                        }
                    },
                    {
                        title: "Export to CSV",
                        onClick: () => {
                            exportData.ExportToCsv('Leads.csv', leads, selectedLeads)
                        }
                    }
                ]} />

                <DigitalToolBelt open={openToolBelt} setOpen={setOpenToolBelt} lead={selectedLead} />
                <CustomerInfo notes={selectedLead?.notes} customer={selectedLead?.fidiumCustomer} open={openCustomerInfo} setOpen={setOpenCustomerInfo} />
                {
                    leads != null &&
                    leads?.map(
                        (lead, index) => {
                            return (
                                <React.Fragment key={index} >
                                    <div key={index} ref={leads.length - 1 === index ? lastCard : null} className="LeadCard">

                                        <div className='ImagesDiv'>
                                            <input className='select' onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSelectedLeads(
                                                        (prev) => {
                                                            return [...prev, lead.id]
                                                        }
                                                    )
                                                }
                                                else {
                                                    setSelectedLeads(
                                                        (prev) => prev.filter(l => l !== lead.id)
                                                    )
                                                }
                                            }} type='checkbox' />
                                            <div className='Image'>
                                                {
                                                    getImage(lead)
                                                }
                                            </div>
                                            <div className='MapIcon'>
                                                {
                                                    getMapIcon(lead)
                                                }
                                            </div>
                                        </div>
                                        <div className='DataDiv'>
                                            <div className='Name' style={{ cursor: "pointer" }} onClick={() => { openMap(lead) }}>
                                                <h3>{getName(lead)} <span style={{
                                                    fontSize: "0.8rem",
                                                    color: "#2761D0",

                                                }}>{getSalesRepName(lead)}</span></h3>
                                            </div>
                                            <div className='Address' style={{ cursor: "pointer" }} onClick={() => { openMap(lead) }}>
                                                <p title={getAddress(lead)} >{getAddress(lead)}</p>
                                            </div>
                                            <div className='Phone'>
                                                <p>{getPhone(lead)}</p>
                                            </div>
                                            <div className='additional_info'>
                                                {
                                                    (lead.fidiumCustomer !== null || lead.isProspect) &&
                                                    <p>{getCustomerLine(lead)}</p>
                                                }
                                                {
                                                    lead.fidiumCustomer !== null && lead.fidiumCustomer.appointmentTime != null &&
                                                    <p>{getAppointmentDateTime(lead)}</p>
                                                }
                                                {
                                                    lead.fidiumCustomer !== null && lead.fidiumCustomer.timeStamp != null &&
                                                    <p>{getSoldDateTime(lead)}</p>
                                                }
                                                {
                                                    lead.fidiumCustomer !== null && lead.fidiumCustomer.installDateTime != null &&
                                                    <p>{getInstallDateTime(lead)}</p>
                                                }
                                            </div>
                                        </div>
                                        <div className='dbt_1' style={{
                                            // display:"flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "0.5rem",
                                            flexDirection: "column"
                                        }}>

                                            <div className='dbt_1' onClick={() => {
                                                setSelectedLead(lead); setOpenToolBelt(true)
                                            }
                                            } style={{
                                                // display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: "2rem",
                                                height: "2rem",

                                            }}>
                                                <img src={'/images/digital_tool_belt/digital_belt.png'} alt='Digital Tool Belt' />
                                            </div>
                                            {
                                                lead.fidiumCustomer != null &&
                                                <div className='dbt_1' style={{
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: "2rem",
                                                    height: "2rem",
                                                }} onClick={() => { setSelectedLead(lead); setOpenCustomerInfo(true) }}>
                                                    <img src={'/images/digital_tool_belt/customerInfo.svg'} alt="info" />
                                                </div>
                                            }
                                        </div>

                                        <div className="MainLeadCard_Buttons">
                                            {/* <div className="MainLeadCard_Button" onClick={() => { lightningMessage(lead) }}>
                                            <TbMessage2Bolt color='#81848A' size={30} />
                                        </div> */}
                                            {
                                                lead.fidiumCustomer != null &&
                                                <div className='dbt_2' style={{
                                                    width: "2rem",
                                                    height: "2rem",
                                                    cursor: 'pointer'
                                                }} onClick={() => { setSelectedLead(lead); setOpenCustomerInfo(true) }}>
                                                    <img src={'/images/digital_tool_belt/customerInfo.svg'} alt="info" />
                                                </div>
                                            }
                                            <div className="dbt_2" style={{
                                                width: "2rem",
                                                height: "2rem",
                                                cursor: 'pointer'
                                            }} onClick={() => { setSelectedLead(lead); setOpenToolBelt(true) }} >
                                                <img src={'/images/digital_tool_belt/digital_belt.png'} alt='Digital Tool Belt' />
                                            </div>
                                            <div className="MainLeadCard_Button" onClick={() => contactButtons.emailLead(lead)}>
                                                <MdOutlineMail color='#81848A' size={30} />
                                            </div>
                                            <div className="MainLeadCard_Button" onClick={() => contactButtons.whatsappLead(lead)}>
                                                <FaWhatsapp color='#81848A' size={30} />
                                            </div>
                                            <div className="MainLeadCard_Button" onClick={() => contactButtons.smsLead(lead)}>
                                                <MdOutlineMessage color='#81848A' size={30} />

                                            </div>
                                            <div className="MainLeadCard_Button" onClick={() => contactButtons.callLead(lead)}>
                                                <MdOutlinePhone color='#81848A' size={30} />

                                            </div>
                                            <div className="MainLeadCard_Button" onClick={() => contactButtons.directionsLead(lead)}>
                                                <MdOutlineDirections color='#81848A' size={30} />
                                            </div>
                                            <div onClick={() => { openMap(lead) }} className='MainLeadCard_Button'>
                                                <IoMapOutline color='#81848A' size={30} />
                                            </div>
                                            {
                                                user.userType === 'SuperAdmin' && lead.fidiumCustomer != null &&
                                                (
                                                    lead.fidiumCustomer.isInvalid === true ?
                                                        <div onClick={() => {
                                                            markCustomerAsValid(lead)
                                                        }} className='MainLeadCard_Button'>
                                                            <IoIosAddCircle color='#81848A' size={30} />
                                                        </div>
                                                        :
                                                        <div onClick={() => {
                                                            markCustomerAsInvalid(lead)
                                                        }} className='MainLeadCard_Button'>
                                                            <IoIosRemoveCircle color='#81848A' size={30} />
                                                        </div>
                                                )

                                            }
                                        </div>

                                    </div>
                                </React.Fragment>
                            )
                        }
                    )
                }

                {
                    !isLoaded &&
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%'
                    }}>
                        <CircularProgress size={40} sx={{
                            color: '#2761CF',
                        }} />
                    </div>
                }

            </div>

        </div>
    )
}

export default Leads