import React, { useEffect, useState } from 'react';
import Layout from '../../../Layouts/Layout';
import './ManageAnnouncements.scss';
import Datatable from '../../../components/Datatable';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import { PaginationItem } from '@mui/material';
import { BiRightArrow, BiLeftArrow, BiEdit, BiPlus } from 'react-icons/bi';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal';
import UsersApi from '../../../API/UsersApi';
import StringIntReq from '../../../Requests/StringIntReq';
import AnnouncementReq from '../../../Requests/Users/AnnouncementReq';

const ManageAnnouncements = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [editingAnnouncement, setEditingAnnouncement] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [newAnnouncement, setNewAnnouncement] = useState(new AnnouncementReq());

    const getAnnouncements = async () => {
        setLoading(true);
        let req = new StringIntReq();
        if (search) {
            req.string = search;
        }
        req.pagingParams.pageNumber = currentPage;
        req.pagingParams.pageSize = 10;
        const res = await UsersApi.GetAllAnnouncements(req);
        if (res?.status?.success) {
            setAnnouncements(res?.data.list);
            setTotalPages(res?.data.totalPages);
            setLoading(false);
        } else {
            console.log(res?.status?.message);
        }
    };

    useEffect(() => {
        getAnnouncements();
    }, [currentPage]);

    const handleEdit = (announcement) => {
        console.log(announcement);
        setEditingAnnouncement(announcement);
        setIsEditModalOpen(true);
    };

    const saveAnnouncement = async () => {
        console.log(editingAnnouncement);
        if (!editingAnnouncement?.id || !editingAnnouncement?.message || !editingAnnouncement?.announcementType) {
            Swal.fire('Error', 'All fields are required!', 'error');
            return;
        }
        Swal.fire({
            icon: "info",
            title: "Updating Announcement",
            text: "Please wait",
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        let req = new AnnouncementReq();
        req.id = editingAnnouncement.id;
        req.message = editingAnnouncement.message;
        req.announcementType = editingAnnouncement.announcementType;
        req.startDate = editingAnnouncement.startDate;
        req.endDate = editingAnnouncement.endDate;
        req.isHidden = editingAnnouncement.isHidden;


        try {
            const res = await UsersApi.EditAnnouncement(req);

            if (res?.status?.success) {
                setIsEditModalOpen(false);
                getAnnouncements();
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Announcement updated successfully",
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
            } else {
                Swal.fire('Error', res?.status?.message, 'error');
            }
        } catch (error) {
            console.error(error);
            Swal.fire('Error', 'Failed to save the announcement. Try again.', 'error');
        }
    };

    const addAnnouncement = async () => {
        if (!newAnnouncement?.message || !newAnnouncement?.announcementType || !newAnnouncement?.startDate || !newAnnouncement?.endDate) {
            Swal.fire('Error', 'All fields are required!', 'error');
            return;
        }
        Swal.fire({
            icon: "info",
            title: "Adding Announcement",
            text: "Please wait",
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const res = await UsersApi.AddAnnouncement({
                message: newAnnouncement.message,
                announcementType: newAnnouncement.announcementType,
                startDate: newAnnouncement.startDate,
                endDate: newAnnouncement.endDate,
            });

            if (res?.status?.success) {
                setIsAddModalOpen(false);
                getAnnouncements();
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Announcement added successfully",
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
            } else {
                Swal.fire('Error', res?.status?.message, 'error');
            }
        } catch (error) {
            console.error(error);
            Swal.fire('Error', 'Failed to add the announcement. Try again.', 'error');
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            getAnnouncements();
        }
    };

    const headers = [
        { key: 'id', name: 'ID' },
        { key: 'message', name: 'Message' },
        { key: 'announcementType', name: 'Type' },
        { key: 'startDate', name: 'Start Date' },
        { key: 'endDate', name: 'End Date' },
        {
            key: 'action',
            name: 'Action',
            render: (row) => (
                <button className="edit-btn" onClick={() => handleEdit(row)}>
                    <BiEdit />
                </button>
            ),
        },
    ];

    return (
        <Layout>
            <div className="manage-announcements-container">

                <div className="header">
                    <h1>Manage Announcements</h1>
                    <div className="search-filters">
                        <button onClick={() => setIsAddModalOpen(true)}>
                            + Add Announcement
                        </button>
                        <input
                            type="text"
                            placeholder="Search"
                            value={search}
                            onKeyDown={handleKeyPress}
                            onChange={(e) => setSearch(e.target.value)}
                        />

                        <button onClick={getAnnouncements}>Search</button>
                    </div>

                </div>

                {loading ? (
                    <div className="spinner">
                        <CircularProgress />
                    </div>
                ) : (
                    <Datatable headers={headers} list={announcements} />
                )}

                <Pagination
                    count={totalPages}
                    onChange={(e, pageNo) => setCurrentPage(pageNo)}
                    renderItem={(item) => (
                        <PaginationItem
                            components={{
                                next: (props) => <button {...props}><BiRightArrow /></button>,
                                previous: (props) => <button {...props}><BiLeftArrow /></button>,
                            }}
                            {...item}
                        />
                    )}
                />

                {/* Edit Modal */}
                <Modal
                    open={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& .modal-content': {
                            backgroundColor: '#fff',
                            padding: '30px',
                            borderRadius: '10px',
                            width: '400px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            zIndex: 9999,
                        },
                        '& .modal-content h3': {
                            marginBottom: '20px',
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#181E4B',
                        },
                        '& .modal-content input': {
                            width: '100%',
                            padding: '10px',
                            marginBottom: '15px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            fontSize: '0.95rem',
                            '&:focus': {
                                borderColor: '#181E4B',
                            },
                        },
                        '& .modal-content textarea': {
                            width: '100%',
                            padding: '10px',
                            marginBottom: '15px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            fontSize: '0.95rem',
                            '&:focus': {
                                borderColor: '#181E4B',
                            },
                        },
                        '& .modal-content button': {
                            width: '100%',
                            padding: '10px',
                            backgroundColor: '#181E4B',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            fontSize: '1rem',
                            '&:hover': {
                                backgroundColor: '#000E48',
                            },
                        },
                        '& .checkbox-label': {
                            display: 'flex',
                            alignItems: 'center',
                            // Center vertically
                            marginTop: '10px',
                            fontSize: '1rem',
                            cursor: 'pointer',
                        },
                        '& .modal-content input[type="checkbox"]': {
                            width: '20px',
                            height: '20px',
                            marginRight: '10px',
                            cursor: 'pointer',
                            appearance: 'none',
                            border: '2px solid #181E4B',
                            borderRadius: '5px',
                            position: 'relative',
                            outline: 'none',
                            backgroundColor: 'white',
                            marginTop: '10px',
                            transition: 'background-color 0.3s ease',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:checked': {
                                backgroundColor: '#181E4B',
                            },
                            '&:checked::after': {
                                content: '"✔"',
                                color: '#fff',
                                position: 'absolute',
                                fontSize: '1rem',
                            },
                        },
                        '& .checkbox-label span': {
                            lineHeight: '20px',
                        }
                    }
                    }
                >
                    <div className="modal-content">
                        <h3>Edit Announcement</h3>
                        <label>Announcement Type</label>
                        <input
                            type="text"
                            disabled={true}
                            placeholder="Type"
                            value={editingAnnouncement?.announcementType}
                        />
                        <label>Announcement Message</label>
                        <textarea
                            type="textarea"
                            rows="5"
                            placeholder="Message"
                            value={editingAnnouncement?.message}
                            onChange={(e) =>
                                setEditingAnnouncement({ ...editingAnnouncement, message: e.target.value })
                            }
                        ></textarea>
                        <label>Start Date</label>
                        <input
                            type="date"
                            defaultValue={
                                editingAnnouncement?.startDate
                                    ? new Date(editingAnnouncement.startDate).toISOString().substring(0, 10)
                                    : ''
                            }
                            onChange={(e) =>
                                setEditingAnnouncement({
                                    ...editingAnnouncement,
                                    startDate: e.target.value,
                                })
                            }
                        />
                        <label>End Date</label>
                        <input
                            type="date"
                            defaultValue={
                                editingAnnouncement?.endDate
                                    ? new Date(editingAnnouncement.endDate).toISOString().substring(0, 10)
                                    : ''
                            }
                            onChange={(e) =>
                                setEditingAnnouncement({
                                    ...editingAnnouncement,
                                    endDate: e.target.value,
                                })
                            }
                        />
                        <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '10px' }}>


                            <input
                                type="checkbox"
                                checked={editingAnnouncement?.isHidden || false}
                                onChange={(e) =>
                                    setEditingAnnouncement({
                                        ...editingAnnouncement,
                                        isHidden: e.target.checked,
                                    })
                                }
                            />
                            <label className="checkbox-label">Hide Announcement</label>

                        </div>


                        <button onClick={saveAnnouncement}>Save</button>
                    </div>
                </Modal>

                {/* Add Modal */}
                <Modal
                    open={isAddModalOpen}
                    onClose={() => setIsAddModalOpen(false)}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& .modal-content': {
                            backgroundColor: '#fff',
                            padding: '30px',
                            borderRadius: '10px',
                            width: '400px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            zIndex: 9999,
                        },
                        '& .modal-content h3': {
                            marginBottom: '20px',
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#181E4B',
                        },
                        '& .modal-content input': {
                            width: '100%',
                            padding: '10px',
                            marginBottom: '15px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            fontSize: '0.95rem',
                            '&:focus': {
                                borderColor: '#181E4B',
                            },
                        },
                        '& .modal-content select': {
                            width: '100%',
                            padding: '10px',
                            marginBottom: '15px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            fontSize: '0.95rem',
                            '&:focus': {
                                borderColor: '#181E4B',
                            }
                        },
                        '& .modal-content textarea': {
                            width: '100%',
                            padding: '10px',
                            marginBottom: '15px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            fontSize: '0.95rem',
                            '&:focus': {
                                borderColor: '#181E4B',
                            },
                        },
                        '& .modal-content button': {
                            width: '100%',
                            padding: '10px',
                            backgroundColor: '#181E4B',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            fontSize: '1rem',
                            '&:hover': {
                                backgroundColor: '#000E48',
                            },
                        },
                        '& .checkbox-label': {
                            display: 'flex',
                            alignItems: 'center',
                            // Center vertically
                            marginTop: '10px',
                            fontSize: '1rem',
                            cursor: 'pointer',
                        },
                        '& .modal-content input[type="checkbox"]': {
                            width: '20px',
                            height: '20px',
                            marginRight: '10px',
                            cursor: 'pointer',
                            appearance: 'none',
                            border: '2px solid #181E4B',
                            borderRadius: '5px',
                            position: 'relative',
                            outline: 'none',
                            backgroundColor: 'white',
                            transition: 'background-color 0.3s ease',
                            display: 'inline-flex',
                            marginTop: '10px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:checked': {
                                backgroundColor: '#181E4B',
                            },
                            '&:checked::after': {
                                content: '"✔"',
                                color: '#fff',
                                position: 'absolute',
                                fontSize: '1rem',
                            },
                        },
                        '& .checkbox-label span': {
                            lineHeight: '20px',
                        }
                    }}
                >
                    <div className="modal-content">
                        <h3>Add Announcement</h3>
                        <select
                            value={newAnnouncement.announcementType}
                            onChange={(e) =>
                                setNewAnnouncement({ ...newAnnouncement, announcementType: e.target.value })
                            }
                        >
                            <option value="">Select Type</option>
                            <option value="Bug Fix">Bug Fix</option>
                            <option value="Feature">Feature</option>
                            <option value="Announcement">Announcement</option>
                            <option value="Update">Update</option>
                        </select>
                        <textarea
                            type="textarea"
                            rows="5"
                            placeholder="Message"
                            value={newAnnouncement.message}
                            onChange={(e) =>
                                setNewAnnouncement({ ...newAnnouncement, message: e.target.value })
                            }
                        ></textarea>
                        <label>Start Date</label>
                        <input
                            type="date"
                            value={newAnnouncement.startDate}
                            onChange={(e) =>
                                setNewAnnouncement({ ...newAnnouncement, startDate: e.target.value })
                            }
                        />
                        <label>End Date</label>
                        <input
                            type="date"
                            value={newAnnouncement.endDate}
                            onChange={(e) =>
                                setNewAnnouncement({ ...newAnnouncement, endDate: e.target.value })
                            }
                        />
                        <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '10px' }}>


                            <input
                                type="checkbox"
                                checked={newAnnouncement?.isHidden || false}
                                onChange={(e) =>
                                    setNewAnnouncement({
                                        ...newAnnouncement,
                                        isHidden: e.target.checked,
                                    })
                                }
                            />
                            <label className="checkbox-label">Hide Announcement</label>

                        </div>
                        <button onClick={addAnnouncement}>Add</button>
                    </div>
                </Modal>
            </div>
        </Layout >
    );
};

export default ManageAnnouncements;
