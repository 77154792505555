/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { PaginationItem } from '@mui/material';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';
import { MdExpandLess, MdExpandMore } from "react-icons/md";
// import Swal from 'sweetalert2';
import CustomerApi from '../../../API/CustomerApi';
import SearchCustomerReq from '../../../Requests/Customer/SearchCustomerReq';
import Datatable from '../../../components/Datatable';
import Creatable from 'react-select/creatable';
import './ManageIssueTypes.scss';
import Layout from '../../../Layouts/Layout';
import Swal from 'sweetalert2';
import { TbMapBolt } from 'react-icons/tb';
import ContextMenu from '../../../components/ContextMenu';
import ExportData from '../../../Services/ExportData';

const IssuesPage = () => {
    const navigate = useNavigate();

    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [issues, setIssues] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dataExport, setDataExport] = useState([]);
    const exportData = new ExportData();
    const [accountStatuses, setAccountStatuses] = useState([]);
    const [issueTypes, setIssueTypes] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [totalIssues, setTotalIssues] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);

    const searchIssues = () => {
        setLoading(true);
        setHasSearched(true);
        let req = new SearchCustomerReq();
        req.pagingParams.pageNumber = currentPage;
        req.pagingParams.pageSize = pageSize;
        req.searchString = search;
        req.hasIssue = true;
        req.accountStatuses = accountStatuses.map(
            (item) => {
                return item.value
            }
        )
        req.issueTypes = issueTypes.map(
            (item) => { return item.value }
        );

        console.log(req);
        req.sortBy = 'Issues'
        CustomerApi.SearchFidiumCustomers(req).then(res => {
            console.log(res?.data?.list);
            const issuesData = res?.data?.list.map(issue => {
                const cancelationForms = issue.cancelationForms || [];
                const rescheduleForms = issue.rescheduleForms || [];
                const numOfIssues = cancelationForms.length + rescheduleForms.length;
                let latestTimeStamp = null;
                if(cancelationForms.length > 0) {
                    cancelationForms.forEach(form => {
                        if(!latestTimeStamp || form.timestamp > latestTimeStamp) {
                            latestTimeStamp = form.timestamp;
                        }
                    });
                }
                if(rescheduleForms.length > 0) {
                    rescheduleForms.forEach(form => {
                        if(!latestTimeStamp || form.timestamp > latestTimeStamp) {
                            latestTimeStamp = form.timestamp;
                        }
                    });
                }
                console.log(cancelationForms, rescheduleForms, latestTimeStamp, numOfIssues)
                
                return {
                    ...issue,
                    cancelationForms,
                    rescheduleForms,
                    numOfIssues,
                    timeStamp: latestTimeStamp ? new Date(latestTimeStamp).toLocaleString(
                        'en-US',
                        {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                        }
                    ) : "N/A",
                    map: <button onClick={() => openMap(issue.lead)}><TbMapBolt size={30} color='#2761D0' /></button>,
                    installDateTime: issue.installDateTime ? new Date(issue.installDateTime).toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    }) : "N/A",
                    originallInstallDateTimeFrom: issue.originallInstallDateTimeFrom ? new Date(issue.originallInstallDateTimeFrom).toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    }) : "N/A",
                    updatedInstallDateTimeFrom: issue.updatedInstallDateTimeFrom ? new Date(issue.updatedInstallDateTimeFrom).toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    }) : "N/A",
                };
            });

            // let sortedIssues = issuesData.sort((a, b) => {
            //     if (a.timeStamp < b.timeStamp) {
            //         return 1;
            //     }
            //     if (a.timeStamp > b.timeStamp) {
            //         return -1;
            //     }
            //     return 0;
            // });

            setIssues(issuesData);
            console.log(issuesData)
            setTotalIssues(res?.data?.totalItems);
            setTotalPages(res?.data?.totalPages);
            setLoading(false);
            setDataExport(
                issuesData.map(issue => ({
                    firstName: issue.firstName,
                    lastName: issue.lastName,
                    email: issue.email,
                    phoneNumber: issue.cellPhone,
                    cancelationForms: issue.cancelationForms.length > 0 
                        ? issue.cancelationForms.map(form => `ID: ${form.id}, BundledService: ${form.bundledService}, Answer: ${form.bundledServiceSubAnswer}`).join('; ') 
                        : "No Cancelation Forms",
                    rescheduleForms: issue.rescheduleForms.length > 0 
                        ? issue.rescheduleForms.map(form => `ID: ${form.id}, Old Date: ${form.oldInstallDate}, New Date: ${form.newInstallDate}`).join('; ') 
                        : "No Reschedule Forms",
                    numOfIssues: issue.numOfIssues,
                    latestTimeStamp: issue.latestTimeStamp ? new Date(issue.latestTimeStamp).toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    }) : "N/A",
                }))
            );
            

            console.log(dataExport)
        });
    };

    useEffect(() => {
        searchIssues();

    }, [currentPage, pageSize]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            searchIssues();
        }
    };

    const openMap = (lead) => {
        if (lead.fiberHouse && lead.fiberHouse.coordinates) {

            navigate('/map', {
                state: {
                    fiberHouse: {
                        ...lead.fiberHouse,
                        leadId: lead.id,
                        lead: {
                            ...lead,
                            fiberHouse: null
                        }
                    }
                }
            })
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'No Coordinates',
                text: 'This lead does not have any coordinates'
            })

        }

    }
    const renderForms = (forms, type) => {
        console.log(type);
        return forms.map((form, index) => {
            const route = type === 'Cancellation' ? `/issue-cancel-details/${form.id}` : `/issue-reschedule-details/${form.id}`;
            return (
                <span
                    key={form.id}
                    onClick={() => navigate(route)}
                    style={{ color: form.isResolved ? 'green' : 'red', cursor: 'pointer', marginBottom: '0.5rem' }}
                >
                    {type} {index + 1}
                </span>
            );
        });
    };

    const headers = [
        {
            name: "Time Stamp",
            key: "timeStamp",
        },
        { name: "First Name", key: "firstName" },
        { name: "Last Name", key: "lastName" },
        { name: "Current Issue Type", key: "issueType" },
        { name: "Current Account Status", key: "accountStatus" },
        { name:"Curent Install Date",key:"installDateTime"},
        { name: "Original Install Date",key:"originallInstallDateTimeFrom"},
        {name:"Updated Install Date",key:"updatedInstallDateTimeFrom"},
        { name: "# of Issues", key: "numOfIssues" },
        {
            name: "Map",
            key: "map",
        },
        {
            name: "Issues",
            key: "issues",
            render: (row) => (

                <td style={{ display: 'flex', flexDirection: 'column' }}>
                    {renderForms(row.cancelationForms, 'Cancellation')}
                    {renderForms(row.rescheduleForms, 'Reschedule')}
                </td>
            )
        }
    ];

    return (
        <Layout>
            <div className='SearchTableLayout ManageCustomersDiv'>
                <div className='SearchCard'>
                    <div className='SearchDiv'>
                        <h3 className='SearchTitle'>Issues Page</h3>
                        <div className='SearchInputs'>
                            <input
                                className='SearchInput search_input'
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={handleKeyPress}
                                placeholder='Search'
                            />
                            {expanded && (
                                <>
                                    <Creatable
                                        isMulti={true}
                                        onChange={setAccountStatuses}
                                        placeholder='Account Statuses'
                                    />
                                    <Creatable
                                        isMulti={true}
                                        onChange={setIssueTypes}
                                        placeholder='Issue Types'
                                    />
                                </>
                            )}
                            <div className='expand_icon'>
                                {expanded ? (
                                    <MdExpandLess size={30} className='ExpandIcon' onClick={() => setExpanded(false)} />
                                ) : (
                                    <MdExpandMore size={30} className='ExpandIcon' onClick={() => setExpanded(true)} />
                                )}
                            </div>
                        </div>
                        <button className='SearchButton' onClick={searchIssues}>Search</button>
                    </div>
                </div>
                {loading ? (
                    <div className='spinner'>
                        <CircularProgress sx={{ color: "var(--color-icon)" }} />
                    </div>
                ) : (
                    hasSearched && (
                        <>
                            <div className='TableDiv'>
                                <div className='top_button'>
                                    <button className='filter_btn export_button' onClick={(e) => {
                                    setAnchorEl(e.currentTarget)
                                }}>Export | <MdExpandMore color='white' size={20} /></button>
                                </div>

                                <Datatable headers={headers} list={issues} currentPage={currentPage} totalPages={totalPages} />
                                <div className='pagination'>
                                    <Pagination
                                        color='light'
                                        renderItem={(item) => (
                                            <PaginationItem
                                                components={{
                                                    next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                                                    previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                                                }}
                                                {...item}
                                                sx={{ color: "white" }}
                                            />
                                        )}
                                        count={totalPages}
                                        page={currentPage}
                                        onChange={(e, v) => setCurrentPage(v)}
                                    />
                                </div>
                            </div>
                            <ContextMenu anchorEl={anchorEl} handleClose={() => {
                                setAnchorEl(null)
                            }} setAnchorEl={setAnchorEl} menuItems={[
                                {
                                    title: "Export to XLSX",
                                    onClick: () => {
                                        exportData.ExportIssuesToXLSX('issueTypes.xlsx', dataExport)
                                    }
                                },
                                {
                                    title: "Export to CSV",
                                    onClick: () => {
                                        exportData.ExportIssuesToCsv('issueTypes.csv', dataExport)
                                    }
                                }
                            ]} />
                        </>
                    )
                )}
            </div>
        </Layout>
    );
};

export default IssuesPage;
