
import { HiOutlineCreditCard } from "react-icons/hi";
import { LuFolderCog } from "react-icons/lu";
import { MdDashboard, MdLeaderboard, MdOutlineAnalytics, MdOutlineBugReport, MdOutlineManageAccounts, MdOutlinePayments } from "react-icons/md";
import { PiMapTrifoldLight } from "react-icons/pi";
import { RiContactsBookLine } from "react-icons/ri";
import { TbMessageBolt, TbReportAnalytics } from "react-icons/tb";
import { TiUserAddOutline } from "react-icons/ti";
import { BiSolidOffer } from "react-icons/bi";
import { VscFeedback, VscSettings } from "react-icons/vsc";
import { GoOrganization } from "react-icons/go";
import { FaCalendarDays } from "react-icons/fa6";
import { TfiAnnouncement } from "react-icons/tfi";
const TeamAdminMenuItems = [
    {
        name: "Dashboard",
        key: "Index",
        route: "/",
        icon: (color,size=40) => <MdDashboard size={size} color={color} />
    },
    {
        name:"General Sales Report",
        key:"GeneralSalesReport",
        route:"/general-sales-reporting-page",
        icon: (color,size=40) => <TbReportAnalytics size={size} color={color} />
    },
    {
        name: "Announcements",
        key: "Announcements",
        route: "/announcements-board",
        icon: (color,size=40) => <TfiAnnouncement size={size} color={color} />,
    },
    {
        type: "collapse",
        name: "Contact center",
        key: 'ContactCenter', 
        route: '/contact-center', 
        icon:(color,size=40) =>  <RiContactsBookLine size={size} color={color} />, 
        noCollapse: true,
        // disabled:true
    },
    {
        name: "Map",
        key: "free-install-map",
        route: "/map",
        icon: (color,size=40) => <PiMapTrifoldLight size={size} color={color} />,
    },
    {
        name :"Leader Board",
        key:"LeaderBoard",
        route:"/leader-board",
        icon: (color,size=40) => <MdLeaderboard size={size} color={color} />,
        // disabled:true
    },
    {
        name: "Customer Feedback",
        key: "CustomerFeedbackAnalysis",
        route: "/customer-feedback-analysis",
        icon: (color,size=40) =>  <VscFeedback size={size} color={color} />,
    },
    {
        name: "Digital Business Center",
        key: "DigitalBusinessCenter",
        route: "/digital-business-center",
        icon: (color,size=40) => <HiOutlineCreditCard size={size} color={color} />,
    },
    // {
    //     name: "Comission Plan Maker",
    //     key: "ComissionPlanMaker",
    //     route: "/comission-plan-maker",
    //     icon: (color,size=40) => <GrPlan size={size} color={color} />,
    // },
    // {
    //     name: "Fiber Feedback",
    //     key: "fiber-feedback",
    //     route: "/fiber-feedback",
    //     icon: (color,size=40) => <VscFeedback size={size} color={color} />,
    // },
    // {
    //     name: "Order History",
    //     key: "order-history",
    //     route: "/order-history",
    //     icon: (color,size=40) => <GrDocumentTime size={size} color={color} />,
    // },
    {
        name: "Chat",
        key: 'privatechat', 
        route: '/chats', 
        icon: (color,size=40) => <TbMessageBolt size={size} color={color} />, 
        disabled : true
    },
    {
        name: "Training Modules",
        key: 'manageSellingCard', 
        route: '/manage-training-modules', 
        icon: (color,size=40) => <LuFolderCog size={size} color={color} />,  
        noCollapse: true,
        disabled : true
    },
    {
        name:"My Commissions",
        key:"MyCommissions",
        route:"/my-commissions",
        icon: (color,size=40) => <MdOutlinePayments size={size} color={color} />,
        disabled:true
    },
    {
        name: "Create Team Member",
        key: "create-team-member",
        route: "/team-member",
        icon: (color,size=40) => <TiUserAddOutline size={size} color={color} />,

    },
    {
        name: "Manage Team Members",
        key: "manage-team-members",
        route: "/manage-team-members",
        icon: (color,size=40) => <MdOutlineManageAccounts size={size} color={color} />,
        disabled:true
    },
    {
        name:"Report A Bug",
        key:"CreateBugTicket",
        route:"/create-bug-ticket",
        icon: (color,size=40) => <MdOutlineBugReport size={size} color={color} />,
    },    
    {
        name: "My Calender",
        key: "MyCalender",
        route: "/my-calender",
        icon: (color,size=40) => <TbReportAnalytics size={size} color={color} />,
        disabled:true
    },
    // {
    //     name:"Manage Business Card",
    //     key:"manageBusinessCard",
    //     route:"/manage-business-card",
    //     icon: (color,size=40) => <VscSettings size={size} color={color} />,
    // },
    {
        name: "My Calendar",
        key: "MyCalendar",
        route: "/my-calendar",
        icon: (color,size=40) => <FaCalendarDays size={size} color={color} />,
    },
];

export default TeamAdminMenuItems;