import React from 'react'
import { FaCheck } from 'react-icons/fa'
import map from './../images/opportunity_map.png'
import Modal from "./../../../../components/Modal"
// import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2'
import CreateJobApplicationReq from '../../../../Requests/Users/CreateJobApplicationReq'
import UsersApi from '../../../../API/UsersApi'
import CreateFileReq from '../../../../Requests/CloudFile/CreateFileReq'
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import { v4 as uuidv4 } from 'uuid'
import PhoneInput from 'react-phone-input-2'

// const searchLocation = async (address) => {
//     const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${'AIzaSyAaKK8tx3031f-PpUCvNX-awAnRTVd04z0'}`;

//     try {
//         const response = await fetch(geocodingUrl);
//         const data = await response.json();

//         if (response.status === 200 && data.status === "OK") {
//             return data;
//         } else {
//             // console.log(data.error_message)
//         }
//     } catch (error) {
//         console.error(error);
//         return null;
//     }
// };
const ExpandedTab = ({ type, jobDescription, requirements, position }) => {

    const [open, setOpen] = React.useState(false)

    // job application states
    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [phoneNumber, setPhoneNumber] = React.useState('')
    const [message, setMessage] = React.useState('')
    // const [address, setAddress] = React.useState(null)
    // const [search, setSearch] = React.useState('')
    const [resume, setResume] = React.useState(null)
    const [city, setCity] = React.useState('')
    const [state, setState] = React.useState('')
    const [how, setHow] = React.useState('')
    const [other, setOther] = React.useState('')
    const storage = getStorage();

    // async function getLocation() {
    //     if (search === "") {
    //         return
    //     }

    //     const data = await searchLocation(search)
    //     return data.results.map((item) => {
    //         return { value: item.formatted_address, label: item.formatted_address }
    //     })
    // }

    const submitApplication = async () => {
        Swal.fire({
            icon: "info",
            title: "Submitting Application",
            text: "Please wait...",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            customClass: {
                container: 'custom-swal'
            },
            didOpen: () => {
                Swal.showLoading()
            }
        })

        if (!firstName) {
            Swal.fire({
                icon: "error",
                title: "First Name is required",
                text: "Please enter your first name",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        // if (!lastName) {
        //     Swal.fire({
        //         icon: "error",
        //         title: "Last Name is required",
        //         text: "Please enter your last name",
        //         customClass: {
        //             container: 'custom-swal'
        //         },
        //         didOpen: () => {
        //             Swal.hideLoading()
        //         }
        //     })
        //     return
        // }

        if (!email) {
            Swal.fire({
                icon: "error",
                title: "Email is required",
                text: "Please enter your email",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!phoneNumber) {
            Swal.fire({
                icon: "error",
                title: "Phone Number is required",
                text: "Please enter your phone number",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        let emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/;
        if (!emailRegex.test(email)) {
            Swal.fire({
                icon: "error",
                title: "Invalid Email",
                text: "Please enter a valid email",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (phoneNumber.length < 11) {
            Swal.fire({
                icon: "error",
                title: "Invalid Phone Number",
                text: "Please enter a valid phone number",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        // if (!address?.value) {
        //     Swal.fire({
        //         icon: "error",
        //         title: "Address is required",
        //         text: "Please enter your address",
        //         customClass: {
        //             container: 'custom-swal'
        //         },
        //         didOpen: () => {
        //             Swal.hideLoading()
        //         }
        //     })
        //     return
        // }

        if (!message) {
            Swal.fire({
                icon: "error",
                title: "Message is required",
                text: "Please enter your message",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }
        if(!how){
            Swal.fire({
                icon: "error",
                title: "Please specify how you heard about us",
                text: "Please select an option",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(how === 'Other' && !other){
            Swal.fire({
                icon: "error",
                title: "Please specify how you heard about us",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }
        // if (!resume) {
        //     Swal.fire({
        //         icon: "error",
        //         title: "Resume is required",
        //         text: "Please upload your resume",
        //         customClass: {
        //             container: 'custom-swal'
        //         },
        //         didOpen: () => {
        //             Swal.hideLoading()
        //         }
        //     })
        //     return
        // }

        const req = new CreateJobApplicationReq();
        req.firstName = firstName;
        req.lastName = lastName;
        req.email = email;
        req.phone = phoneNumber;
        req.address = city && state ? `${city}, ${state}` : city ? city : state ? state : null;
        req.message = message;
        req.position = position;
        req.howDidYouHear = how === 'Other' ? other : how;
        if (resume) {
            const file = new CreateFileReq()
            file.name = resume.name
            let downloadURLs = [];
            let filePaths = [];
            // let index = 0;
            // Function to upload file and get download URL
            const uploadAndGetData = async (file, type) => {
                // Generate a unique ID 
                const uniqueId = uuidv4();


                const storageRef = ref(storage, `/job-application/${uniqueId}/${file.name}`);
                await uploadBytes(storageRef, file);
                // Get download URL and push to downloadURLs array
                const downloadURL = await getDownloadURL(storageRef);
                downloadURLs.push(downloadURL);
                filePaths.push(`/job-application/${uniqueId}/${file.name}`)
            };
            await uploadAndGetData(resume);
            file.name = resume.name;
            file.extension = file.name.split('.').pop();
            file.url = downloadURLs[0];
            file.path = filePaths[0];
            file.fileSize = resume.size.toString();
            file.sellingCardId = 1;
            file.caption = "123";
            file.fileType = resume.type
            req.file = file;
        }

        const res = await UsersApi.CreateJobApplication(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Application Submitted",
                text: "Thank you for applying",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            setOpen(false)
            setFirstName('')
            setLastName('')
            setEmail('')
            setPhoneNumber('')
            // setAddress({})
            setMessage('')

        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
    }

    const handleOptionChange = (e) => {
        setHow(e.target.value)
    }

    return (
        <div className='expanded_tab'>
            <Modal height={'80vh'} open={open} setOpen={setOpen} >
                <div className='job_application_form'>
                    <h1>Job Application</h1>
                    <input className='input' value={firstName} onChange={(e) => { setFirstName(e.target.value) }} type='text' placeholder='First Name *' />
                    <input className='input' value={lastName} onChange={(e) => { setLastName(e.target.value) }} type='text' placeholder='Last Name' />
                    <input className='input' value={email} onChange={(e) => { setEmail(e.target.value) }} type='email' placeholder='Email *' />
                    {/* <input className='input' value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value) }} type='tel' placeholder='Phone Number' /> */}
                    <PhoneInput containerStyle={{width:"100%"}} inputStyle={{width:"100%"}} country={'us'} value={phoneNumber} onChange={(e) => { setPhoneNumber(e) }} />
                    <input className='input' value={city} onChange={(e) => { setCity(e.target.value) }} type='text' placeholder='City' />
                    <input className='input' value={state} onChange={(e) => { setState(e.target.value) }} type='text' placeholder='State' />
                    <textarea value={message} onChange={(e) => { setMessage(e.target.value) }} required placeholder='Message *' rows={4} />
                    <div>
                        <label>Resume</label>
                        <input className='input' type='file' onChange={(e) => { setResume(e.target.files[0]) }} />
                    </div>
                    <div className='how'>
                        <p>How did you hear about us?</p>
                        <div>
                            <input type='radio' name='how' value='LinkedIn' checked={how === 'LinkedIn'} onChange={handleOptionChange} xcheck />
                            <label>LinkedIn</label>
                        </div>
                        <div>
                            <input type='radio' name='how' value='Facebook' checked={how === 'Facebook'} onChange={handleOptionChange} xcheck />
                            <label>Facebook</label>
                        </div>
                        <div>
                            <input type='radio' name='how' value='Instagram' checked={how === 'Instagram'} onChange={handleOptionChange} xcheck />
                            <label>Instagram</label>
                        </div>
                        <div>
                            <input type='radio' name='how' value='Twitter' checked={how === 'Twitter'} onChange={handleOptionChange} xcheck />
                            <label>Twitter</label>
                        </div>
                        <div>
                            <input type='radio' name='how' value='Google' checked={how === 'Google'} onChange={handleOptionChange} xcheck />
                            <label>Google</label>
                        </div>
                        <div>
                            <input type='radio' name='how' value='Other' checked={how === 'Other'} onChange={handleOptionChange} xcheck />
                            <label>Other</label>
                        </div>
                    </div>
                    {
                        how === 'Other' &&
                        <input className='input' onChange={(e)=>setOther(e.target.value)} value={other} type='text' placeholder='Please specify *' />
                    }
                    <button onClick={submitApplication}>Submit</button>
                </div>
            </Modal>
            <p>Date Posted: MARCH 21, 2024</p>
            <h3>Job Description</h3>
            <p>{jobDescription}</p>
            <h3>Requirements and Responsibilities</h3>
            <div className='requirements'>
                {/* <div className='requirement_item'>
                    <FaCheck color='2761D0' size={20} />
                    <p>Conduct field visits to promote Lightning OS fiber internet solutions.</p>
                </div>
                <div className='requirement_item'>
                    <FaCheck color='2761D0' size={20} />
                    <p>Forge meaningful connections with potential customers and provide comprehensive product knowledge.</p>
                </div>
                <div className='requirement_item'>
                    <FaCheck color='2761D0' size={20} />
                    <p>Collaborate closely with the sales team to exceed targets and drive sustainable revenue growth.</p>
                </div> */}
                {
                    requirements.map((requirement, index) => {
                        return (
                            <div key={index} className='requirement_item'>
                                <FaCheck color='2761D0' size={20} />
                                <p>{requirement}</p>
                            </div>
                        )
                    })
                }
            </div>

            <h1>Job Location</h1>
            <p style={{
                textAlign: 'center',
                marginTop: "-2rem"
            }}>Nation Wide</p>

            <img style={{ margin: "auto" }} src={map} alt='map' />
            <button onClick={() => { setOpen(true) }} className='button'>APPLY NOW</button>
            <p style={{ textAlign: "center" }}>Valid Until 21st September 2024</p>
            <p style={{
                textAlign: "center",
                color: "#2761D0"
            }}>Stay Tuned For More Upcoming Positions</p>
        </div>
    )
}

export default ExpandedTab