/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat, toLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { MapContext as MP } from '..';
import FiberHouseApi from '../../../../API/FiberHouseApi';
import BatchCreateFiberHousesReq from '../../../../Requests/FiberHouse/BatchCreateFiberHousesReq';
import CreateFiberHouseReq from '../../../../Requests/FiberHouse/CreateFiberHouseReq';
import Button from '../../../../components/Button';
import OrganizationSelector from '../../../../components/OrganizationSelector';
import Papa from 'papaparse';
import Modify from 'ol/interaction/Modify';
import Collection from 'ol/Collection';
import * as XLSX from 'xlsx';
import MapHelper from '../MapHelper';


// const searchLocatationWithAdressValidationApi = async (address) => {
//     // const url = "https://addressvalidation.googleapis.com/v1:validateAddress?key=YOUR_API_KEY";
//     const url = "https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyAaKK8tx3031f-PpUCvNX-awAnRTVd04z0";
//     const requestBody = {
//         address: {
//             addressLines: [address]
//         }
//     };

//     try {
//         const response = await fetch(url, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(requestBody)
//         });

//         const data = await response.json();

//         if (response.ok) {
//             // Handle the response data here
//             console.log(data);
//             let lat = data?.result?.geocode?.location?.latitude;
//             let lng = data?.result?.geocode?.location?.longitude;
//             let formattedAddress = data?.result?.address.formattedAddress;

//             return { coordinates: [lng, lat], address: formattedAddress };
//         } else {
//             console.log("Error", data.error.message);
//             return { coordinates: null, address: address };
//             throw new Error(data.error.message || "An error occurred");
//         }
//     } catch (error) {
//         console.error(error);
//         return null;
//     }
// }


const CreateUsingFile = ({ user }) => {


    const [selectedMapIcon, setSelectedMapIcon] = useState(null);

    const [file, setFile] = useState(null)
    const [mapStrings, setMapStrings] = useState([])
    const [mapStringObjects, setMapStringObjects] = useState([])
    const [mapStringObjectsGood, setMapStringObjectsGoood] = useState([])
    const [mapStringObjectsBad, setMapStringObjectsBad] = useState([])
    const [mapStringObjectsWithServicableArea, setMapStringObjectsWithServicableArea] = useState([])
    const [housesWithSameCoordinates, setHousesWithSameCoordinates] = useState([])
    const [newHousesLayer, setNewHousesLayer] = useState(null)
    // const [isLoaded, setIsLoaded] = useState(false);
    const [metaData, setMetaData] = useState(null)
    const [salesOrgId, setSalesOrgId] = useState()
    // const [coordinatesIncluded, setCoordinatesIncluded] = useState(false)
    const [excelHouseObjects, setExcelHouseObjects] = useState(null)

    const { mapObject, setIndex,mapIcons,searchFiberHouses } = React.useContext(MP)
    const helper = new MapHelper(mapObject)
    // const GetMapIcons = async () => {
    //     let req = new StringIntReq();
    //     req.string = "";
    //     req.int = user.salesOrgId;

    //     let res = await MapIconApi.GetSalesOrgMapIcons(req);
    //     if (res?.status?.success) {
    //         setMapIcons(res?.data);
    //     }
    //     else {

    //     }
    // }

    // useEffect(() => {
    //     if (!isLoaded) {
    //         GetMapIcons();
    //     }
    // }, [isLoaded])

    // useEffect(() => {
    //     if (mapIcons.length > 0) {
    //         setIsLoaded(true);
    //     }
    // }, [mapIcons])


    const CreateFiberHouses = async () => {

        if (!mapStringObjectsWithServicableArea || mapStringObjectsWithServicableArea.length === 0) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please Upload a File",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }
        if (selectedMapIcon === null) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please Select a Map Icon",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        Swal.fire({
            icon: 'info',
            title: 'Creating Fiber Houses',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        let orgId = null;
        if (user?.userType === "SuperAdmin" || user?.userType === "Admin") {
            orgId = salesOrgId;
        }
        else {
            orgId = user.salesOrgId;
        }
        if (orgId == null) {
            Swal.fire({
                icon: 'error',
                title: 'Organization is required',
                text: 'Please select Organization',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }


        var list = [];

        mapStringObjectsWithServicableArea.forEach((mapStringObject,index) => {
            const req = new CreateFiberHouseReq();
            req.createdById = user.id;
            req.salesOrgId = orgId;
            // req.country = mapStringObject.country
            req.state = mapStringObject.state;
            req.city = mapStringObject.city;
            req.address = mapStringObject.address;
            req.zipCode =  String(mapStringObject.zipCode);
            // req.notes = mapStringObject.notes
            // req.lead.name = metaData[index].firstName + " " + metaData[index].lastName
            // req.lead.email = metaData[index].email
            // req.lead.phone = metaData[index].phone
            // req.lead.notes = metaData[index].notes
            // req.mapIconId = metaData[index].mapIconId
            req.servicableAreaId = mapStringObject.servicableArea?.id;
            req.coordinate.latitude = mapStringObject.coordinates[1];
            req.coordinate.longitude = mapStringObject.coordinates[0];
            req.coordinate.popUpColor = "#000000";
            req.mapIconId = mapStringObject?.duplicate?20:selectedMapIcon.id;
            req.mapString = mapStringObject.mapString;
            req.unit = mapStringObject.unit;

            list.push(req);
        });

        let req = new BatchCreateFiberHousesReq();
        req.fiberHouses = list;
        console.log(req)

        var response = await FiberHouseApi.BatchCreateFiberHouse(req);
        console.log(response)
        if (response?.status?.success) {
            Swal.fire(
                {
                    title: "Fiber Houses Created",
                    text: response?.status?.message,
                    icon: "success",
                    allowEnterKey: true,
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                }
            )
            
            searchFiberHouses()
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: response?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
        }


    }

    // const fileChange = () => {
    //     // parse a list of strings into an array from a txt file with each string seperated by a new line
    //     const reader = new FileReader();
    //     reader.onload = function (e) {
    //         const text = e.target.result;
    //         if (file.name.endsWith('.csv')) {
    //             // Parse CSV file
    //             const { data, errors } = Papa.parse(text, {
    //                 header: true, // Treat the first row as header
    //                 skipEmptyLines: true // Skip empty lines
    //             });
    //             if (errors.length > 0) {
    //                 console.error("CSV parsing errors:", errors);
    //                 return;
    //             }
    //             // Extract address column
    //             const addresses = data.map(row => row.ADDRESS);
    //             setMapStrings(addresses);
    //             console.log("ADDRESSES", addresses);
    //         }
    //         else if (file.name.endsWith('.txt')) {
    //             let lines = [];
    //             // const lines = text.split("\n").map((line) => line.trim());
    //             lines = text.split("\n").map((line) => line.trim());
    //             //remove empty lines
    //             lines = lines.filter((line) => line !== "");
    //             //remove quotes from each line
    //             lines = lines.map((line) => line.replace(/['"]+/g, ''));
    //             setMapStrings(lines)
    //             console.log("LINES", lines)
    //         }
    //         else {
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Error',
    //                 text: "Invalid File Type",
    //                 allowOutsideClick: false,
    //                 allowEscapeKey: false,
    //                 allowEnterKey: false,
    //                 showConfirmButton: true,
    //                 didOpen: () => {
    //                     Swal.hideLoading()
    //                 }
    //             });
    //         }

    //     };
    //     reader.readAsText(file);
    // }

    // const fileChange = () => {
    //     // parse a list of strings into an array from a txt file with each string seperated by a new line
    //     const reader = new FileReader();
    //     reader.onload = function (e) {
    //         const text = e.target.result;
    //         if (file.name.endsWith('.csv')) {
    //             // Parse CSV file assuming it has a column named 'ADDRESS'
    //             const { data: parsedDataWithColumn } = Papa.parse(text, {
    //                 header: true,
    //                 skipEmptyLines: true,
    //                 delimiter: ','
    //             });
    //             const addressesWithColumn = parsedDataWithColumn.map(row => row.ADDRESS);
    //             const meta =  [];
    //             parsedDataWithColumn.map(row =>   {
    //                 meta.push({mapIconId: row.MapIconId, firstName : row.FirstName, lastName: row.LastName, email: row.Email, phone: row.Phone, notes: row.Notes})
    //             })
    //             // Check if 'ADDRESS' column exists and contains data
    //             const addressesWithColumnExist = addressesWithColumn && addressesWithColumn.length > 0 && addressesWithColumn[0];
    //             const mapIconExist = meta && meta.length > 0 && meta[0];
    //             if (addressesWithColumnExist) {
    //                 // Extract address column if exists
    //                 setMapStrings(addressesWithColumn);
    //                 if(mapIconExist){
    //                     setMetaData(meta);
    //                 }
    //                 console.log("ADDRESSES (with column):", addressesWithColumn);
    //             } else {
    //                 // Fallback: Treat each line as an address
    //                 var lines = text.split("\n").map(line => line.trim()).filter(line => line !== "");
    //                 lines = lines.map(line => line.replace(/['"]+/g, ''));
    //                 setMapStrings(lines);
    //                 console.log("ADDRESSES (no column):", lines);
    //             }
    //         }
    //         else if (file.name.endsWith('.txt')) {
    //             var lines = [];
    //             // const lines = text.split("\n").map((line) => line.trim());
    //             lines = text.split("\n").map((line) => line.trim());
    //             //remove empty lines
    //             lines = lines.filter((line) => line !== "");
    //             //remove quotes from each line
    //             lines = lines.map((line) => line.replace(/['"]+/g, ''));
    //             setMapStrings(lines)
    //             console.log("LINES", lines)
    //         }
    //         else if(file.name.endsWith('.xlsx') || file.name.endsWith('.xls')){
    //             const data = new Uint8Array(e.target.result);
    //             const workbook = XLSX.read(data, { type: 'array' });
    //             var allHousesList = [];
    //             workbook.SheetNames.forEach(sheetName => {
    //               console.log(`Sheet Name: ${sheetName}`);
    //               const worksheet = workbook.Sheets[sheetName];
    //               const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            
    //               // Headers
    //               const headers = sheetData[0];
    //               console.log("Headers:");
    //               console.table(headers);
            
    //               // Rows
    //               const rows = sheetData.slice(1);
    //               console.log("Rows:");
    //               console.table(rows);

    //               //check if longitude and latitude columns exist
    //                 if(!headers.includes('Longitude') || !headers.includes('Latitude')){
    //                     //if they dont exist, use the address column to get the coordinates
    //                     //push the address to the mapStrings array
    //                     let lines = [];
    //                     console.log("NO LONGITUDE OR LATITUDE COLUMNS")

    //                 }
    //                 else{

                        
    //                     rows.forEach(row => {
    //                         let address = row[headers.indexOf('Address 1')];
    //                         if(row[headers.indexOf('Address 2')]){
    //                             address += " " + row[headers.indexOf('Address 2')];
    //                         }
    //                         var house = {
    //                             sheetName: sheetName,
    //                             address: address,
    //                             city: row[headers.indexOf('City')],
    //                             state: row[headers.indexOf('State')],
    //                             zipCode: row[headers.indexOf('Zip')],
    //                             // country: row[headers.indexOf('Country')],
    //                             latidude: row[headers.indexOf('Latitude')],
    //                             longitude: row[headers.indexOf('Longitude')],
    //                         }

    //                         allHousesList.push(house);
    //                     });

    //                     console.log("ALL HOUSES LIST", allHousesList)
    //                     setExcelHouseObjects(allHousesList);
    //                 }
    //             });

    //         }
    //         else {
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Error',
    //                 text: "Invalid File Type",
    //                 allowOutsideClick: false,
    //                 allowEscapeKey: false,
    //                 allowEnterKey: false,
    //                 showConfirmButton: true,
    //                 didOpen: () => {
    //                     Swal.hideLoading()
    //                 }
    //             });
    //         }

    //     };
    //     if(file.name.endsWith('.xlsx') || file.name.endsWith('.xls')){
    //         reader.readAsArrayBuffer(file);
    //     }
    //     else{
    //         reader.readAsText(file);
    //     }
    // }

    const fileChange = () => {
        // parse a list of strings into an array from a txt file with each string seperated by a new line
        const reader = new FileReader();
        reader.onload = function (e) {
            const text = e.target.result;
            if (file.name.endsWith('.csv')) {
                // Parse CSV file assuming it has a column named 'ADDRESS'
                const { data: parsedDataWithColumn } = Papa.parse(text, {
                    header: true,
                    skipEmptyLines: true,
                    delimiter: ','
                });
                const addressesWithColumn = parsedDataWithColumn.map(row => ({ mapString: row.ADDRESS }));
                const meta = [];
                parsedDataWithColumn.map(row => {
                    meta.push({
                        mapIconId: row.MapIconId,
                        firstName: row.FirstName,
                        lastName: row.LastName,
                        email: row.Email,
                        phone: row.Phone,
                        notes: row.Notes
                    });
                });
            
                // Check if 'ADDRESS' column exists and contains data
                const addressesWithColumnExist = addressesWithColumn && addressesWithColumn.length > 0 && addressesWithColumn[0].mapString;
                const mapIconExist = meta && meta.length > 0 && meta[0];
            
                if (addressesWithColumnExist) {
                    // Extract address column if exists
                    setMapStrings(addressesWithColumn);
                    if (mapIconExist) {
                        setMetaData(meta);
                    }
                    console.log("ADDRESSES (with column):", addressesWithColumn);
                } else {
                    // Fallback: Treat each line as an address
                    var lines = text.split("\n").map(line => line.trim()).filter(line => line !== "");
                    lines = lines.map(line => ({ mapString: line.replace(/['"]+/g, '') }));
                    setMapStrings(lines);
                    console.log("ADDRESSES (no column):", lines);
                }
            } 
            else if (file.name.endsWith('.txt')) {
                var lines = [];
                lines = text.split("\n").map((line) => line.trim());
                // Remove empty lines
                lines = lines.filter((line) => line !== "");
                // Remove quotes from each line and map to objects with the 'mapString' key
                lines = lines.map((line) => ({ mapString: line.replace(/['"]+/g, '') }));
                setMapStrings(lines);
                console.log("LINES", lines);
            }
            else if(file.name.endsWith('.xlsx') || file.name.endsWith('.xls')){
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                var allHousesList = [];
                let lines = [];
                workbook.SheetNames.forEach(sheetName => {
                  console.log(`Sheet Name: ${sheetName}`);
                  const worksheet = workbook.Sheets[sheetName];
                  const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            
                  // Headers
                  const headers = sheetData[0];
                  console.log("Headers:");
                  console.table(headers);
            
                  // Rows
                  const rows = sheetData.slice(1);
                  console.log("Rows:");
                  console.table(rows);

                  //check if longitude and latitude columns exist
                    if(!headers.includes('Longitude') || !headers.includes('Latitude')){
                        //if they dont exist, use the address column to get the coordinates
                        //push the address to the mapStrings array

                        rows.forEach(row => {
                            // let address = row[headers.indexOf('Address')];
                            let address = "";
                            if(row[headers.indexOf('FullServiceAddress')]){
                                address = row[headers.indexOf('FullServiceAddress')];
                            }
                            else if(row[headers.indexOf('Address')]){
                                address = row[headers.indexOf('Address')];
                            }
                            else if(row[headers.indexOf('Service Address')]){
                                address = row[headers.indexOf('Service Address')];
                            }
                            let city = "";
                            if(row[headers.indexOf('City')]){
                                city = row[headers.indexOf('City')];
                            }
                            else if(row[headers.indexOf('Community')]){
                                city = row[headers.indexOf('Community')];
                            }
                            let state = "";
                            if(row[headers.indexOf('State')]){
                                state = row[headers.indexOf('State')];
                            }
                            else if(row[headers.indexOf('911 State')]){
                                state = row[headers.indexOf('911 State')];
                            }
                            let zipCode = "";
                            if(row[headers.indexOf('Zip')]){
                                zipCode = row[headers.indexOf('Zip')];
                            }
                            else if(row[headers.indexOf('911 Zip')]){
                                zipCode = row[headers.indexOf('911 Zip')];
                            }
                            let unit = "";
                            if(row[headers.indexOf('Serv Address Unit')]){
                                unit = row[headers.indexOf('Serv Address Unit')];
                            }

                            lines.push({ 
                                mapString: address + " " + city + " " + state,
                                address: address,
                                city: city,
                                state: state,
                                zipCode: zipCode,
                                unit: unit
                            });

                        });

                        console.log("NO LONGITUDE OR LATITUDE COLUMNS")
                    }
                    else{

                        
                        rows.forEach(row => {
                            let address = row[headers.indexOf('Address 1')];
                            if(row[headers.indexOf('Address 2')]){
                                address += " " + row[headers.indexOf('Address 2')];
                            }
                            var house = {
                                sheetName: sheetName,
                                address: address,
                                city: row[headers.indexOf('City')],
                                state: row[headers.indexOf('State')],
                                zipCode: row[headers.indexOf('Zip')],
                                // country: row[headers.indexOf('Country')],
                                latidude: row[headers.indexOf('Latitude')],
                                longitude: row[headers.indexOf('Longitude')],
                            }

                            allHousesList.push(house);
                        });

                        console.log("ALL HOUSES LIST", allHousesList)
                        // setExcelHouseObjects(allHousesList);
                    }
                });

                if(lines.length > 0){
                    setMapStrings(lines);
                }
                else if(allHousesList.length > 0){
                    setExcelHouseObjects(allHousesList);
                }

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: "Invalid File Type",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                });
            }

        };
        if(file.name.endsWith('.xlsx') || file.name.endsWith('.xls')){
            reader.readAsArrayBuffer(file);
        }
        else{
            reader.readAsText(file);
        }
    }

    useEffect(() => {
        if (file) {
            fileChange()
        }
    }, [file])

    // const mapStringsChange = () => {
    //     const list = [];

    //     // Use Promise.all to wait for all promises to resolve
    //     Promise.all(mapStrings.map((location) => {
    //         return searchLocation(location.mapString).then(details => ({ ...details, location }));
    //     }))
    //     .then((detailsArray) => {
    //         detailsArray.forEach((details, index) => {
    //             list.push({ 
    //                 mapString: details.address,
    //                 address: details.location.address, 
    //                 coordinates: details.coordinates, 
    //                 city: details.location.city,
    //                 state: details.location.state,
    //                 zipCode: details.location.zipCode,
    //                 index: index
    //             });
    //         });
        
    //         // Now that all promises are resolved, update the state
    //         setMapStringObjects(list);
    //     })
    //     .catch((error) => {
    //         console.error('Error fetching coordinates:', error);
    //     });
    //     console.log("LIST", list)
    //     return list;
    // }

    const mapStringsChange = async () => {
        Swal.fire({
            icon: 'info',
            title: 'Fetching Coordinates',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        const list = [];

        // use a for loop to wait for each promise to resolve
        for (let i = 0; i < mapStrings.length; i++) {
            var location = mapStrings[i];
            try{

                //update swal to show progress
                Swal.update({
                    title: 'Fetching Coordinates',
                    html: `Please wait...<br>Progress: ${i + 1}/${mapStrings.length}`,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });

                var details = await helper.searchLocationDetails(location.address);
                list.push({
                    mapString: details.address,
                    address: location.address,
                    coordinates: details.coordinates,
                    city: location.city,
                    state: location.state,
                    zipCode: location.zipCode,
                    unit: location.unit,
                    index: i
                });
            }
            catch(e){
                console.log(e)
            }
        }

        // Now that all promises are resolved, update the state
        console.log("LIST", list)
        setMapStringObjects(list);

        Swal.update({
            icon: 'success',
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: true,
            showConfirmButton: true,
            
            isopen: () => {
            // Add your code here to handle the appropriate function
            // For example, you can call a function to display a success message or perform any other action
            Swal.hideLoading();
            }
        });
    }


    const snapToLocation = (fiberHouse) => {
        let lonlat = [fiberHouse.coordinates.longitude, fiberHouse.coordinates.latitude]
        mapObject.getView().setCenter(fromLonLat(lonlat));
        mapObject.getView().setZoom(21);
    }

    useEffect( () => {
        console.log("MAP STRINGS", mapStrings)
        if (mapStrings && mapStrings.length > 0) {
            mapStringsChange()
        }
    }, [mapStrings])

    const excelHouseObjectsChange = () => {
        const list = [];
        
        if(excelHouseObjects == null || excelHouseObjects?.length === 0){
            return;
        } 

        excelHouseObjects.forEach((location, index) => {
            list.push({ mapString: location.address, coordinates: [location.longitude, location.latidude], index: index });
        });

        //check if any entries are undefined
        if (list.some((entry) => entry.coordinates === null)) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Some addresses could not be found',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }
        if(list.some((entry) => entry === null)){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Some addresses could not be found ENTRYY',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        // Now that all promises are resolved, update the state
        setMapStringObjects(list);

    }

    useEffect(() => {
        excelHouseObjectsChange()
    }, [excelHouseObjects])

    const showFiberHousesOnMap = () => {
        //show each mapStringObject on the map with a marker and popup
        // Create a vector source and vector layer for the markers

        const oldZoom = mapObject.getView().getZoom();
        //remove the newHousesLayer from the map
        if (newHousesLayer) {
            mapObject.removeLayer(newHousesLayer)
        }
        const vectorSource = new VectorSource();
        const vectorLayer = new VectorLayer({
            source: vectorSource,
        });

        // Create a style for the markers
        const markerStyle = new Style({
            image: new Icon({
                src: 'https://openlayers.org/en/latest/examples/data/icon.png', // Blue-dot icon
                scale: 0.9, // Adjust the scale as needed
                zIndex: 10000000, // Make sure the icon stays on top of other map features
            }),
        });

        // Add the vector layer to the map
        mapObject.addLayer(vectorLayer);
        mapObject.getView().setZoom(oldZoom);
        // Add a point feature for each coordinate with the marker style
        // mapStringObjects.forEach(ms => {
            const features = new Collection();

            mapStringObjectsGood.forEach(ms => {
                const point = new Point(fromLonLat(ms.coordinates));
                const feature = new Feature(point);
                feature.set('mapString', ms.mapString);
                feature.set('index', ms.index);
                feature.set('coordinates', ms.coordinates);
                feature.set('bulk_mark',1)
                feature.setStyle(markerStyle);
                vectorSource.addFeature(feature);
                features.push(feature);
            });
            
            const modify = new Modify({ features: features });
        mapObject.addInteraction(modify);
        modify.on('modifyend', function (e) {
            const features = e.features.getArray();
            console.log("MODIFY END", features)
            features.forEach((feature) => {
                const coordinates = feature.getGeometry().getCoordinates();
                const index = feature.get('index');
                setMapStringObjectsGoood((prevList) => {
                    return prevList.map((ms) => {
                        if (ms.index === index) {
                            return { ...ms, coordinates: toLonLat(coordinates) }
                        }
                        return ms;
                    })
                });
            });
        })
        vectorLayer.setZIndex(1000)

        // Zoom to the extent of the vector layer
        if (!newHousesLayer) {
            mapObject.getView().fit(vectorSource.getExtent());
        }
        setNewHousesLayer(vectorLayer)

        // Add a click handler to the map to render the popup.
        mapObject.on('click', function (evt) {

            const feature = mapObject.forEachFeatureAtPixel(evt.pixel,
                function (feature) {
                    return feature;
                });
            if (feature?.get('bulk_mark')) {
                // const coordinates = feature.getGeometry().getCoordinates();
                // Set the position and content of the popup
                Swal.fire({
                    title: 'Popup Title',
                    html: `<div><p>${feature.get('mapString')}</p></div>`,
                    footer: '<button id="customButton">Remove House</button>',
                    confirmButtonText: 'OK',
                });

                // Add a click event listener to the custom button in the Swal popup
                document.getElementById('customButton').addEventListener('click', () => {
                    // Perform the desired action when the button is clicked

                    // Add your logic here
                    removeEntryFromMapStringObjects(feature.get('index'))
                });


            }
        });
    }


    const removeEntryFromMapStringObjects = (index) => {
        // setMapStringObjects((prevList) => {
        //     // Remove the entry from mapStringObjects with the given index
        //     return prevList.filter((ms) => ms.index !== index);
        // });
        console.log(index,"INDEX")
        console.log(mapStringObjects)
        console.log(metaData)
        setMapStringObjectsGoood((prevList) => {
            // Remove the entry from mapStringObjects with the given index
            return prevList.filter((ms) => ms.index !== index);
        });

        // setMetaData((prevList) => {
        //     // Remove the entry from mapStringObjects with the given index
        //     for (let i = 0; i < prevList.length; i++) {
        //         if (i === index) {
        //             prevList.splice(i, 1)
        //         }
        //     }
        //     return prevList;
        // });

        setMapStringObjectsWithServicableArea((prevList) => {
            // Remove the entry from mapStringObjects with the given index
            return prevList.filter((ms) => ms.index !== index);
        });
    };


    const filterMapStringObjects = () => {
        let goodList = [];
        let badList = [];
        mapStringObjects.forEach(ms => {
            if (ms.coordinates) {
                goodList.push(ms)
            }
            else {
                badList.push(ms)
            }
        });
        setMapStringObjectsGoood(goodList)
        setMapStringObjectsBad(badList)
    }

    const getHousesWithSameCoordinates = () => {
        // let list = [];
        let coordinates = [];
        mapStringObjectsGood.forEach(ms => {
          coordinates.push(ms.coordinates)
        });
        let duplicates = []
        let temp = coordinates

        for (let i = 0; i < temp.length; i++) {
            for (let j = i + 1; j < temp.length; j++) {
                if (temp[i][0] === temp[j][0] && temp[i][1] === temp[j][1]) {
                    // Add a small random offset to the duplicate's coordinates
                    // let lastMs = "not yet";
                    // let lastTemp = "not yet";
                    setMapStringObjectsGoood((prevList) => {
                        return prevList.map((ms) => {
                          
                                if(temp == null || temp[j] == null || ms == null || ms.coordinates == null){
                                    return ms;
                                }
                                if (ms?.coordinates[0] === temp[j][0] && ms?.coordinates[1] === temp[j][1]) {
                                    // temp[j][0] += 0.00001; // Change this value to adjust the offset
                                    // temp[j][1] += 0.00001; // Change this value to adjust the offset
                                    return { ...ms, coordinates: temp[j],duplicate:true }
                                }
                                return ms;
                        });
                    });

                    duplicates.push(temp[j]);
                    temp.splice(j, 1);
                    j--; // Decrement j to account for the removed element
                }
            }
        }
      
        setHousesWithSameCoordinates(duplicates)
      }

    const downloadBadAddresses = () => {
        let csvContent = "data:text/csv;charset=utf-8,";
        mapStringObjectsBad.forEach((ms) => {
            csvContent += ms.mapString + "\n";
        });
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "badAddresses.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
    }


    useEffect(() => {
        if (mapStringObjects && mapStringObjects.length > 0) {
            console.log("MAP STRING OBJECTS", mapStringObjects)
            filterMapStringObjects()
        }
    }, [mapStringObjects])

    useEffect(() => {
        if (mapStringObjectsGood && mapStringObjectsGood.length > 0) {
            console.log("MAP STRING OBJECTS GOOD", mapStringObjectsGood)
            showFiberHousesOnMap()
            UpdateMapStringObjectsWithServicableArea()
           
        }
    }, [mapStringObjectsGood])

    useEffect(() => {
        if (mapStringObjectsGood && mapStringObjectsGood.length > 0) {
            getHousesWithSameCoordinates()
        }
    },[mapStringObjectsBad])

    const UpdateMapStringObjectsWithServicableArea = () => {


        let updatedList = [];
        // mapStringObjects.forEach(fa => {
        //     let newListObject = fa;
        //     // const coordinates = fa.coordinates;
        //     // let insideAreas = [];
        //     // servicableAreas.forEach(sa => {
        //     //     if (checkIfCoordinatesAreInsideServicableArea(coordinates, sa)) {
        //     //         insideAreas.push(sa)
        //     //     }

        //     // });
        //     // if (insideAreas.length > 1) {
        //     //     const smallestArea = getSmallestServicableArea(insideAreas)
        //     //     newListObject.servicableArea = smallestArea
        //     // }
        //     // else if (insideAreas.length === 1) {
        //     //     newListObject.servicableArea = insideAreas[0]
        //     // }
        //     // else {
        //     //     newListObject.servicableArea = null;
        //     // }
        //     updatedList.push(newListObject)
        // });
        mapStringObjectsGood.forEach(fa => {
            let newListObject = fa;
            // const coordinates = fa.coordinates;
            // let insideAreas = [];
            // servicableAreas.forEach(sa => {
            //     if (checkIfCoordinatesAreInsideServicableArea(coordinates, sa)) {
            //         insideAreas.push(sa)
            //     }

            // });
            // if (insideAreas.length > 1) {
            //     const smallestArea = getSmallestServicableArea(insideAreas)
            //     newListObject.servicableArea = smallestArea
            // }
            // else if (insideAreas.length === 1) {
            //     newListObject.servicableArea = insideAreas[0]
            // }
            // else {
            //     newListObject.servicableArea = null;
            // }
            updatedList.push(newListObject)
        });


        setMapStringObjectsWithServicableArea(updatedList)
    }


    // function getPolygonArea(coordinates) {
    //     const numPoints = coordinates.length;

    //     if (numPoints < 3) {
    //         return 0; // A polygon with less than 3 vertices has no area
    //     }

    //     let area = 0;

    //     for (let i = 0; i < numPoints; i++) {
    //         const xi = coordinates[i].latitude;
    //         const yi = coordinates[i].longitude;

    //         const nextIndex = (i + 1) % numPoints;
    //         const xj = coordinates[nextIndex].latitude;
    //         const yj = coordinates[nextIndex].longitude;

    //         area += xi * yj - xj * yi;
    //     }

    //     area = Math.abs(area) / 2;

    //     return area;
    // }

    // function getSmallestServicableArea(servicableAreas) {
    //     if (servicableAreas.length === 0) {
    //         return null; // Return null or handle empty array case as needed
    //     }

    //     return servicableAreas.reduce((smallestArea, currentArea) => {
    //         const areaSmallest = smallestArea ? getPolygonArea(smallestArea.coordinates) : Infinity;
    //         const areaCurrent = getPolygonArea(currentArea.coordinates);

    //         if (areaCurrent < areaSmallest) {
    //             return currentArea;
    //         } else {
    //             return smallestArea;
    //         }
    //     }, null);
    // }

    //remove markers from map when component unmounts
    useEffect(() => {
        return () => {
            if (newHousesLayer) {
                mapObject.removeLayer(newHousesLayer)
            }
        }
    }, [])

    return (
        <div className='FiberHousefileDiv'>
            <div className='TitleDiv'>
                <h3 >Bulk Create Fiber Houses</h3>
                <button onClick={() => setIndex(2)} className='uppercase text-[#2761D0] '>Create Manually</button>
                {/* <button onClick={() => { closeTab() }} className='CloseButton'>
                    X
                </button> */}
            </div>
            <div className='FormDiv'>

                <input label="file" id="fileUpload" type="file" onChange={(e) => {
                    setMapStringObjects([])
                    setMapStringObjectsGoood([])
                    setMapStringObjectsBad([])
                    setMapStringObjectsWithServicableArea([])
                    setHousesWithSameCoordinates([])
                    setMetaData([]);
                    setFile(e.target.files[0])
                    }
                    } placeholder="Enter file" className='hidden' />
                <label htmlFor="fileUpload" className="cursor-pointer overflow-hidden rounded-lg border-2 border-[#C4C4C4] bg-white text-center text-black text-base focus:outline-[#181E4B] flex justify-between items-center px-8 py-2">
                    <div className='flex items-center'>
                        <p className="mr-2">{file ? file.name : "Upload a File"}</p>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
                        <path d="M5 12.9987H9C9.55 12.9987 10 12.5487 10 11.9987V6.99869H11.59C12.48 6.99869 12.93 5.91869 12.3 5.28869L7.71 0.698694C7.61749 0.60599 7.5076 0.532443 7.38662 0.482261C7.26565 0.43208 7.13597 0.40625 7.005 0.40625C6.87403 0.40625 6.74435 0.43208 6.62338 0.482261C6.5024 0.532443 6.39251 0.60599 6.3 0.698694L1.71 5.28869C1.08 5.91869 1.52 6.99869 2.41 6.99869H4V11.9987C4 12.5487 4.45 12.9987 5 12.9987ZM1 14.9987H13C13.55 14.9987 14 15.4487 14 15.9987C14 16.5487 13.55 16.9987 13 16.9987H1C0.45 16.9987 0 16.5487 0 15.9987C0 15.4487 0.45 14.9987 1 14.9987Z" fill="#2761D0" />
                    </svg>
                </label>
                {
                    (user?.userType === "SuperAdmin" || user?.userType === "Admin") &&
                    <OrganizationSelector setUser={setSalesOrgId} placeholder='Select Organization' />
                }
                <div className='MapIconsDiv'>
                    {mapIcons.map((assignedMapIcons, index) => {
                        return (
                            <div className='MapIconDiv' key={index} style={{ border: selectedMapIcon?.id === assignedMapIcons.id ? "2px solid #2761D0" : "none", cursor: 'pointer' }} onClick={() => setSelectedMapIcon(assignedMapIcons)}>
                                <div className='MapIconImageDiv'>
                                    <img src={"data:image/png;base64," + assignedMapIcons.image} alt="Map Icon" />
                                </div>
                                <p className='MapIconName'>{assignedMapIcons.abbreviation}</p>
                                <div className='MapIconButtonsDiv'>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <Button onClick={CreateFiberHouses} >Upload</Button>
                <div className='StatusDiv'>
                    {/* {
                        mapStringObjectsWithServicableArea != null && mapStringObjectsWithServicableArea.length > 0 &&
                        <div className='MapStringObjectDiv'>
                            <div className='MapStringObjectMapStringDiv'>
                                <p className='MapStringObjectMapString'>
                                    {mapStringObjectsWithServicableArea.length} Houses Detected from {mapStringObjects.length} Addresses

                                </p>
                                <p className='MapStringObjectMapString'>
                                    {mapStringObjectsGood.length} Good Addresses
                                </p>
                                <p className='MapStringObjectMapString' style={{ color: mapStringObjectsBad.length > 0 ? "red" : "green" }}>
                                    {mapStringObjectsBad.length} Bad Addresses
                                </p>
                            </div>
                            <div className='MapStringObjectServicableAreaDiv'>
                                <p className='MapStringObjectServicableArea'>
                                    {
                                        mapStringObjectsWithServicableArea.filter(fa => fa.servicableArea !== null).length
                                    } Inside Serviceable Areas
                                </p>
                            </div>
                        </div>

                    } */}
                    {
                        mapStringObjectsWithServicableArea != null && mapStringObjectsWithServicableArea.length > 0 &&
                        <div className='MapStringObjectDiv'>
                            <div className='MapStringObjectMapStringDiv'>
                                <p className='MapStringObjectMapString'>
                                    {mapStringObjectsWithServicableArea.length} Houses Detected from {mapStringObjects.length} Addresses
                                    and {mapStrings.length} Lines of Data

                                </p>
                                <p className='MapStringObjectMapString'>
                                    {mapStringObjectsGood.length} Good Addresses
                                </p>
                                <p className='MapStringObjectMapString'>
                                    {housesWithSameCoordinates.length} Duplicate Coordinates
                                </p>
                                <p className='MapStringObjectMapString' style={{ color: mapStringObjectsBad.length > 0 ? "red" : "green" }}>
                                    {mapStringObjectsBad.length} Bad Addresses
                                    <button onClick={downloadBadAddresses} className='DownloadButton'>Download</button>
                                </p>
                            </div>
                            <div className='MapStringObjectServicableAreaDiv'>
                                <p className='MapStringObjectServicableArea'>
                                    {
                                        mapStringObjectsWithServicableArea.filter(fa => fa.servicableArea !== null).length
                                    } Inside Serviceable Areas
                                </p>
                            </div>
                        </div>

                    }
                    {
                        mapStringObjectsWithServicableArea != null && mapStringObjectsWithServicableArea.length > 0 ?
                            mapStringObjectsWithServicableArea.map((mapStringObject, index) => {
                                return (
                                    <div key={index} className='MapStringObjectDiv'>
                                        <div className='MapStringObjectMapStringDiv'>
                                            <p className='MapStringObjectMapString'>{mapStringObject.mapString}</p>
                                            <p className='MapStringObjectServicableArea' style={{ color: mapStringObject.servicableArea ? mapStringObject.servicableArea.color : "black" }} >
                                                {mapStringObject.servicableArea && mapStringObject.servicableArea.name}
                                            </p>
                                            {
                                                metaData && metaData.length > 0 && metaData[index].firstName &&
                                                <p>First Name : {metaData[index].firstName}</p>
                                            }
                                            {
                                                metaData && metaData.length > 0 && metaData[index].lastName &&
                                                <p>Last Name : {metaData[index].lastName}</p>
                                            }
                                            {
                                                metaData && metaData.length > 0 && metaData[index].email &&
                                                <p>Email : {metaData[index].email}</p>
                                            }
                                            {
                                                metaData && metaData.length > 0 && metaData[index].phone &&
                                                <p>Phone : {metaData[index].phone}</p>
                                            }
                                            {
                                                metaData && metaData.length > 0 && metaData[index].notes &&
                                                <p>Notes : {metaData[index].notes}</p>
                                            }
                                            
                                            {/* {
                                            //show if house is duplicate
                                            housesWithSameCoordinates.filter(house => house.coordinates === mapStringObject.coordinates).length > 1 &&
                                            <p className='MapStringObjectMapString' style={{ color: "red" }}>
                                                Duplicate
                                            </p>
                                        } */}
                                        </div>
                                        <div className='MapStringObjectServicableAreaDiv'>
                                          
                                            <button onClick={()=>{snapToLocation({
                                                coordinates:{
                                                    latitude:mapStringObject.coordinates[1],
                                                    longitude:mapStringObject.coordinates[0]
                                                }
                                            })}} style={{width:"100%"}} >Snap To Location</button>
                                            <button style={{width:"100%"}} onClick={()=>removeEntryFromMapStringObjects(index)}  className='remove'>Remove</button>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className='MapStringObjectDiv'>
                                <div className='MapStringObjectMapStringDiv'>
                                    <p className='MapStringObjectMapString'>No Map Strings</p>
                                </div>
                                <div className='MapStringObjectServicableAreaDiv'>
                                    <p className='MapStringObjectServicableArea'>No Servicable Area</p>
                                </div>
                            </div>

                    }
                    {
                        mapStringObjectsBad != null && mapStringObjectsBad.length > 0 &&
                        mapStringObjectsBad.map((mapStringObject, index) => {
                            return (
                                <div key={index} className='MapStringObjectDiv'>
                                    <div className='MapStringObjectMapStringDiv'>
                                        <p className='MapStringObjectMapString'>{mapStringObject.mapString}</p>
                                    </div>
                                    <div className='MapStringObjectServicableAreaDiv'>
                                        <p className='MapStringObjectServicableArea' style={{ color: "red" }} >
                                            Bad Address
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>


        </div>
    )
}

export default CreateUsingFile