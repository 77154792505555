class MapHelper {
    constructor(mapObject) {
        this.map = mapObject;
    }

    checkIfFiberHouseIsInsideServicableArea(fiberHouse, searchArea) {
        const lat = fiberHouse.coordinates.latitude;
        const lng = fiberHouse.coordinates.longitude;

        let inside = false;
        for (let i = 0, j = searchArea.length - 1; i < searchArea.length; j = i++) {
            const xi = searchArea[i].latitude;
            const yi = searchArea[i].longitude;
            const xj = searchArea[j].latitude;
            const yj = searchArea[j].longitude;

            const intersect = ((yi > lng) !== (yj > lng)) && (lat < (xj - xi) * (lng - yi) / (yj - yi) + xi);
            if (intersect) {
                inside = !inside; // Toggle the inside flag on each intersection
            }
        }
        return inside;
    }

    async searchLocation(address) {
        const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${'AIzaSyAaKK8tx3031f-PpUCvNX-awAnRTVd04z0'}`;

        try {
            const response = await fetch(geocodingUrl);
            const data = await response.json();

            if (response.status === 200 && data.status === "OK") {
                const { lat, lng } = data.results[0].geometry.location;
                return [lng, lat];
            } else {
                throw new Error(data.error_message || "An error occurred");
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    };


    // combineTwoAreas(area1,area2){

    // }

    async searchLocationDetails(address) {
        // const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${'AIzaSyAaKK8tx3031f-PpUCvNX-awAnRTVd04z0'}`;
        //make address url encoded
        const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${'AIzaSyAaKK8tx3031f-PpUCvNX-awAnRTVd04z0'}`;

        try {
            const response = await fetch(geocodingUrl);
            const data = await response.json();

            if (response.status === 200 && data.status === "OK") {
                const { lat, lng } = data.results[0].geometry.location;
                // return [lng, lat];
                return { coordinates: [lng, lat], address: address };
            } else {
                console.log("Error", data.error_message);
                return { coordinates: null, address: address };
                throw new Error(data.error_message || "An error occurred");
            }
        } catch (error) {
            console.error(error);
            return { coordinates: null, address: address };
        }
    };


    async getLocationName(coordinates) {
        const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates[1]},${coordinates[0]}&key=${'AIzaSyAaKK8tx3031f-PpUCvNX-awAnRTVd04z0'}`;
        try {
            const response = await fetch(geocodingUrl);
            const data = await response.json();

            if (response.status === 200 && data.status === "OK") {
                return data.results[0].formatted_address;
            } else {
                throw new Error(data.error_message || "An error occurred");
            }
        } catch (error) {
            console.error(error);
            return null;
        }

    }

    // For Stats 

    // Get the Unique Sales Orgs from the Fiber Houses
    getUniqueSalesOrgsFromFiberHouses(fiberHouses) {
        let salesOrgs = [];
        fiberHouses.forEach((fiberHouse) => {
            if (fiberHouse.salesOrgId) {
                if (!salesOrgs.some(salesOrg => salesOrg.id === fiberHouse.salesOrgId)) {
                    salesOrgs.push({
                        id: fiberHouse.salesOrgId,
                        name: fiberHouse.salesOrgName
                    })
                }
            }
        });
        return salesOrgs;
    }

    // Get the Unique Sales Reps from the Fiber Houses
    getUniqueSalesRepsFromFiberHouses(fiberHouses) {
        let salesReps = [];
        let userIds = new Set();

        fiberHouses.forEach((fiberHouse) => {
            if (fiberHouse.owners) {
                fiberHouse.owners.forEach((owner) => {
                    if (!userIds.has(owner.userId)) {
                        userIds.add(owner.userId);
                        salesReps.push({
                            userId: owner.userId,
                            name: owner.name,
                            profileImage : owner.profileImage
                        });
                    }
                });
            }
        });
        console.log(salesReps)
        return salesReps;
    }

    // Get the count of fiberHouses owned by each salesRep
    getCountOfEachSalesRepFiberHouses(fiberHouses) {
        const combinationsCount = {};

        fiberHouses?.forEach((fiberhouse) => {
            const ownerIds = fiberhouse?.owners?.map(owner => { return { id: owner.userId, salesRepF: [owner.salesRepFiberHouseId] } });
            // console.log(ownerIds)

            if (ownerIds) {
                for (let i = 0; i < ownerIds.length; i++) {
                    for (let j = i + 1; j < ownerIds.length; j++) {
                        const sortedPair = [ownerIds[i].id, ownerIds[j].id].sort();
                        const pairKey = sortedPair.join(',');

                        if (combinationsCount[pairKey]) {
                            combinationsCount[pairKey].count++;
                            combinationsCount[pairKey].salesRepF.push(...ownerIds[i].salesRepF, ...ownerIds[j].salesRepF);
                        } else {
                            combinationsCount[pairKey] = {
                                count: 1,
                                salesRepF: [...ownerIds[i].salesRepF, ...ownerIds[j].salesRepF]
                            };
                        }
                    }
                }
            }
        });

        return combinationsCount;
    }

    cleanup() {
        this.map = null;
    }

}

export default MapHelper;