import React, { useEffect, useState } from 'react';
import Layout from '../../../Layouts/Layout';
import './AnnouncementCards.scss';
import UsersApi from '../../../API/UsersApi';
import StringIntReq from '../../../Requests/StringIntReq';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Announcements = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch all announcements
    const getAnnouncements = async () => {
        setLoading(true);
        let req = new StringIntReq();
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 50;
        const res = await UsersApi.GetAllAnnouncements(req);
        if (res?.status?.success) {
            const sortedAnnouncements = res.data.list
                .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
                .filter(announcement => announcement.isHidden === false);
            setAnnouncements(sortedAnnouncements);
            setLoading(false);
        } else {
            console.log(res?.status?.message);
            setLoading(false);
        }
    };


    const getDaysAgo = (startDate) => {
        const today = new Date();
        const date = new Date(startDate);
        const diffInTime = today.getTime() - date.getTime();
        const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

        if (diffInDays === 0) return 'Today';
        if (diffInDays === 1) return 'Yesterday';
        return `${diffInDays}d ago`;
    };


    const getBorderColor = (type) => {
        switch (type) {
            case 'Bug Fix':
                return '#ff0000';
            case 'Feature':
                return '#4caf50'; 
            case 'Announcement':
                return '#2196f3'; 
            case 'Update':
                return '#ff0000'; 
            default:
                return '#000'; 
        }
    };

    useEffect(() => {
        getAnnouncements();
    }, []);

    return (
        <Layout>
            <div className="announcement-cards-container">
                <h1>All Announcements</h1>
                {loading ? (
                    <div className="spinner">
                        <CircularProgress />
                    </div>
                ) : (
                    <Grid container spacing={3}>
                        {announcements.length > 0 ? (
                            announcements.map((announcement) => (
                                <Grid item xs={12} md={6} lg={4} key={announcement.id}>
                                    <div className="announcement-card" style={{ borderLeftColor: getBorderColor(announcement.announcementType) }}>
                                        <div className="announcement-header">
                                            <Typography className="announcement-type">
                                                New {announcement.announcementType}
                                            </Typography>
                                            <Typography className="announcement-date">
                                                {getDaysAgo(announcement.startDate)}
                                            </Typography>
                                        </div>
                                        <Typography className="announcement-message">
                                            {announcement.message}
                                        </Typography>
                                    </div>
                                </Grid>
                            ))
                        ) : (
                            <Typography style={{ textAlign: 'center' }}>No announcements available.</Typography>
                        )}
                    </Grid>
                )}
            </div>
        </Layout>
    );
};

export default Announcements;
